import { FlagHandler } from './flag-handler'

console.log('Using QA Flags')

export const FeatureFlags: Record<string, boolean> = new Proxy(
  {
    fleet488: true,
    fleet561: true,
    aty310: true, // Scheduling feature
    fleet624: true, // Scheduling from reservations
  },
  FlagHandler
)
