import { find } from 'lodash'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NexusService } from 'global-apis/nexus-service'
import { getPricingOptionsForProgram } from '../ProgramPricingBlock/useProgramPricingBlock'
import { Reservation } from '@nxcr-org/web-api-interface/lib/billing_service_pb'

export function useChangeProgramDialog({
  programId,
  onClose,
  reservation: { color, secondaryColor, reservationNumber, id: reservationId },
  postal,
}: {
  customerId: string
  programId: string
  onClose: () => void
  reservation: Reservation.AsObject
  postal: string
}) {
  const queryClient = useQueryClient()
  const [program, setSelectedProgram] = useState(null)
  const [pricingId, setPricingId] = useState<string>('')

  const {
    data: programOptions,
    error: programOptionsError,
    isLoading,
  } = useQuery(
    ['/programOptions'],
    () => {
      return NexusService.getProgramofferings()
    },
    {
      placeholderData: {
        programsList: [],
      },
      onError() {
        console.log('Error: ', programOptionsError)
      },
      onSuccess(data) {
        const selectedProgram = find(data.programsList, {
          id: program?.id || programId,
        })

        setPricingId(selectedProgram?.pricingOptionsList?.[0]?.id)
        setSelectedProgram(selectedProgram)
      },
    }
  )

  const setProgram = (newProgramId: string) => {
    const newProgram = find(programOptions.programsList, {
      id: newProgramId,
    })

    setSelectedProgram(newProgram)
    setPricingId(newProgram.pricingOptionsList?.[0]?.id)
  }

  const { mutate: saveProgramSelection } = useMutation(
    () => {
      const pricingOption = find(
        getPricingOptionsForProgram(programOptions?.programsList, program.id),
        {
          id: pricingId,
        }
      )
      return NexusService.UpdateProgramReservation({
        reservationId,
        deposit: pricingOption.deposit,
        startFee: pricingOption.startFee,
        monthlyRent: pricingOption.monthly,
        color: color,
        secondaryColor: secondaryColor,
        make: program.make,
        model: program.model,
        postal,
        trackingId: reservationNumber,
        programId: program.id,
      })
        .then((res) => {
          console.log('update program reservation', res)

          onClose()
        })
        .catch(onClose)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['/reservation', reservationId])
      },
    }
  )

  // TEMP - needed since program options might be cached initially
  useEffect(() => {
    if (programOptions) {
      const selectedProgram = find(programOptions.programsList, {
        id: programId,
      })

      setPricingId(selectedProgram?.pricingOptionsList?.[0]?.id)
      setSelectedProgram(selectedProgram)
    }
  }, [])

  return {
    programs: programOptions,
    pricingId,
    isLoading,
    program,
    setProgram,
    setPricingId,
    saveProgramSelection,
    programPricingOptions: getPricingOptionsForProgram(
      programOptions?.programsList,
      program?.id
    ),
  }
}
