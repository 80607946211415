import { useQuery } from 'react-query'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { NexusService } from '../global-APIs/nexus-service'

export function useTransactions() {

  const [{ accountName, rowsPerPage, page, description }, setQueryParams] =
    useQueryParams({
      accountName: StringParam,
      description: withDefault(StringParam, ''),
      page: withDefault(NumberParam, 0),
      rowsPerPage: withDefault(NumberParam, 50),
    })

  const setAccountName = (accountName?: string) => {
    setQueryParams({
      accountName
    })
  }

  const setDescription = (description?: string) => {
    setQueryParams({
      description,
    })
  }

  const setPage = (nextPage: number) => {
    setQueryParams({ page: nextPage })
  }

  const setRowsPerPage = (nextRowsPerPage: number) => {
    setQueryParams({ rowsPerPage: nextRowsPerPage })
  }

  const { data: transactions, error: transactionsError, isLoading } = useQuery(
    ['/Transactions', accountName, page, rowsPerPage, description],
    () => {
      return NexusService.listTransactions({
        description,
        accountName,
        limit: rowsPerPage,
        offset: Number((rowsPerPage * page).toString(10)),
      })
        .catch(() => {
          return {
            transactionsList: [],
            total: 0,
          }
        })
    },
    {
      placeholderData: {
        transactionsList: [],
        total: 0
      },
      onError() {
        console.log('Error: ', transactionsError)
      },
    },
  )

  return {
    accountName,
    setAccountName,
    description,
    setDescription,
    transactions: transactions.transactionsList,
    total: transactions?.total || 0,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    isLoading,
  }
}

