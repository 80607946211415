import React from 'react'
import ReactMapGL, { Layer, Source } from 'react-map-gl'

import { useMap } from './useMap'
import { MapCard } from '../MapCard/MapCard'
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from './layers.config'
import { useMapStyles } from './Map.styles'
import { FilterCard } from '../FilterCard/FilterCard'

import { useFlags } from 'launchdarkly-react-client-sdk'

const Map = () => {
  const { classes } = useMapStyles()
  const { nex111mapFilterCard } = useFlags()
  const {
    onClick,
    vehicles,
    initialViewState,
    selectedVehicle,
    deselectVehicle,
  } = useMap()

  return (
    <div className={classes.mapContainer}>
      {nex111mapFilterCard && <FilterCard />}
      <ReactMapGL
        id="assets"
        mapboxAccessToken={process.env.MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/light-v11"
        // TODO: move to NXCR mapbox account
        // mapStyle="mapbox://styles/patrickautonomy/cl77tj18w003j14lodmmjtcro"
        onClick={onClick}
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
        doubleClickZoom={true}
        initialViewState={initialViewState}
      >
        <Source
          id="car-source"
          type="geojson"
          data={vehicles.geoJSON}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </ReactMapGL>
      {selectedVehicle && (
        <MapCard
          customer={selectedVehicle.customer}
          vehicle={selectedVehicle.vehicle}
          closeDialog={deselectVehicle}
          showDialog={!!selectedVehicle}
        />
      )}
    </div>
  )
}

export default Map
