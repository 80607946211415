import React from 'react'
import { Box, Card, CardHeader, Grid } from '@mui/material'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { useTransactions } from './useTransactions'
import { getGridRowClass } from '../../utils/getGridRowClass'
import { TableSearch } from 'shared/TableSearch'

export const Transactions = () => {
  const {
    transactions,
    accountName,
    setAccountName,
    page,
    rowsPerPage,
    total,
    setRowsPerPage,
    setPage,
    description,
    setDescription,
    isLoading,
  } = useTransactions()

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '30px',
          marginBottom: '30px',
        }}
      >
        <CardHeader
          sx={{
            padding: 'none',
          }}
          title={'Transactions'}
        />
      </Box>
      <Card>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <TableSearch
              search={accountName}
              setSearch={setAccountName}
              label={'Search by Account Name'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableSearch
              search={description}
              setSearch={setDescription}
              label={'Search by Description'}
            />
          </Grid>
        </Grid>

        <DataGrid
          autoHeight
          disableColumnFilter
          rowHeight={35}
          getRowClassName={(params) =>
            getGridRowClass(params.indexRelativeToCurrentPage)
          }
          columns={columns}
          rows={transactions}
          components={{ Toolbar: GridToolbar }}
          pageSize={rowsPerPage}
          page={page}
          pagination={true}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginationMode="server"
          rowCount={total}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
          loading={isLoading}
        />
      </Card>
    </Box>
  )
}

const columns: GridColumns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
  },
  {
    field: 'customerAccountNumber',
    headerName: 'Customer Account Number',
    width: 200,
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    width: 200,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 100,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
  },
]
