/**
 * @fileoverview gRPC-Web generated client stub for nexus_bff
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var web_pb = require('./web_pb.js')

var appointment_pb = require('./appointment_pb.js')

var domain_pb = require('./domain_pb.js')

var insurance_service_pb = require('./insurance_service_pb.js')
const proto = {};
proto.nexus_bff = require('./nexus_bff_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.nexus_bff.NexusBffClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.nexus_bff.NexusBffPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.nexus_bff.ListAppointmentsResponse>}
 */
const methodDescriptor_NexusBff_ListAppointments = new grpc.web.MethodDescriptor(
  '/nexus_bff.NexusBff/ListAppointments',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  proto.nexus_bff.ListAppointmentsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_bff.ListAppointmentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.nexus_bff.ListAppointmentsResponse>}
 */
const methodInfo_NexusBff_ListAppointments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_bff.ListAppointmentsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_bff.ListAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_bff.ListAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_bff.ListAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_bff.NexusBffClient.prototype.listAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_bff.NexusBff/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusBff_ListAppointments,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_bff.ListAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_bff.NexusBffPromiseClient.prototype.listAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_bff.NexusBff/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_NexusBff_ListAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsurancePolicyRequest,
 *   !proto.insurance_service.GetInsurancePolicyResponse>}
 */
const methodDescriptor_NexusBff_GetInsurancePolicy = new grpc.web.MethodDescriptor(
  '/nexus_bff.NexusBff/GetInsurancePolicy',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsurancePolicyRequest,
  insurance_service_pb.GetInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.GetInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePolicyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsurancePolicyRequest,
 *   !proto.insurance_service.GetInsurancePolicyResponse>}
 */
const methodInfo_NexusBff_GetInsurancePolicy = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.GetInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePolicyResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsurancePolicyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsurancePolicyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_bff.NexusBffClient.prototype.getInsurancePolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_bff.NexusBff/GetInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_NexusBff_GetInsurancePolicy,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsurancePolicyResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_bff.NexusBffPromiseClient.prototype.getInsurancePolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_bff.NexusBff/GetInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_NexusBff_GetInsurancePolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.nexus_bff.ListCustomersResponse>}
 */
const methodDescriptor_NexusBff_GetCustomers = new grpc.web.MethodDescriptor(
  '/nexus_bff.NexusBff/GetCustomers',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  proto.nexus_bff.ListCustomersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_bff.ListCustomersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.nexus_bff.ListCustomersResponse>}
 */
const methodInfo_NexusBff_GetCustomers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.nexus_bff.ListCustomersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.nexus_bff.ListCustomersResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_bff.ListCustomersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_bff.ListCustomersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.nexus_bff.NexusBffClient.prototype.getCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/nexus_bff.NexusBff/GetCustomers',
      request,
      metadata || {},
      methodDescriptor_NexusBff_GetCustomers,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_bff.ListCustomersResponse>}
 *     Promise that resolves to the response
 */
proto.nexus_bff.NexusBffPromiseClient.prototype.getCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/nexus_bff.NexusBff/GetCustomers',
      request,
      metadata || {},
      methodDescriptor_NexusBff_GetCustomers);
};


module.exports = proto.nexus_bff;

