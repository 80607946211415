import { CustomerServicePromiseClient } from '@nxcr-org/web-api-interface/lib/gateway_service_grpc_web_pb'

import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'
import { Env } from '../fleet-management/api/env'
import {
  GetCustomerByIDRequest,
  ListCustomersRequest,
  UpdateCustomerWithFakePhoneRequest,
} from '@nxcr-org/web-api-interface/lib/customer_service_pb'

import { SearchParams } from '@nxcr-org/web-api-interface/lib/web_pb'
import {
  buildOrderListObj,
  buildPaginationObj,
  buildQueryListObj,
} from './nexus-bff-service-customers'
function getCustomerService(): CustomerServicePromiseClient {
  const idToken = oktaAuth.getIdToken()
  const target = Env.endpoints.fleet

  return new CustomerServicePromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor(idToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}
export function UpdateCustomerWithFakePhone(customerId) {
  const client = getCustomerService()
  const request = new UpdateCustomerWithFakePhoneRequest()
  request.setCustomerId(customerId)
  return client.updateCustomerWithFakePhone(request).then((res) => {
    return res.toObject()
  })
}

export function getCustomerById(customerId) {
  const client = getCustomerService()
  const request = new GetCustomerByIDRequest()
  request.setCustomerId(customerId)
  return client.getCustomerByID(request).then((res) => {
    return res.toObject()
  })
}
export function ListCustomers({ searchParam }) {
  const client = getCustomerService()
  const request = new ListCustomersRequest()
  const searchParamsObj = new SearchParams()

  searchParamsObj.setQueryList(buildQueryListObj(searchParam?.query || []))
  searchParamsObj.setOrderList(buildOrderListObj(searchParam?.order || []))
  searchParamsObj.setPagination(buildPaginationObj(searchParam?.pagination))
  request.setSearchParam(searchParamsObj)
  return client.listCustomers(request).then((res) => {
    return res.toObject()
  })
}
