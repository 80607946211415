export const FlagHandler: ProxyHandler<Record<string, boolean>> = {
  get(target, prop: string){
    if(target[prop]){
      return target[prop]
    }

    console.log(`Flag ${prop} not included in available flags list.`)

    return false
  }
}