import { NexusBffPromiseClient } from '@nxcr-org/web-api-interface/lib/nexus_bff_grpc_web_pb'
import {
  OrderParams,
  PaginationParams,
  SearchParams,
  QueryParams,
} from '@nxcr-org/web-api-interface/lib/web_pb'
import { Env } from '../fleet-management/api/env'
import { oktaAuth, OktaAuthInterceptor } from '../../okta/config'

function getCustomerService(): NexusBffPromiseClient {
  const accessToken = oktaAuth.getAccessToken()
  const target = Env.endpoints.nexusBffService

  return new NexusBffPromiseClient(target, null, {
    unaryInterceptors: [new OktaAuthInterceptor('Bearer ' + accessToken)],
    'grpc-node.max_session_memory': 1000,
    'grpc-node.max_send_message_length': 1024 * 1024 * 100,
    'grpc-node.max_receive_message_length': 1024 * 1024 * 100,
  })
}

export async function getBffCustomers(searchParams) {
  const client = getCustomerService()
  const request = new SearchParams()
  request.setQueryList(buildQueryListObj(searchParams.query || []))
  request.setOrderList(buildOrderListObj(searchParams.order || []))
  request.setPagination(buildPaginationObj(searchParams.pagination))

  return client.getCustomers(request).then((res) => {
    return res.toObject()
  })
}

export function buildQueryListObj(
  params: QueryParams.AsObject[]
): QueryParams[] {
  const queryListRequestArray = params.map((queryParam) => {
    const request = new QueryParams()
    request.setProperty(queryParam.property)
    request.setValue(queryParam.value)
    request.setModifier(queryParam.modifier)
    return request
  })

  return queryListRequestArray
}

export function buildOrderListObj(
  params: OrderParams.AsObject[]
): OrderParams[] {
  const requestArray = params.map((queryParam) => {
    const request = new OrderParams()
    request.setProperty(queryParam.property)
    request.setDirection(queryParam.direction)
    return request
  })

  return requestArray
}

export function buildPaginationObj(
  params: PaginationParams.AsObject
): PaginationParams {
  const request = new PaginationParams()

  request.setLimit(params?.limit)
  request.setOffset(params?.offset)

  return request
}
