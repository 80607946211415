import { NexusRouteGroup } from '../components/layout/layout.types'
import { useFlags } from 'launchdarkly-react-client-sdk'


export const availableRoutes = (): NexusRouteGroup[] => {
  
  const { nex21TransactionsTable } = useFlags()

  return [
    {
      label: 'General',
      routes: [
        {
          label: 'Vehicle Map',
          target: '/assets/inventory',
          icon: 'public',
          exact: true,
        },
        {
          label: 'Vestaboard',
          target: '/vestaboard',
          icon: 'bar_chart',
        },
      ],
    },
    {
      label: 'Fleet',
      routes: [
        {
          label: 'Vehicle Inventory',
          target: '/fleet',
          icon: 'directions_car',
          exact: true,
        },
        {
          label: 'Active Vehicles',
          target: '/fleet/active',
          icon: 'directions_car',
        },
        {
          label: 'Appointments',
          target: '/fleet/tasks',
          icon: 'calendar_month',
        },
      ],
    },
    {
      label: 'Account/Billing',
      routes: nex21TransactionsTable ? [
        {
          label: 'Reservations',
          // ! In the current navigation reservations is under pmt, but does that make sense?
          target: '/fleet/reservations',
          icon: 'event_available',
        },
        {
          label: 'Transactions',
          target: '/transactions',
          icon: 'receipt_long',
        },
      ] : [
        {
          label: 'Reservations',
          // ! In the current navigation reservations is under pmt, but does that make sense?
          target: '/fleet/reservations',
          icon: 'event_available',
        },
      ],
    },
    {
      label: 'Customer Acquisition',
      routes: [
        {
          label: 'Promos',
          target: '/promotions',
          icon: 'confirmation_number',
        },
        {
          label: 'Customers',
          target: '/customers',
          icon: 'group',
        },
      ],
    },
    {
      label: 'Nexus',
      routes: [
        {
          label: 'Settings',
          target: '/fleet/settings',
          icon: 'settings_applications',
        },
      ],
    },
  ]
} 