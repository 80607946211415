// source: events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var customer_pb = require('./customer_pb.js');
goog.object.extend(proto, customer_pb);
var customer_service_pb = require('./customer_service_pb.js');
goog.object.extend(proto, customer_service_pb);
var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var telemetric_pb = require('./telemetric_pb.js');
goog.object.extend(proto, telemetric_pb);
var subscription_pb = require('./subscription_pb.js');
goog.object.extend(proto, subscription_pb);
var billing_service_pb = require('./billing_service_pb.js');
goog.object.extend(proto, billing_service_pb);
var inspection_pb = require('./inspection_pb.js');
goog.object.extend(proto, inspection_pb);
var appointment_pb = require('./appointment_pb.js');
goog.object.extend(proto, appointment_pb);
var notifications_pb = require('./notifications_pb.js');
goog.object.extend(proto, notifications_pb);
var dealer_pb = require('./dealer_pb.js');
goog.object.extend(proto, dealer_pb);
var insurance_service_pb = require('./insurance_service_pb.js');
goog.object.extend(proto, insurance_service_pb);
goog.exportSymbol('proto.events.ActivityStateUpdated', null, global);
goog.exportSymbol('proto.events.AuthStatus', null, global);
goog.exportSymbol('proto.events.CancelPaymentTransaction', null, global);
goog.exportSymbol('proto.events.CollisionReceived', null, global);
goog.exportSymbol('proto.events.CustomerActivityUpdated', null, global);
goog.exportSymbol('proto.events.CustomerCreated', null, global);
goog.exportSymbol('proto.events.CustomerDataEnriched', null, global);
goog.exportSymbol('proto.events.CustomerDeleted', null, global);
goog.exportSymbol('proto.events.CustomerFailureStateUpdated', null, global);
goog.exportSymbol('proto.events.CustomerInfoUpdated', null, global);
goog.exportSymbol('proto.events.CustomerInsuranceUpdated', null, global);
goog.exportSymbol('proto.events.CustomerInsuranceUpdated.ChangeType', null, global);
goog.exportSymbol('proto.events.CustomerInsuranceUpdated.RequestSource', null, global);
goog.exportSymbol('proto.events.CustomerQualified', null, global);
goog.exportSymbol('proto.events.CustomerScoreDisqualified', null, global);
goog.exportSymbol('proto.events.CustomerScored', null, global);
goog.exportSymbol('proto.events.CustomerStateUpdated', null, global);
goog.exportSymbol('proto.events.DocumentAdded', null, global);
goog.exportSymbol('proto.events.DocumentAdded.DoctypeCase', null, global);
goog.exportSymbol('proto.events.DocumentDeleted', null, global);
goog.exportSymbol('proto.events.DocumentDeleted.DoctypeCase', null, global);
goog.exportSymbol('proto.events.Domain', null, global);
goog.exportSymbol('proto.events.Event', null, global);
goog.exportSymbol('proto.events.Event.PayloadCase', null, global);
goog.exportSymbol('proto.events.EventCategory', null, global);
goog.exportSymbol('proto.events.ExtendInsurancePolicies', null, global);
goog.exportSymbol('proto.events.InvoiceCancelled', null, global);
goog.exportSymbol('proto.events.InvoiceReady', null, global);
goog.exportSymbol('proto.events.InvoiceToBePaid', null, global);
goog.exportSymbol('proto.events.MilesPurchased', null, global);
goog.exportSymbol('proto.events.Notification', null, global);
goog.exportSymbol('proto.events.OdometerSet', null, global);
goog.exportSymbol('proto.events.OdometerUpdated', null, global);
goog.exportSymbol('proto.events.OfferReason', null, global);
goog.exportSymbol('proto.events.OfferResponse', null, global);
goog.exportSymbol('proto.events.OfferSubmitted', null, global);
goog.exportSymbol('proto.events.PaymentActivity', null, global);
goog.exportSymbol('proto.events.PaymentActivityType', null, global);
goog.exportSymbol('proto.events.PaymentRefunded', null, global);
goog.exportSymbol('proto.events.PaymentStatus', null, global);
goog.exportSymbol('proto.events.PickupDateSet', null, global);
goog.exportSymbol('proto.events.PickupPayment', null, global);
goog.exportSymbol('proto.events.PrepickupAuth', null, global);
goog.exportSymbol('proto.events.ReloadDomain', null, global);
goog.exportSymbol('proto.events.ReturnLineItem', null, global);
goog.exportSymbol('proto.events.SmsReceived', null, global);
goog.exportSymbol('proto.events.StatementActivity', null, global);
goog.exportSymbol('proto.events.StatementActivity.ActivityType', null, global);
goog.exportSymbol('proto.events.SubscriptionCancelled', null, global);
goog.exportSymbol('proto.events.SubscriptionContractAdded', null, global);
goog.exportSymbol('proto.events.SubscriptionVehicleChange', null, global);
goog.exportSymbol('proto.events.SubscriptionVehicleReturned', null, global);
goog.exportSymbol('proto.events.SyncDealerships', null, global);
goog.exportSymbol('proto.events.SyncIngestion', null, global);
goog.exportSymbol('proto.events.TransferVehicle', null, global);
goog.exportSymbol('proto.events.TripReceived', null, global);
goog.exportSymbol('proto.events.VehicleActivityFailureUpdated', null, global);
goog.exportSymbol('proto.events.VehicleActivityStateUpdated', null, global);
goog.exportSymbol('proto.events.VehicleActivityUpdated', null, global);
goog.exportSymbol('proto.events.VehicleChangeDetails', null, global);
goog.exportSymbol('proto.events.VehicleCreated', null, global);
goog.exportSymbol('proto.events.VehicleInspectionCompleted', null, global);
goog.exportSymbol('proto.events.VehicleInspectionPhotoUrl', null, global);
goog.exportSymbol('proto.events.VehicleInspectionStarted', null, global);
goog.exportSymbol('proto.events.VehiclePickup', null, global);
goog.exportSymbol('proto.events.VehiclePrePickup', null, global);
goog.exportSymbol('proto.events.VehicleStateUpdated', null, global);
goog.exportSymbol('proto.events.VehicleStateUpdated.Reason', null, global);
goog.exportSymbol('proto.events.VehicleTosAccepted', null, global);
goog.exportSymbol('proto.events.VendorAccountUpdated', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.events.Event.oneofGroups_);
};
goog.inherits(proto.events.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.Event.displayName = 'proto.events.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.PaymentRefunded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.PaymentRefunded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.PaymentRefunded.displayName = 'proto.events.PaymentRefunded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.InvoiceCancelled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.InvoiceCancelled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.InvoiceCancelled.displayName = 'proto.events.InvoiceCancelled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SubscriptionCancelled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.SubscriptionCancelled.repeatedFields_, null);
};
goog.inherits(proto.events.SubscriptionCancelled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SubscriptionCancelled.displayName = 'proto.events.SubscriptionCancelled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.TripReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.TripReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.TripReceived.displayName = 'proto.events.TripReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CollisionReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CollisionReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CollisionReceived.displayName = 'proto.events.CollisionReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.Notification.displayName = 'proto.events.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerCreated.displayName = 'proto.events.CustomerCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerInfoUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.CustomerInfoUpdated.repeatedFields_, null);
};
goog.inherits(proto.events.CustomerInfoUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerInfoUpdated.displayName = 'proto.events.CustomerInfoUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerInsuranceUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerInsuranceUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerInsuranceUpdated.displayName = 'proto.events.CustomerInsuranceUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerStateUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerStateUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerStateUpdated.displayName = 'proto.events.CustomerStateUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerFailureStateUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerFailureStateUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerFailureStateUpdated.displayName = 'proto.events.CustomerFailureStateUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerActivityUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerActivityUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerActivityUpdated.displayName = 'proto.events.CustomerActivityUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ActivityStateUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ActivityStateUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ActivityStateUpdated.displayName = 'proto.events.ActivityStateUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VendorAccountUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VendorAccountUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VendorAccountUpdated.displayName = 'proto.events.VendorAccountUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerDeleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerDeleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerDeleted.displayName = 'proto.events.CustomerDeleted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.PickupDateSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.PickupDateSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.PickupDateSet.displayName = 'proto.events.PickupDateSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehiclePrePickup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehiclePrePickup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehiclePrePickup.displayName = 'proto.events.VehiclePrePickup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehiclePickup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehiclePickup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehiclePickup.displayName = 'proto.events.VehiclePickup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.PrepickupAuth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.PrepickupAuth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.PrepickupAuth.displayName = 'proto.events.PrepickupAuth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.PickupPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.PickupPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.PickupPayment.displayName = 'proto.events.PickupPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleCreated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleCreated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleCreated.displayName = 'proto.events.VehicleCreated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.TransferVehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.TransferVehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.TransferVehicle.displayName = 'proto.events.TransferVehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.InvoiceReady = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.InvoiceReady, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.InvoiceReady.displayName = 'proto.events.InvoiceReady';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OdometerUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OdometerUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OdometerUpdated.displayName = 'proto.events.OdometerUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OdometerSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OdometerSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OdometerSet.displayName = 'proto.events.OdometerSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.StatementActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.StatementActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.StatementActivity.displayName = 'proto.events.StatementActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.PaymentActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.PaymentActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.PaymentActivity.displayName = 'proto.events.PaymentActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleStateUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.VehicleStateUpdated.repeatedFields_, null);
};
goog.inherits(proto.events.VehicleStateUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleStateUpdated.displayName = 'proto.events.VehicleStateUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ReturnLineItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ReturnLineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ReturnLineItem.displayName = 'proto.events.ReturnLineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleActivityUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleActivityUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleActivityUpdated.displayName = 'proto.events.VehicleActivityUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleActivityStateUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleActivityStateUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleActivityStateUpdated.displayName = 'proto.events.VehicleActivityStateUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleActivityFailureUpdated = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleActivityFailureUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleActivityFailureUpdated.displayName = 'proto.events.VehicleActivityFailureUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.InvoiceToBePaid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.InvoiceToBePaid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.InvoiceToBePaid.displayName = 'proto.events.InvoiceToBePaid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SyncDealerships = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SyncDealerships, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SyncDealerships.displayName = 'proto.events.SyncDealerships';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.DocumentAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.events.DocumentAdded.oneofGroups_);
};
goog.inherits(proto.events.DocumentAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.DocumentAdded.displayName = 'proto.events.DocumentAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.DocumentDeleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.events.DocumentDeleted.oneofGroups_);
};
goog.inherits(proto.events.DocumentDeleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.DocumentDeleted.displayName = 'proto.events.DocumentDeleted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OfferSubmitted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OfferSubmitted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OfferSubmitted.displayName = 'proto.events.OfferSubmitted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SubscriptionContractAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SubscriptionContractAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SubscriptionContractAdded.displayName = 'proto.events.SubscriptionContractAdded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleInspectionStarted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleInspectionStarted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleInspectionStarted.displayName = 'proto.events.VehicleInspectionStarted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleInspectionPhotoUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleInspectionPhotoUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleInspectionPhotoUrl.displayName = 'proto.events.VehicleInspectionPhotoUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleInspectionCompleted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.VehicleInspectionCompleted.repeatedFields_, null);
};
goog.inherits(proto.events.VehicleInspectionCompleted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleInspectionCompleted.displayName = 'proto.events.VehicleInspectionCompleted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.OfferResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.OfferResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.OfferResponse.displayName = 'proto.events.OfferResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleTosAccepted = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleTosAccepted, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleTosAccepted.displayName = 'proto.events.VehicleTosAccepted';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ReloadDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ReloadDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ReloadDomain.displayName = 'proto.events.ReloadDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CancelPaymentTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.events.CancelPaymentTransaction.repeatedFields_, null);
};
goog.inherits(proto.events.CancelPaymentTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CancelPaymentTransaction.displayName = 'proto.events.CancelPaymentTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SmsReceived = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SmsReceived, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SmsReceived.displayName = 'proto.events.SmsReceived';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerScored = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerScored, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerScored.displayName = 'proto.events.CustomerScored';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerQualified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerQualified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerQualified.displayName = 'proto.events.CustomerQualified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerScoreDisqualified = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerScoreDisqualified, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerScoreDisqualified.displayName = 'proto.events.CustomerScoreDisqualified';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.CustomerDataEnriched = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.CustomerDataEnriched, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.CustomerDataEnriched.displayName = 'proto.events.CustomerDataEnriched';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SyncIngestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SyncIngestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SyncIngestion.displayName = 'proto.events.SyncIngestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.ExtendInsurancePolicies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.ExtendInsurancePolicies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.ExtendInsurancePolicies.displayName = 'proto.events.ExtendInsurancePolicies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.MilesPurchased = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.MilesPurchased, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.MilesPurchased.displayName = 'proto.events.MilesPurchased';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.VehicleChangeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.VehicleChangeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.VehicleChangeDetails.displayName = 'proto.events.VehicleChangeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SubscriptionVehicleChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SubscriptionVehicleChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SubscriptionVehicleChange.displayName = 'proto.events.SubscriptionVehicleChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.events.SubscriptionVehicleReturned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.events.SubscriptionVehicleReturned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.events.SubscriptionVehicleReturned.displayName = 'proto.events.SubscriptionVehicleReturned';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.events.Event.oneofGroups_ = [[9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115]];

/**
 * @enum {number}
 */
proto.events.Event.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  CUSTOMER_CREATED: 9,
  CUSTOMER_INFO_UPDATED: 10,
  CUSTOMER_STATE_UPDATED: 11,
  CUSTOMER_FAILURE_STATE_UPDATED: 12,
  CUSTOMER_ACTIVITY_UPDATED: 13,
  ACTIVITY_STATE_UPDATED: 14,
  VENDOR_ACCOUNT_UPDATED: 15,
  CUSTOMER_DELETED: 16,
  PICKUP_DATE_SET: 17,
  VEHICLE_PREPICKUP: 18,
  VEHICLE_PICKUP: 19,
  PREPICKUP_AUTH: 20,
  PICKUP_PAYMENT: 21,
  TRANSFER_VEHICLE: 22,
  VEHICLE_CREATED: 23,
  TRIP_RECEIVED: 24,
  COLLISION_RECEIVED: 25,
  NOTIFICATION: 26,
  INVOICE_READY: 27,
  ODOMETER_UPDATED: 28,
  ODOMETER_SET: 29,
  STATEMENT_ACTIVITY: 30,
  PAYMENT_ACTIVITY: 31,
  VEHICLE_STATE_UPDATED: 32,
  VEHICLE_ACTIVITY_UPDATED: 33,
  VEHICLE_ACTIVITY_STATE_UPDATED: 34,
  VEHICLE_ACTIVITY_FAILURE_UPDATED: 35,
  CUSTOMER_INSURANCE_UPDATED: 36,
  SYNC_DEALERSHIPS: 37,
  INVOICE_TO_BE_PAID: 38,
  DOCUMENT_ADDED: 39,
  DOCUMENT_DELETED: 40,
  OFFER_SUBMITTED: 41,
  SUBSCRIPTION_CONTRACT_ADDED: 42,
  VEHICLE_INSPECTION_COMPLETED: 43,
  VEHICLE_INSPECTION_STARTED: 44,
  OFFER_RESPONSE: 45,
  VEHICLE_TOS_ACCEPTED: 46,
  SUBSCRIPTION_CANCELLED: 47,
  INVOICE_CANCELLED: 48,
  RELOAD_DOMAIN: 49,
  CANCEL_PAYMENT_TRANSACTION: 50,
  SMS_RECEIVED: 51,
  CUSTOMER_SCORED: 52,
  CUSTOMER_QUALIFIED: 53,
  CUSTOMER_SCORE_DISQUALIFIED: 54,
  PAYMENT_REFUNDED: 55,
  CUSTOMER_DATA_ENRICHED: 56,
  RESERVATION_CREATED: 57,
  RESERVATION_STATUS_UPDATED: 58,
  PROGRAM_CREATED: 59,
  COLOR_OPTION_CREATED: 60,
  PRICING_OPTION_CREATED: 61,
  PENDING_SUBSCRIPTION_INITIALIZED: 62,
  RESERVATION_CANCELLED: 63,
  SYNC_INGESTION: 64,
  VEHICLE_INGESTION: 65,
  VEHICLE_UPDATED: 66,
  VEHICLE_DELETED: 67,
  PROCESS_DUE_STATEMENTS: 68,
  STATEMENT_DUE: 69,
  SUBSCRIPTION_ACTIVATED: 70,
  CUSTOMER_PHONE_UPDATED: 71,
  PROMOTION_CREATED: 72,
  PROMOTION_UPDATED: 73,
  PROMOTION_ACTIVITY: 74,
  DRAFT_ORDER_UPDATED: 75,
  RESERVATION_OPTIONS_UPDATED: 76,
  PROMOTION_PARTNER_CREATED: 77,
  PROMOTION_PARTNER_UPDATED: 78,
  LOCATION_UPDATED: 79,
  SUBSCRIPTION_UPDATED: 80,
  ODOMETER_UPDATE: 81,
  LOCATION_UPDATE: 82,
  APPOINTMENT_CREATED: 83,
  APPOINTMENT_TYPE_UPDATED: 84,
  APPOINTMENT_STATUS_UPDATED: 85,
  APPOINTMENT_VEHICLE_UPDATED: 86,
  AVAILABILITY_CREATED: 87,
  APPOINTMENT_CANCELLED: 88,
  PROGRAM_PRICING_OPTIONS_UPDATED: 89,
  APPOINTMENT_UPDATED: 90,
  VEHICLE_PREFERENCES_NOT_AVAILABLE: 91,
  SEND_NOTIFICATIONS: 92,
  NOTIFICATIONS_RECEIVED: 93,
  NOTIFICATION_STATUS_UPDATED: 94,
  DEALERSHIP_CREATED: 100,
  DEALERSHIP_USER_CREATED: 101,
  CUSTOMER_BANK_VERIFIED: 102,
  IS_QUALIFIED_FOR_LIBERTY: 103,
  INSURANCE_POLICY_CREATED: 104,
  DEALERSHIP_USER_UPDATED: 105,
  DEALERSHIP_UPDATED: 106,
  PHONE_NUMBER_ENTERED: 107,
  LICENSE_INFO_SUBMITTED: 108,
  VEHICLE_INFLEETING_UPDATED: 109,
  EXTEND_INSURANCE_POLICIES: 110,
  INSURANCE_POLICY_UPDATED: 111,
  MILES_PURCHASED: 112,
  INSURANCE_POLICY_CANCELLED: 113,
  SUBSCRIPTION_VEHICLE_CHANGE: 114,
  SUBSCRIPTION_VEHICLE_RETURNED: 115
};

/**
 * @return {proto.events.Event.PayloadCase}
 */
proto.events.Event.prototype.getPayloadCase = function() {
  return /** @type {proto.events.Event.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.events.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.events.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, 0),
    domain: jspb.Message.getFieldWithDefault(msg, 2, 0),
    domainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    traceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    version: jspb.Message.getFieldWithDefault(msg, 7, 0),
    modelVersion: jspb.Message.getFieldWithDefault(msg, 8, 0),
    customerCreated: (f = msg.getCustomerCreated()) && proto.events.CustomerCreated.toObject(includeInstance, f),
    customerInfoUpdated: (f = msg.getCustomerInfoUpdated()) && proto.events.CustomerInfoUpdated.toObject(includeInstance, f),
    customerStateUpdated: (f = msg.getCustomerStateUpdated()) && proto.events.CustomerStateUpdated.toObject(includeInstance, f),
    customerFailureStateUpdated: (f = msg.getCustomerFailureStateUpdated()) && proto.events.CustomerFailureStateUpdated.toObject(includeInstance, f),
    customerActivityUpdated: (f = msg.getCustomerActivityUpdated()) && proto.events.CustomerActivityUpdated.toObject(includeInstance, f),
    activityStateUpdated: (f = msg.getActivityStateUpdated()) && proto.events.ActivityStateUpdated.toObject(includeInstance, f),
    vendorAccountUpdated: (f = msg.getVendorAccountUpdated()) && proto.events.VendorAccountUpdated.toObject(includeInstance, f),
    customerDeleted: (f = msg.getCustomerDeleted()) && proto.events.CustomerDeleted.toObject(includeInstance, f),
    pickupDateSet: (f = msg.getPickupDateSet()) && proto.events.PickupDateSet.toObject(includeInstance, f),
    vehiclePrepickup: (f = msg.getVehiclePrepickup()) && proto.events.VehiclePrePickup.toObject(includeInstance, f),
    vehiclePickup: (f = msg.getVehiclePickup()) && proto.events.VehiclePickup.toObject(includeInstance, f),
    prepickupAuth: (f = msg.getPrepickupAuth()) && proto.events.PrepickupAuth.toObject(includeInstance, f),
    pickupPayment: (f = msg.getPickupPayment()) && proto.events.PickupPayment.toObject(includeInstance, f),
    transferVehicle: (f = msg.getTransferVehicle()) && proto.events.TransferVehicle.toObject(includeInstance, f),
    vehicleCreated: (f = msg.getVehicleCreated()) && proto.events.VehicleCreated.toObject(includeInstance, f),
    tripReceived: (f = msg.getTripReceived()) && proto.events.TripReceived.toObject(includeInstance, f),
    collisionReceived: (f = msg.getCollisionReceived()) && proto.events.CollisionReceived.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.events.Notification.toObject(includeInstance, f),
    invoiceReady: (f = msg.getInvoiceReady()) && proto.events.InvoiceReady.toObject(includeInstance, f),
    odometerUpdated: (f = msg.getOdometerUpdated()) && proto.events.OdometerUpdated.toObject(includeInstance, f),
    odometerSet: (f = msg.getOdometerSet()) && proto.events.OdometerSet.toObject(includeInstance, f),
    statementActivity: (f = msg.getStatementActivity()) && proto.events.StatementActivity.toObject(includeInstance, f),
    paymentActivity: (f = msg.getPaymentActivity()) && proto.events.PaymentActivity.toObject(includeInstance, f),
    vehicleStateUpdated: (f = msg.getVehicleStateUpdated()) && proto.events.VehicleStateUpdated.toObject(includeInstance, f),
    vehicleActivityUpdated: (f = msg.getVehicleActivityUpdated()) && proto.events.VehicleActivityUpdated.toObject(includeInstance, f),
    vehicleActivityStateUpdated: (f = msg.getVehicleActivityStateUpdated()) && proto.events.VehicleActivityStateUpdated.toObject(includeInstance, f),
    vehicleActivityFailureUpdated: (f = msg.getVehicleActivityFailureUpdated()) && proto.events.VehicleActivityFailureUpdated.toObject(includeInstance, f),
    customerInsuranceUpdated: (f = msg.getCustomerInsuranceUpdated()) && proto.events.CustomerInsuranceUpdated.toObject(includeInstance, f),
    syncDealerships: (f = msg.getSyncDealerships()) && proto.events.SyncDealerships.toObject(includeInstance, f),
    invoiceToBePaid: (f = msg.getInvoiceToBePaid()) && proto.events.InvoiceToBePaid.toObject(includeInstance, f),
    documentAdded: (f = msg.getDocumentAdded()) && proto.events.DocumentAdded.toObject(includeInstance, f),
    documentDeleted: (f = msg.getDocumentDeleted()) && proto.events.DocumentDeleted.toObject(includeInstance, f),
    offerSubmitted: (f = msg.getOfferSubmitted()) && proto.events.OfferSubmitted.toObject(includeInstance, f),
    subscriptionContractAdded: (f = msg.getSubscriptionContractAdded()) && proto.events.SubscriptionContractAdded.toObject(includeInstance, f),
    vehicleInspectionCompleted: (f = msg.getVehicleInspectionCompleted()) && proto.events.VehicleInspectionCompleted.toObject(includeInstance, f),
    vehicleInspectionStarted: (f = msg.getVehicleInspectionStarted()) && proto.events.VehicleInspectionStarted.toObject(includeInstance, f),
    offerResponse: (f = msg.getOfferResponse()) && proto.events.OfferResponse.toObject(includeInstance, f),
    vehicleTosAccepted: (f = msg.getVehicleTosAccepted()) && proto.events.VehicleTosAccepted.toObject(includeInstance, f),
    subscriptionCancelled: (f = msg.getSubscriptionCancelled()) && proto.events.SubscriptionCancelled.toObject(includeInstance, f),
    invoiceCancelled: (f = msg.getInvoiceCancelled()) && proto.events.InvoiceCancelled.toObject(includeInstance, f),
    reloadDomain: (f = msg.getReloadDomain()) && proto.events.ReloadDomain.toObject(includeInstance, f),
    cancelPaymentTransaction: (f = msg.getCancelPaymentTransaction()) && proto.events.CancelPaymentTransaction.toObject(includeInstance, f),
    smsReceived: (f = msg.getSmsReceived()) && proto.events.SmsReceived.toObject(includeInstance, f),
    customerScored: (f = msg.getCustomerScored()) && proto.events.CustomerScored.toObject(includeInstance, f),
    customerQualified: (f = msg.getCustomerQualified()) && proto.events.CustomerQualified.toObject(includeInstance, f),
    customerScoreDisqualified: (f = msg.getCustomerScoreDisqualified()) && proto.events.CustomerScoreDisqualified.toObject(includeInstance, f),
    paymentRefunded: (f = msg.getPaymentRefunded()) && proto.events.PaymentRefunded.toObject(includeInstance, f),
    customerDataEnriched: (f = msg.getCustomerDataEnriched()) && proto.events.CustomerDataEnriched.toObject(includeInstance, f),
    reservationCreated: (f = msg.getReservationCreated()) && subscription_pb.ReservationCreated.toObject(includeInstance, f),
    reservationStatusUpdated: (f = msg.getReservationStatusUpdated()) && subscription_pb.ReservationStatusUpdated.toObject(includeInstance, f),
    programCreated: (f = msg.getProgramCreated()) && subscription_pb.ProgramCreated.toObject(includeInstance, f),
    colorOptionCreated: (f = msg.getColorOptionCreated()) && subscription_pb.ColorOptionCreated.toObject(includeInstance, f),
    pricingOptionCreated: (f = msg.getPricingOptionCreated()) && subscription_pb.PricingOptionCreated.toObject(includeInstance, f),
    pendingSubscriptionInitialized: (f = msg.getPendingSubscriptionInitialized()) && subscription_pb.PendingSubscriptionInitialized.toObject(includeInstance, f),
    reservationCancelled: (f = msg.getReservationCancelled()) && subscription_pb.ReservationCancelled.toObject(includeInstance, f),
    syncIngestion: (f = msg.getSyncIngestion()) && proto.events.SyncIngestion.toObject(includeInstance, f),
    vehicleIngestion: (f = msg.getVehicleIngestion()) && vehicle_pb.Ingestion.toObject(includeInstance, f),
    vehicleUpdated: (f = msg.getVehicleUpdated()) && vehicle_pb.VehicleUpdated.toObject(includeInstance, f),
    vehicleDeleted: (f = msg.getVehicleDeleted()) && vehicle_pb.VehicleDeleted.toObject(includeInstance, f),
    processDueStatements: (f = msg.getProcessDueStatements()) && subscription_pb.ProcessDueStatements.toObject(includeInstance, f),
    statementDue: (f = msg.getStatementDue()) && subscription_pb.StatementDue.toObject(includeInstance, f),
    subscriptionActivated: (f = msg.getSubscriptionActivated()) && subscription_pb.SubscriptionActivated.toObject(includeInstance, f),
    customerPhoneUpdated: (f = msg.getCustomerPhoneUpdated()) && customer_pb.CustomerPhoneUpdated.toObject(includeInstance, f),
    promotionCreated: (f = msg.getPromotionCreated()) && billing_service_pb.Promotion.toObject(includeInstance, f),
    promotionUpdated: (f = msg.getPromotionUpdated()) && billing_service_pb.Promotion.toObject(includeInstance, f),
    promotionActivity: (f = msg.getPromotionActivity()) && billing_service_pb.PromotionActivity.toObject(includeInstance, f),
    draftOrderUpdated: (f = msg.getDraftOrderUpdated()) && billing_service_pb.DraftOrderUpdated.toObject(includeInstance, f),
    reservationOptionsUpdated: (f = msg.getReservationOptionsUpdated()) && subscription_pb.ReservationOptionsUpdated.toObject(includeInstance, f),
    promotionPartnerCreated: (f = msg.getPromotionPartnerCreated()) && billing_service_pb.PromotionPartner.toObject(includeInstance, f),
    promotionPartnerUpdated: (f = msg.getPromotionPartnerUpdated()) && billing_service_pb.PromotionPartner.toObject(includeInstance, f),
    locationUpdated: (f = msg.getLocationUpdated()) && vehicle_pb.LocationUpdated.toObject(includeInstance, f),
    subscriptionUpdated: (f = msg.getSubscriptionUpdated()) && subscription_pb.SubscriptionUpdated.toObject(includeInstance, f),
    odometerUpdate: (f = msg.getOdometerUpdate()) && vehicle_pb.OdometerUpdate.toObject(includeInstance, f),
    locationUpdate: (f = msg.getLocationUpdate()) && vehicle_pb.LocationUpdate.toObject(includeInstance, f),
    appointmentCreated: (f = msg.getAppointmentCreated()) && appointment_pb.AppointmentCreated.toObject(includeInstance, f),
    appointmentTypeUpdated: (f = msg.getAppointmentTypeUpdated()) && appointment_pb.AppointmentTypeUpdated.toObject(includeInstance, f),
    appointmentStatusUpdated: (f = msg.getAppointmentStatusUpdated()) && appointment_pb.AppointmentStatusUpdated.toObject(includeInstance, f),
    appointmentVehicleUpdated: (f = msg.getAppointmentVehicleUpdated()) && appointment_pb.AppointmentVehicleUpdated.toObject(includeInstance, f),
    availabilityCreated: (f = msg.getAvailabilityCreated()) && appointment_pb.AvailabilityCreated.toObject(includeInstance, f),
    appointmentCancelled: (f = msg.getAppointmentCancelled()) && appointment_pb.AppointmentCancelled.toObject(includeInstance, f),
    programPricingOptionsUpdated: (f = msg.getProgramPricingOptionsUpdated()) && subscription_pb.ProgramPricingOptionsUpdated.toObject(includeInstance, f),
    appointmentUpdated: (f = msg.getAppointmentUpdated()) && appointment_pb.AppointmentUpdated.toObject(includeInstance, f),
    vehiclePreferencesNotAvailable: (f = msg.getVehiclePreferencesNotAvailable()) && vehicle_pb.VehiclePreferencesNotAvailable.toObject(includeInstance, f),
    sendNotifications: (f = msg.getSendNotifications()) && notifications_pb.SendNotifications.toObject(includeInstance, f),
    notificationsReceived: (f = msg.getNotificationsReceived()) && notifications_pb.NotificationReceived.toObject(includeInstance, f),
    notificationStatusUpdated: (f = msg.getNotificationStatusUpdated()) && notifications_pb.NotificationStatusUpdated.toObject(includeInstance, f),
    dealershipCreated: (f = msg.getDealershipCreated()) && dealer_pb.DealershipCreated.toObject(includeInstance, f),
    dealershipUserCreated: (f = msg.getDealershipUserCreated()) && dealer_pb.DealershipUserCreated.toObject(includeInstance, f),
    customerBankVerified: (f = msg.getCustomerBankVerified()) && customer_pb.CustomerBankAccountVerified.toObject(includeInstance, f),
    isQualifiedForLiberty: (f = msg.getIsQualifiedForLiberty()) && insurance_service_pb.IsQualifiedForLiberty.toObject(includeInstance, f),
    insurancePolicyCreated: (f = msg.getInsurancePolicyCreated()) && insurance_service_pb.InsurancePolicyCreated.toObject(includeInstance, f),
    dealershipUserUpdated: (f = msg.getDealershipUserUpdated()) && dealer_pb.DealershipUserUpdated.toObject(includeInstance, f),
    dealershipUpdated: (f = msg.getDealershipUpdated()) && dealer_pb.DealershipUpdated.toObject(includeInstance, f),
    phoneNumberEntered: (f = msg.getPhoneNumberEntered()) && customer_pb.PhoneNumberEntered.toObject(includeInstance, f),
    licenseInfoSubmitted: (f = msg.getLicenseInfoSubmitted()) && customer_pb.LicenseInfoSubmitted.toObject(includeInstance, f),
    vehicleInfleetingUpdated: (f = msg.getVehicleInfleetingUpdated()) && vehicle_pb.VehicleInfleetingUpdated.toObject(includeInstance, f),
    extendInsurancePolicies: (f = msg.getExtendInsurancePolicies()) && proto.events.ExtendInsurancePolicies.toObject(includeInstance, f),
    insurancePolicyUpdated: (f = msg.getInsurancePolicyUpdated()) && insurance_service_pb.InsurancePolicyUpdated.toObject(includeInstance, f),
    milesPurchased: (f = msg.getMilesPurchased()) && proto.events.MilesPurchased.toObject(includeInstance, f),
    insurancePolicyCancelled: (f = msg.getInsurancePolicyCancelled()) && insurance_service_pb.InsurancePolicyCancelled.toObject(includeInstance, f),
    subscriptionVehicleChange: (f = msg.getSubscriptionVehicleChange()) && proto.events.SubscriptionVehicleChange.toObject(includeInstance, f),
    subscriptionVehicleReturned: (f = msg.getSubscriptionVehicleReturned()) && proto.events.SubscriptionVehicleReturned.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.Event}
 */
proto.events.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.Event;
  return proto.events.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.Event}
 */
proto.events.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.events.EventCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {!proto.events.Domain} */ (reader.readEnum());
      msg.setDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModelVersion(value);
      break;
    case 9:
      var value = new proto.events.CustomerCreated;
      reader.readMessage(value,proto.events.CustomerCreated.deserializeBinaryFromReader);
      msg.setCustomerCreated(value);
      break;
    case 10:
      var value = new proto.events.CustomerInfoUpdated;
      reader.readMessage(value,proto.events.CustomerInfoUpdated.deserializeBinaryFromReader);
      msg.setCustomerInfoUpdated(value);
      break;
    case 11:
      var value = new proto.events.CustomerStateUpdated;
      reader.readMessage(value,proto.events.CustomerStateUpdated.deserializeBinaryFromReader);
      msg.setCustomerStateUpdated(value);
      break;
    case 12:
      var value = new proto.events.CustomerFailureStateUpdated;
      reader.readMessage(value,proto.events.CustomerFailureStateUpdated.deserializeBinaryFromReader);
      msg.setCustomerFailureStateUpdated(value);
      break;
    case 13:
      var value = new proto.events.CustomerActivityUpdated;
      reader.readMessage(value,proto.events.CustomerActivityUpdated.deserializeBinaryFromReader);
      msg.setCustomerActivityUpdated(value);
      break;
    case 14:
      var value = new proto.events.ActivityStateUpdated;
      reader.readMessage(value,proto.events.ActivityStateUpdated.deserializeBinaryFromReader);
      msg.setActivityStateUpdated(value);
      break;
    case 15:
      var value = new proto.events.VendorAccountUpdated;
      reader.readMessage(value,proto.events.VendorAccountUpdated.deserializeBinaryFromReader);
      msg.setVendorAccountUpdated(value);
      break;
    case 16:
      var value = new proto.events.CustomerDeleted;
      reader.readMessage(value,proto.events.CustomerDeleted.deserializeBinaryFromReader);
      msg.setCustomerDeleted(value);
      break;
    case 17:
      var value = new proto.events.PickupDateSet;
      reader.readMessage(value,proto.events.PickupDateSet.deserializeBinaryFromReader);
      msg.setPickupDateSet(value);
      break;
    case 18:
      var value = new proto.events.VehiclePrePickup;
      reader.readMessage(value,proto.events.VehiclePrePickup.deserializeBinaryFromReader);
      msg.setVehiclePrepickup(value);
      break;
    case 19:
      var value = new proto.events.VehiclePickup;
      reader.readMessage(value,proto.events.VehiclePickup.deserializeBinaryFromReader);
      msg.setVehiclePickup(value);
      break;
    case 20:
      var value = new proto.events.PrepickupAuth;
      reader.readMessage(value,proto.events.PrepickupAuth.deserializeBinaryFromReader);
      msg.setPrepickupAuth(value);
      break;
    case 21:
      var value = new proto.events.PickupPayment;
      reader.readMessage(value,proto.events.PickupPayment.deserializeBinaryFromReader);
      msg.setPickupPayment(value);
      break;
    case 22:
      var value = new proto.events.TransferVehicle;
      reader.readMessage(value,proto.events.TransferVehicle.deserializeBinaryFromReader);
      msg.setTransferVehicle(value);
      break;
    case 23:
      var value = new proto.events.VehicleCreated;
      reader.readMessage(value,proto.events.VehicleCreated.deserializeBinaryFromReader);
      msg.setVehicleCreated(value);
      break;
    case 24:
      var value = new proto.events.TripReceived;
      reader.readMessage(value,proto.events.TripReceived.deserializeBinaryFromReader);
      msg.setTripReceived(value);
      break;
    case 25:
      var value = new proto.events.CollisionReceived;
      reader.readMessage(value,proto.events.CollisionReceived.deserializeBinaryFromReader);
      msg.setCollisionReceived(value);
      break;
    case 26:
      var value = new proto.events.Notification;
      reader.readMessage(value,proto.events.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 27:
      var value = new proto.events.InvoiceReady;
      reader.readMessage(value,proto.events.InvoiceReady.deserializeBinaryFromReader);
      msg.setInvoiceReady(value);
      break;
    case 28:
      var value = new proto.events.OdometerUpdated;
      reader.readMessage(value,proto.events.OdometerUpdated.deserializeBinaryFromReader);
      msg.setOdometerUpdated(value);
      break;
    case 29:
      var value = new proto.events.OdometerSet;
      reader.readMessage(value,proto.events.OdometerSet.deserializeBinaryFromReader);
      msg.setOdometerSet(value);
      break;
    case 30:
      var value = new proto.events.StatementActivity;
      reader.readMessage(value,proto.events.StatementActivity.deserializeBinaryFromReader);
      msg.setStatementActivity(value);
      break;
    case 31:
      var value = new proto.events.PaymentActivity;
      reader.readMessage(value,proto.events.PaymentActivity.deserializeBinaryFromReader);
      msg.setPaymentActivity(value);
      break;
    case 32:
      var value = new proto.events.VehicleStateUpdated;
      reader.readMessage(value,proto.events.VehicleStateUpdated.deserializeBinaryFromReader);
      msg.setVehicleStateUpdated(value);
      break;
    case 33:
      var value = new proto.events.VehicleActivityUpdated;
      reader.readMessage(value,proto.events.VehicleActivityUpdated.deserializeBinaryFromReader);
      msg.setVehicleActivityUpdated(value);
      break;
    case 34:
      var value = new proto.events.VehicleActivityStateUpdated;
      reader.readMessage(value,proto.events.VehicleActivityStateUpdated.deserializeBinaryFromReader);
      msg.setVehicleActivityStateUpdated(value);
      break;
    case 35:
      var value = new proto.events.VehicleActivityFailureUpdated;
      reader.readMessage(value,proto.events.VehicleActivityFailureUpdated.deserializeBinaryFromReader);
      msg.setVehicleActivityFailureUpdated(value);
      break;
    case 36:
      var value = new proto.events.CustomerInsuranceUpdated;
      reader.readMessage(value,proto.events.CustomerInsuranceUpdated.deserializeBinaryFromReader);
      msg.setCustomerInsuranceUpdated(value);
      break;
    case 37:
      var value = new proto.events.SyncDealerships;
      reader.readMessage(value,proto.events.SyncDealerships.deserializeBinaryFromReader);
      msg.setSyncDealerships(value);
      break;
    case 38:
      var value = new proto.events.InvoiceToBePaid;
      reader.readMessage(value,proto.events.InvoiceToBePaid.deserializeBinaryFromReader);
      msg.setInvoiceToBePaid(value);
      break;
    case 39:
      var value = new proto.events.DocumentAdded;
      reader.readMessage(value,proto.events.DocumentAdded.deserializeBinaryFromReader);
      msg.setDocumentAdded(value);
      break;
    case 40:
      var value = new proto.events.DocumentDeleted;
      reader.readMessage(value,proto.events.DocumentDeleted.deserializeBinaryFromReader);
      msg.setDocumentDeleted(value);
      break;
    case 41:
      var value = new proto.events.OfferSubmitted;
      reader.readMessage(value,proto.events.OfferSubmitted.deserializeBinaryFromReader);
      msg.setOfferSubmitted(value);
      break;
    case 42:
      var value = new proto.events.SubscriptionContractAdded;
      reader.readMessage(value,proto.events.SubscriptionContractAdded.deserializeBinaryFromReader);
      msg.setSubscriptionContractAdded(value);
      break;
    case 43:
      var value = new proto.events.VehicleInspectionCompleted;
      reader.readMessage(value,proto.events.VehicleInspectionCompleted.deserializeBinaryFromReader);
      msg.setVehicleInspectionCompleted(value);
      break;
    case 44:
      var value = new proto.events.VehicleInspectionStarted;
      reader.readMessage(value,proto.events.VehicleInspectionStarted.deserializeBinaryFromReader);
      msg.setVehicleInspectionStarted(value);
      break;
    case 45:
      var value = new proto.events.OfferResponse;
      reader.readMessage(value,proto.events.OfferResponse.deserializeBinaryFromReader);
      msg.setOfferResponse(value);
      break;
    case 46:
      var value = new proto.events.VehicleTosAccepted;
      reader.readMessage(value,proto.events.VehicleTosAccepted.deserializeBinaryFromReader);
      msg.setVehicleTosAccepted(value);
      break;
    case 47:
      var value = new proto.events.SubscriptionCancelled;
      reader.readMessage(value,proto.events.SubscriptionCancelled.deserializeBinaryFromReader);
      msg.setSubscriptionCancelled(value);
      break;
    case 48:
      var value = new proto.events.InvoiceCancelled;
      reader.readMessage(value,proto.events.InvoiceCancelled.deserializeBinaryFromReader);
      msg.setInvoiceCancelled(value);
      break;
    case 49:
      var value = new proto.events.ReloadDomain;
      reader.readMessage(value,proto.events.ReloadDomain.deserializeBinaryFromReader);
      msg.setReloadDomain(value);
      break;
    case 50:
      var value = new proto.events.CancelPaymentTransaction;
      reader.readMessage(value,proto.events.CancelPaymentTransaction.deserializeBinaryFromReader);
      msg.setCancelPaymentTransaction(value);
      break;
    case 51:
      var value = new proto.events.SmsReceived;
      reader.readMessage(value,proto.events.SmsReceived.deserializeBinaryFromReader);
      msg.setSmsReceived(value);
      break;
    case 52:
      var value = new proto.events.CustomerScored;
      reader.readMessage(value,proto.events.CustomerScored.deserializeBinaryFromReader);
      msg.setCustomerScored(value);
      break;
    case 53:
      var value = new proto.events.CustomerQualified;
      reader.readMessage(value,proto.events.CustomerQualified.deserializeBinaryFromReader);
      msg.setCustomerQualified(value);
      break;
    case 54:
      var value = new proto.events.CustomerScoreDisqualified;
      reader.readMessage(value,proto.events.CustomerScoreDisqualified.deserializeBinaryFromReader);
      msg.setCustomerScoreDisqualified(value);
      break;
    case 55:
      var value = new proto.events.PaymentRefunded;
      reader.readMessage(value,proto.events.PaymentRefunded.deserializeBinaryFromReader);
      msg.setPaymentRefunded(value);
      break;
    case 56:
      var value = new proto.events.CustomerDataEnriched;
      reader.readMessage(value,proto.events.CustomerDataEnriched.deserializeBinaryFromReader);
      msg.setCustomerDataEnriched(value);
      break;
    case 57:
      var value = new subscription_pb.ReservationCreated;
      reader.readMessage(value,subscription_pb.ReservationCreated.deserializeBinaryFromReader);
      msg.setReservationCreated(value);
      break;
    case 58:
      var value = new subscription_pb.ReservationStatusUpdated;
      reader.readMessage(value,subscription_pb.ReservationStatusUpdated.deserializeBinaryFromReader);
      msg.setReservationStatusUpdated(value);
      break;
    case 59:
      var value = new subscription_pb.ProgramCreated;
      reader.readMessage(value,subscription_pb.ProgramCreated.deserializeBinaryFromReader);
      msg.setProgramCreated(value);
      break;
    case 60:
      var value = new subscription_pb.ColorOptionCreated;
      reader.readMessage(value,subscription_pb.ColorOptionCreated.deserializeBinaryFromReader);
      msg.setColorOptionCreated(value);
      break;
    case 61:
      var value = new subscription_pb.PricingOptionCreated;
      reader.readMessage(value,subscription_pb.PricingOptionCreated.deserializeBinaryFromReader);
      msg.setPricingOptionCreated(value);
      break;
    case 62:
      var value = new subscription_pb.PendingSubscriptionInitialized;
      reader.readMessage(value,subscription_pb.PendingSubscriptionInitialized.deserializeBinaryFromReader);
      msg.setPendingSubscriptionInitialized(value);
      break;
    case 63:
      var value = new subscription_pb.ReservationCancelled;
      reader.readMessage(value,subscription_pb.ReservationCancelled.deserializeBinaryFromReader);
      msg.setReservationCancelled(value);
      break;
    case 64:
      var value = new proto.events.SyncIngestion;
      reader.readMessage(value,proto.events.SyncIngestion.deserializeBinaryFromReader);
      msg.setSyncIngestion(value);
      break;
    case 65:
      var value = new vehicle_pb.Ingestion;
      reader.readMessage(value,vehicle_pb.Ingestion.deserializeBinaryFromReader);
      msg.setVehicleIngestion(value);
      break;
    case 66:
      var value = new vehicle_pb.VehicleUpdated;
      reader.readMessage(value,vehicle_pb.VehicleUpdated.deserializeBinaryFromReader);
      msg.setVehicleUpdated(value);
      break;
    case 67:
      var value = new vehicle_pb.VehicleDeleted;
      reader.readMessage(value,vehicle_pb.VehicleDeleted.deserializeBinaryFromReader);
      msg.setVehicleDeleted(value);
      break;
    case 68:
      var value = new subscription_pb.ProcessDueStatements;
      reader.readMessage(value,subscription_pb.ProcessDueStatements.deserializeBinaryFromReader);
      msg.setProcessDueStatements(value);
      break;
    case 69:
      var value = new subscription_pb.StatementDue;
      reader.readMessage(value,subscription_pb.StatementDue.deserializeBinaryFromReader);
      msg.setStatementDue(value);
      break;
    case 70:
      var value = new subscription_pb.SubscriptionActivated;
      reader.readMessage(value,subscription_pb.SubscriptionActivated.deserializeBinaryFromReader);
      msg.setSubscriptionActivated(value);
      break;
    case 71:
      var value = new customer_pb.CustomerPhoneUpdated;
      reader.readMessage(value,customer_pb.CustomerPhoneUpdated.deserializeBinaryFromReader);
      msg.setCustomerPhoneUpdated(value);
      break;
    case 72:
      var value = new billing_service_pb.Promotion;
      reader.readMessage(value,billing_service_pb.Promotion.deserializeBinaryFromReader);
      msg.setPromotionCreated(value);
      break;
    case 73:
      var value = new billing_service_pb.Promotion;
      reader.readMessage(value,billing_service_pb.Promotion.deserializeBinaryFromReader);
      msg.setPromotionUpdated(value);
      break;
    case 74:
      var value = new billing_service_pb.PromotionActivity;
      reader.readMessage(value,billing_service_pb.PromotionActivity.deserializeBinaryFromReader);
      msg.setPromotionActivity(value);
      break;
    case 75:
      var value = new billing_service_pb.DraftOrderUpdated;
      reader.readMessage(value,billing_service_pb.DraftOrderUpdated.deserializeBinaryFromReader);
      msg.setDraftOrderUpdated(value);
      break;
    case 76:
      var value = new subscription_pb.ReservationOptionsUpdated;
      reader.readMessage(value,subscription_pb.ReservationOptionsUpdated.deserializeBinaryFromReader);
      msg.setReservationOptionsUpdated(value);
      break;
    case 77:
      var value = new billing_service_pb.PromotionPartner;
      reader.readMessage(value,billing_service_pb.PromotionPartner.deserializeBinaryFromReader);
      msg.setPromotionPartnerCreated(value);
      break;
    case 78:
      var value = new billing_service_pb.PromotionPartner;
      reader.readMessage(value,billing_service_pb.PromotionPartner.deserializeBinaryFromReader);
      msg.setPromotionPartnerUpdated(value);
      break;
    case 79:
      var value = new vehicle_pb.LocationUpdated;
      reader.readMessage(value,vehicle_pb.LocationUpdated.deserializeBinaryFromReader);
      msg.setLocationUpdated(value);
      break;
    case 80:
      var value = new subscription_pb.SubscriptionUpdated;
      reader.readMessage(value,subscription_pb.SubscriptionUpdated.deserializeBinaryFromReader);
      msg.setSubscriptionUpdated(value);
      break;
    case 81:
      var value = new vehicle_pb.OdometerUpdate;
      reader.readMessage(value,vehicle_pb.OdometerUpdate.deserializeBinaryFromReader);
      msg.setOdometerUpdate(value);
      break;
    case 82:
      var value = new vehicle_pb.LocationUpdate;
      reader.readMessage(value,vehicle_pb.LocationUpdate.deserializeBinaryFromReader);
      msg.setLocationUpdate(value);
      break;
    case 83:
      var value = new appointment_pb.AppointmentCreated;
      reader.readMessage(value,appointment_pb.AppointmentCreated.deserializeBinaryFromReader);
      msg.setAppointmentCreated(value);
      break;
    case 84:
      var value = new appointment_pb.AppointmentTypeUpdated;
      reader.readMessage(value,appointment_pb.AppointmentTypeUpdated.deserializeBinaryFromReader);
      msg.setAppointmentTypeUpdated(value);
      break;
    case 85:
      var value = new appointment_pb.AppointmentStatusUpdated;
      reader.readMessage(value,appointment_pb.AppointmentStatusUpdated.deserializeBinaryFromReader);
      msg.setAppointmentStatusUpdated(value);
      break;
    case 86:
      var value = new appointment_pb.AppointmentVehicleUpdated;
      reader.readMessage(value,appointment_pb.AppointmentVehicleUpdated.deserializeBinaryFromReader);
      msg.setAppointmentVehicleUpdated(value);
      break;
    case 87:
      var value = new appointment_pb.AvailabilityCreated;
      reader.readMessage(value,appointment_pb.AvailabilityCreated.deserializeBinaryFromReader);
      msg.setAvailabilityCreated(value);
      break;
    case 88:
      var value = new appointment_pb.AppointmentCancelled;
      reader.readMessage(value,appointment_pb.AppointmentCancelled.deserializeBinaryFromReader);
      msg.setAppointmentCancelled(value);
      break;
    case 89:
      var value = new subscription_pb.ProgramPricingOptionsUpdated;
      reader.readMessage(value,subscription_pb.ProgramPricingOptionsUpdated.deserializeBinaryFromReader);
      msg.setProgramPricingOptionsUpdated(value);
      break;
    case 90:
      var value = new appointment_pb.AppointmentUpdated;
      reader.readMessage(value,appointment_pb.AppointmentUpdated.deserializeBinaryFromReader);
      msg.setAppointmentUpdated(value);
      break;
    case 91:
      var value = new vehicle_pb.VehiclePreferencesNotAvailable;
      reader.readMessage(value,vehicle_pb.VehiclePreferencesNotAvailable.deserializeBinaryFromReader);
      msg.setVehiclePreferencesNotAvailable(value);
      break;
    case 92:
      var value = new notifications_pb.SendNotifications;
      reader.readMessage(value,notifications_pb.SendNotifications.deserializeBinaryFromReader);
      msg.setSendNotifications(value);
      break;
    case 93:
      var value = new notifications_pb.NotificationReceived;
      reader.readMessage(value,notifications_pb.NotificationReceived.deserializeBinaryFromReader);
      msg.setNotificationsReceived(value);
      break;
    case 94:
      var value = new notifications_pb.NotificationStatusUpdated;
      reader.readMessage(value,notifications_pb.NotificationStatusUpdated.deserializeBinaryFromReader);
      msg.setNotificationStatusUpdated(value);
      break;
    case 100:
      var value = new dealer_pb.DealershipCreated;
      reader.readMessage(value,dealer_pb.DealershipCreated.deserializeBinaryFromReader);
      msg.setDealershipCreated(value);
      break;
    case 101:
      var value = new dealer_pb.DealershipUserCreated;
      reader.readMessage(value,dealer_pb.DealershipUserCreated.deserializeBinaryFromReader);
      msg.setDealershipUserCreated(value);
      break;
    case 102:
      var value = new customer_pb.CustomerBankAccountVerified;
      reader.readMessage(value,customer_pb.CustomerBankAccountVerified.deserializeBinaryFromReader);
      msg.setCustomerBankVerified(value);
      break;
    case 103:
      var value = new insurance_service_pb.IsQualifiedForLiberty;
      reader.readMessage(value,insurance_service_pb.IsQualifiedForLiberty.deserializeBinaryFromReader);
      msg.setIsQualifiedForLiberty(value);
      break;
    case 104:
      var value = new insurance_service_pb.InsurancePolicyCreated;
      reader.readMessage(value,insurance_service_pb.InsurancePolicyCreated.deserializeBinaryFromReader);
      msg.setInsurancePolicyCreated(value);
      break;
    case 105:
      var value = new dealer_pb.DealershipUserUpdated;
      reader.readMessage(value,dealer_pb.DealershipUserUpdated.deserializeBinaryFromReader);
      msg.setDealershipUserUpdated(value);
      break;
    case 106:
      var value = new dealer_pb.DealershipUpdated;
      reader.readMessage(value,dealer_pb.DealershipUpdated.deserializeBinaryFromReader);
      msg.setDealershipUpdated(value);
      break;
    case 107:
      var value = new customer_pb.PhoneNumberEntered;
      reader.readMessage(value,customer_pb.PhoneNumberEntered.deserializeBinaryFromReader);
      msg.setPhoneNumberEntered(value);
      break;
    case 108:
      var value = new customer_pb.LicenseInfoSubmitted;
      reader.readMessage(value,customer_pb.LicenseInfoSubmitted.deserializeBinaryFromReader);
      msg.setLicenseInfoSubmitted(value);
      break;
    case 109:
      var value = new vehicle_pb.VehicleInfleetingUpdated;
      reader.readMessage(value,vehicle_pb.VehicleInfleetingUpdated.deserializeBinaryFromReader);
      msg.setVehicleInfleetingUpdated(value);
      break;
    case 110:
      var value = new proto.events.ExtendInsurancePolicies;
      reader.readMessage(value,proto.events.ExtendInsurancePolicies.deserializeBinaryFromReader);
      msg.setExtendInsurancePolicies(value);
      break;
    case 111:
      var value = new insurance_service_pb.InsurancePolicyUpdated;
      reader.readMessage(value,insurance_service_pb.InsurancePolicyUpdated.deserializeBinaryFromReader);
      msg.setInsurancePolicyUpdated(value);
      break;
    case 112:
      var value = new proto.events.MilesPurchased;
      reader.readMessage(value,proto.events.MilesPurchased.deserializeBinaryFromReader);
      msg.setMilesPurchased(value);
      break;
    case 113:
      var value = new insurance_service_pb.InsurancePolicyCancelled;
      reader.readMessage(value,insurance_service_pb.InsurancePolicyCancelled.deserializeBinaryFromReader);
      msg.setInsurancePolicyCancelled(value);
      break;
    case 114:
      var value = new proto.events.SubscriptionVehicleChange;
      reader.readMessage(value,proto.events.SubscriptionVehicleChange.deserializeBinaryFromReader);
      msg.setSubscriptionVehicleChange(value);
      break;
    case 115:
      var value = new proto.events.SubscriptionVehicleReturned;
      reader.readMessage(value,proto.events.SubscriptionVehicleReturned.deserializeBinaryFromReader);
      msg.setSubscriptionVehicleReturned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTraceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getModelVersion();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCustomerCreated();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.events.CustomerCreated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerInfoUpdated();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.events.CustomerInfoUpdated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerStateUpdated();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.events.CustomerStateUpdated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerFailureStateUpdated();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.events.CustomerFailureStateUpdated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerActivityUpdated();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.events.CustomerActivityUpdated.serializeBinaryToWriter
    );
  }
  f = message.getActivityStateUpdated();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.events.ActivityStateUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVendorAccountUpdated();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.events.VendorAccountUpdated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerDeleted();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.events.CustomerDeleted.serializeBinaryToWriter
    );
  }
  f = message.getPickupDateSet();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.events.PickupDateSet.serializeBinaryToWriter
    );
  }
  f = message.getVehiclePrepickup();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.events.VehiclePrePickup.serializeBinaryToWriter
    );
  }
  f = message.getVehiclePickup();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.events.VehiclePickup.serializeBinaryToWriter
    );
  }
  f = message.getPrepickupAuth();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.events.PrepickupAuth.serializeBinaryToWriter
    );
  }
  f = message.getPickupPayment();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.events.PickupPayment.serializeBinaryToWriter
    );
  }
  f = message.getTransferVehicle();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.events.TransferVehicle.serializeBinaryToWriter
    );
  }
  f = message.getVehicleCreated();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.events.VehicleCreated.serializeBinaryToWriter
    );
  }
  f = message.getTripReceived();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.events.TripReceived.serializeBinaryToWriter
    );
  }
  f = message.getCollisionReceived();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.events.CollisionReceived.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.events.Notification.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceReady();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.events.InvoiceReady.serializeBinaryToWriter
    );
  }
  f = message.getOdometerUpdated();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.events.OdometerUpdated.serializeBinaryToWriter
    );
  }
  f = message.getOdometerSet();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.events.OdometerSet.serializeBinaryToWriter
    );
  }
  f = message.getStatementActivity();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.events.StatementActivity.serializeBinaryToWriter
    );
  }
  f = message.getPaymentActivity();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.events.PaymentActivity.serializeBinaryToWriter
    );
  }
  f = message.getVehicleStateUpdated();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.events.VehicleStateUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVehicleActivityUpdated();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.events.VehicleActivityUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVehicleActivityStateUpdated();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.events.VehicleActivityStateUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVehicleActivityFailureUpdated();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.events.VehicleActivityFailureUpdated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerInsuranceUpdated();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.events.CustomerInsuranceUpdated.serializeBinaryToWriter
    );
  }
  f = message.getSyncDealerships();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.events.SyncDealerships.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceToBePaid();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.events.InvoiceToBePaid.serializeBinaryToWriter
    );
  }
  f = message.getDocumentAdded();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.events.DocumentAdded.serializeBinaryToWriter
    );
  }
  f = message.getDocumentDeleted();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.events.DocumentDeleted.serializeBinaryToWriter
    );
  }
  f = message.getOfferSubmitted();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.events.OfferSubmitted.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionContractAdded();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.events.SubscriptionContractAdded.serializeBinaryToWriter
    );
  }
  f = message.getVehicleInspectionCompleted();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.events.VehicleInspectionCompleted.serializeBinaryToWriter
    );
  }
  f = message.getVehicleInspectionStarted();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.events.VehicleInspectionStarted.serializeBinaryToWriter
    );
  }
  f = message.getOfferResponse();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.events.OfferResponse.serializeBinaryToWriter
    );
  }
  f = message.getVehicleTosAccepted();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.events.VehicleTosAccepted.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionCancelled();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.events.SubscriptionCancelled.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceCancelled();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.events.InvoiceCancelled.serializeBinaryToWriter
    );
  }
  f = message.getReloadDomain();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      proto.events.ReloadDomain.serializeBinaryToWriter
    );
  }
  f = message.getCancelPaymentTransaction();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.events.CancelPaymentTransaction.serializeBinaryToWriter
    );
  }
  f = message.getSmsReceived();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.events.SmsReceived.serializeBinaryToWriter
    );
  }
  f = message.getCustomerScored();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.events.CustomerScored.serializeBinaryToWriter
    );
  }
  f = message.getCustomerQualified();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.events.CustomerQualified.serializeBinaryToWriter
    );
  }
  f = message.getCustomerScoreDisqualified();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      proto.events.CustomerScoreDisqualified.serializeBinaryToWriter
    );
  }
  f = message.getPaymentRefunded();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      proto.events.PaymentRefunded.serializeBinaryToWriter
    );
  }
  f = message.getCustomerDataEnriched();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.events.CustomerDataEnriched.serializeBinaryToWriter
    );
  }
  f = message.getReservationCreated();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      subscription_pb.ReservationCreated.serializeBinaryToWriter
    );
  }
  f = message.getReservationStatusUpdated();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      subscription_pb.ReservationStatusUpdated.serializeBinaryToWriter
    );
  }
  f = message.getProgramCreated();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      subscription_pb.ProgramCreated.serializeBinaryToWriter
    );
  }
  f = message.getColorOptionCreated();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      subscription_pb.ColorOptionCreated.serializeBinaryToWriter
    );
  }
  f = message.getPricingOptionCreated();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      subscription_pb.PricingOptionCreated.serializeBinaryToWriter
    );
  }
  f = message.getPendingSubscriptionInitialized();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      subscription_pb.PendingSubscriptionInitialized.serializeBinaryToWriter
    );
  }
  f = message.getReservationCancelled();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      subscription_pb.ReservationCancelled.serializeBinaryToWriter
    );
  }
  f = message.getSyncIngestion();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      proto.events.SyncIngestion.serializeBinaryToWriter
    );
  }
  f = message.getVehicleIngestion();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      vehicle_pb.Ingestion.serializeBinaryToWriter
    );
  }
  f = message.getVehicleUpdated();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      vehicle_pb.VehicleUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVehicleDeleted();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      vehicle_pb.VehicleDeleted.serializeBinaryToWriter
    );
  }
  f = message.getProcessDueStatements();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      subscription_pb.ProcessDueStatements.serializeBinaryToWriter
    );
  }
  f = message.getStatementDue();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      subscription_pb.StatementDue.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionActivated();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      subscription_pb.SubscriptionActivated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerPhoneUpdated();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      customer_pb.CustomerPhoneUpdated.serializeBinaryToWriter
    );
  }
  f = message.getPromotionCreated();
  if (f != null) {
    writer.writeMessage(
      72,
      f,
      billing_service_pb.Promotion.serializeBinaryToWriter
    );
  }
  f = message.getPromotionUpdated();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      billing_service_pb.Promotion.serializeBinaryToWriter
    );
  }
  f = message.getPromotionActivity();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      billing_service_pb.PromotionActivity.serializeBinaryToWriter
    );
  }
  f = message.getDraftOrderUpdated();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      billing_service_pb.DraftOrderUpdated.serializeBinaryToWriter
    );
  }
  f = message.getReservationOptionsUpdated();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      subscription_pb.ReservationOptionsUpdated.serializeBinaryToWriter
    );
  }
  f = message.getPromotionPartnerCreated();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      billing_service_pb.PromotionPartner.serializeBinaryToWriter
    );
  }
  f = message.getPromotionPartnerUpdated();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      billing_service_pb.PromotionPartner.serializeBinaryToWriter
    );
  }
  f = message.getLocationUpdated();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      vehicle_pb.LocationUpdated.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionUpdated();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      subscription_pb.SubscriptionUpdated.serializeBinaryToWriter
    );
  }
  f = message.getOdometerUpdate();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      vehicle_pb.OdometerUpdate.serializeBinaryToWriter
    );
  }
  f = message.getLocationUpdate();
  if (f != null) {
    writer.writeMessage(
      82,
      f,
      vehicle_pb.LocationUpdate.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentCreated();
  if (f != null) {
    writer.writeMessage(
      83,
      f,
      appointment_pb.AppointmentCreated.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentTypeUpdated();
  if (f != null) {
    writer.writeMessage(
      84,
      f,
      appointment_pb.AppointmentTypeUpdated.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentStatusUpdated();
  if (f != null) {
    writer.writeMessage(
      85,
      f,
      appointment_pb.AppointmentStatusUpdated.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentVehicleUpdated();
  if (f != null) {
    writer.writeMessage(
      86,
      f,
      appointment_pb.AppointmentVehicleUpdated.serializeBinaryToWriter
    );
  }
  f = message.getAvailabilityCreated();
  if (f != null) {
    writer.writeMessage(
      87,
      f,
      appointment_pb.AvailabilityCreated.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentCancelled();
  if (f != null) {
    writer.writeMessage(
      88,
      f,
      appointment_pb.AppointmentCancelled.serializeBinaryToWriter
    );
  }
  f = message.getProgramPricingOptionsUpdated();
  if (f != null) {
    writer.writeMessage(
      89,
      f,
      subscription_pb.ProgramPricingOptionsUpdated.serializeBinaryToWriter
    );
  }
  f = message.getAppointmentUpdated();
  if (f != null) {
    writer.writeMessage(
      90,
      f,
      appointment_pb.AppointmentUpdated.serializeBinaryToWriter
    );
  }
  f = message.getVehiclePreferencesNotAvailable();
  if (f != null) {
    writer.writeMessage(
      91,
      f,
      vehicle_pb.VehiclePreferencesNotAvailable.serializeBinaryToWriter
    );
  }
  f = message.getSendNotifications();
  if (f != null) {
    writer.writeMessage(
      92,
      f,
      notifications_pb.SendNotifications.serializeBinaryToWriter
    );
  }
  f = message.getNotificationsReceived();
  if (f != null) {
    writer.writeMessage(
      93,
      f,
      notifications_pb.NotificationReceived.serializeBinaryToWriter
    );
  }
  f = message.getNotificationStatusUpdated();
  if (f != null) {
    writer.writeMessage(
      94,
      f,
      notifications_pb.NotificationStatusUpdated.serializeBinaryToWriter
    );
  }
  f = message.getDealershipCreated();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      dealer_pb.DealershipCreated.serializeBinaryToWriter
    );
  }
  f = message.getDealershipUserCreated();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      dealer_pb.DealershipUserCreated.serializeBinaryToWriter
    );
  }
  f = message.getCustomerBankVerified();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      customer_pb.CustomerBankAccountVerified.serializeBinaryToWriter
    );
  }
  f = message.getIsQualifiedForLiberty();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      insurance_service_pb.IsQualifiedForLiberty.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePolicyCreated();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      insurance_service_pb.InsurancePolicyCreated.serializeBinaryToWriter
    );
  }
  f = message.getDealershipUserUpdated();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      dealer_pb.DealershipUserUpdated.serializeBinaryToWriter
    );
  }
  f = message.getDealershipUpdated();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      dealer_pb.DealershipUpdated.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumberEntered();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      customer_pb.PhoneNumberEntered.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInfoSubmitted();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      customer_pb.LicenseInfoSubmitted.serializeBinaryToWriter
    );
  }
  f = message.getVehicleInfleetingUpdated();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      vehicle_pb.VehicleInfleetingUpdated.serializeBinaryToWriter
    );
  }
  f = message.getExtendInsurancePolicies();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      proto.events.ExtendInsurancePolicies.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePolicyUpdated();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      insurance_service_pb.InsurancePolicyUpdated.serializeBinaryToWriter
    );
  }
  f = message.getMilesPurchased();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      proto.events.MilesPurchased.serializeBinaryToWriter
    );
  }
  f = message.getInsurancePolicyCancelled();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      insurance_service_pb.InsurancePolicyCancelled.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionVehicleChange();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      proto.events.SubscriptionVehicleChange.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionVehicleReturned();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      proto.events.SubscriptionVehicleReturned.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventCategory category = 1;
 * @return {!proto.events.EventCategory}
 */
proto.events.Event.prototype.getCategory = function() {
  return /** @type {!proto.events.EventCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.events.EventCategory} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Domain domain = 2;
 * @return {!proto.events.Domain}
 */
proto.events.Event.prototype.getDomain = function() {
  return /** @type {!proto.events.Domain} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.events.Domain} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string domain_id = 3;
 * @return {string}
 */
proto.events.Event.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.events.Event.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.events.Event.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string trace_id = 6;
 * @return {string}
 */
proto.events.Event.prototype.getTraceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setTraceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 version = 7;
 * @return {number}
 */
proto.events.Event.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 model_version = 8;
 * @return {number}
 */
proto.events.Event.prototype.getModelVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.setModelVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional CustomerCreated customer_created = 9;
 * @return {?proto.events.CustomerCreated}
 */
proto.events.Event.prototype.getCustomerCreated = function() {
  return /** @type{?proto.events.CustomerCreated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerCreated, 9));
};


/**
 * @param {?proto.events.CustomerCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerCreated = function() {
  return this.setCustomerCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerCreated = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional CustomerInfoUpdated customer_info_updated = 10;
 * @return {?proto.events.CustomerInfoUpdated}
 */
proto.events.Event.prototype.getCustomerInfoUpdated = function() {
  return /** @type{?proto.events.CustomerInfoUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerInfoUpdated, 10));
};


/**
 * @param {?proto.events.CustomerInfoUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerInfoUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerInfoUpdated = function() {
  return this.setCustomerInfoUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerInfoUpdated = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CustomerStateUpdated customer_state_updated = 11;
 * @return {?proto.events.CustomerStateUpdated}
 */
proto.events.Event.prototype.getCustomerStateUpdated = function() {
  return /** @type{?proto.events.CustomerStateUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerStateUpdated, 11));
};


/**
 * @param {?proto.events.CustomerStateUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerStateUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerStateUpdated = function() {
  return this.setCustomerStateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerStateUpdated = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional CustomerFailureStateUpdated customer_failure_state_updated = 12;
 * @return {?proto.events.CustomerFailureStateUpdated}
 */
proto.events.Event.prototype.getCustomerFailureStateUpdated = function() {
  return /** @type{?proto.events.CustomerFailureStateUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerFailureStateUpdated, 12));
};


/**
 * @param {?proto.events.CustomerFailureStateUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerFailureStateUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerFailureStateUpdated = function() {
  return this.setCustomerFailureStateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerFailureStateUpdated = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CustomerActivityUpdated customer_activity_updated = 13;
 * @return {?proto.events.CustomerActivityUpdated}
 */
proto.events.Event.prototype.getCustomerActivityUpdated = function() {
  return /** @type{?proto.events.CustomerActivityUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerActivityUpdated, 13));
};


/**
 * @param {?proto.events.CustomerActivityUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerActivityUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerActivityUpdated = function() {
  return this.setCustomerActivityUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerActivityUpdated = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ActivityStateUpdated activity_state_updated = 14;
 * @return {?proto.events.ActivityStateUpdated}
 */
proto.events.Event.prototype.getActivityStateUpdated = function() {
  return /** @type{?proto.events.ActivityStateUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.ActivityStateUpdated, 14));
};


/**
 * @param {?proto.events.ActivityStateUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setActivityStateUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearActivityStateUpdated = function() {
  return this.setActivityStateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasActivityStateUpdated = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional VendorAccountUpdated vendor_account_updated = 15;
 * @return {?proto.events.VendorAccountUpdated}
 */
proto.events.Event.prototype.getVendorAccountUpdated = function() {
  return /** @type{?proto.events.VendorAccountUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.VendorAccountUpdated, 15));
};


/**
 * @param {?proto.events.VendorAccountUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVendorAccountUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVendorAccountUpdated = function() {
  return this.setVendorAccountUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVendorAccountUpdated = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CustomerDeleted customer_deleted = 16;
 * @return {?proto.events.CustomerDeleted}
 */
proto.events.Event.prototype.getCustomerDeleted = function() {
  return /** @type{?proto.events.CustomerDeleted} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerDeleted, 16));
};


/**
 * @param {?proto.events.CustomerDeleted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerDeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerDeleted = function() {
  return this.setCustomerDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerDeleted = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PickupDateSet pickup_date_set = 17;
 * @return {?proto.events.PickupDateSet}
 */
proto.events.Event.prototype.getPickupDateSet = function() {
  return /** @type{?proto.events.PickupDateSet} */ (
    jspb.Message.getWrapperField(this, proto.events.PickupDateSet, 17));
};


/**
 * @param {?proto.events.PickupDateSet|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPickupDateSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPickupDateSet = function() {
  return this.setPickupDateSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPickupDateSet = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional VehiclePrePickup vehicle_prepickup = 18;
 * @return {?proto.events.VehiclePrePickup}
 */
proto.events.Event.prototype.getVehiclePrepickup = function() {
  return /** @type{?proto.events.VehiclePrePickup} */ (
    jspb.Message.getWrapperField(this, proto.events.VehiclePrePickup, 18));
};


/**
 * @param {?proto.events.VehiclePrePickup|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehiclePrepickup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehiclePrepickup = function() {
  return this.setVehiclePrepickup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehiclePrepickup = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional VehiclePickup vehicle_pickup = 19;
 * @return {?proto.events.VehiclePickup}
 */
proto.events.Event.prototype.getVehiclePickup = function() {
  return /** @type{?proto.events.VehiclePickup} */ (
    jspb.Message.getWrapperField(this, proto.events.VehiclePickup, 19));
};


/**
 * @param {?proto.events.VehiclePickup|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehiclePickup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehiclePickup = function() {
  return this.setVehiclePickup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehiclePickup = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional PrepickupAuth prepickup_auth = 20;
 * @return {?proto.events.PrepickupAuth}
 */
proto.events.Event.prototype.getPrepickupAuth = function() {
  return /** @type{?proto.events.PrepickupAuth} */ (
    jspb.Message.getWrapperField(this, proto.events.PrepickupAuth, 20));
};


/**
 * @param {?proto.events.PrepickupAuth|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPrepickupAuth = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPrepickupAuth = function() {
  return this.setPrepickupAuth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPrepickupAuth = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PickupPayment pickup_payment = 21;
 * @return {?proto.events.PickupPayment}
 */
proto.events.Event.prototype.getPickupPayment = function() {
  return /** @type{?proto.events.PickupPayment} */ (
    jspb.Message.getWrapperField(this, proto.events.PickupPayment, 21));
};


/**
 * @param {?proto.events.PickupPayment|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPickupPayment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPickupPayment = function() {
  return this.setPickupPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPickupPayment = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TransferVehicle transfer_vehicle = 22;
 * @return {?proto.events.TransferVehicle}
 */
proto.events.Event.prototype.getTransferVehicle = function() {
  return /** @type{?proto.events.TransferVehicle} */ (
    jspb.Message.getWrapperField(this, proto.events.TransferVehicle, 22));
};


/**
 * @param {?proto.events.TransferVehicle|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setTransferVehicle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearTransferVehicle = function() {
  return this.setTransferVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasTransferVehicle = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional VehicleCreated vehicle_created = 23;
 * @return {?proto.events.VehicleCreated}
 */
proto.events.Event.prototype.getVehicleCreated = function() {
  return /** @type{?proto.events.VehicleCreated} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleCreated, 23));
};


/**
 * @param {?proto.events.VehicleCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleCreated = function() {
  return this.setVehicleCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleCreated = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional TripReceived trip_received = 24;
 * @return {?proto.events.TripReceived}
 */
proto.events.Event.prototype.getTripReceived = function() {
  return /** @type{?proto.events.TripReceived} */ (
    jspb.Message.getWrapperField(this, proto.events.TripReceived, 24));
};


/**
 * @param {?proto.events.TripReceived|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setTripReceived = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearTripReceived = function() {
  return this.setTripReceived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasTripReceived = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional CollisionReceived collision_received = 25;
 * @return {?proto.events.CollisionReceived}
 */
proto.events.Event.prototype.getCollisionReceived = function() {
  return /** @type{?proto.events.CollisionReceived} */ (
    jspb.Message.getWrapperField(this, proto.events.CollisionReceived, 25));
};


/**
 * @param {?proto.events.CollisionReceived|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCollisionReceived = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCollisionReceived = function() {
  return this.setCollisionReceived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCollisionReceived = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional Notification notification = 26;
 * @return {?proto.events.Notification}
 */
proto.events.Event.prototype.getNotification = function() {
  return /** @type{?proto.events.Notification} */ (
    jspb.Message.getWrapperField(this, proto.events.Notification, 26));
};


/**
 * @param {?proto.events.Notification|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional InvoiceReady invoice_ready = 27;
 * @return {?proto.events.InvoiceReady}
 */
proto.events.Event.prototype.getInvoiceReady = function() {
  return /** @type{?proto.events.InvoiceReady} */ (
    jspb.Message.getWrapperField(this, proto.events.InvoiceReady, 27));
};


/**
 * @param {?proto.events.InvoiceReady|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInvoiceReady = function(value) {
  return jspb.Message.setOneofWrapperField(this, 27, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInvoiceReady = function() {
  return this.setInvoiceReady(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInvoiceReady = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional OdometerUpdated odometer_updated = 28;
 * @return {?proto.events.OdometerUpdated}
 */
proto.events.Event.prototype.getOdometerUpdated = function() {
  return /** @type{?proto.events.OdometerUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.OdometerUpdated, 28));
};


/**
 * @param {?proto.events.OdometerUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setOdometerUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearOdometerUpdated = function() {
  return this.setOdometerUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasOdometerUpdated = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional OdometerSet odometer_set = 29;
 * @return {?proto.events.OdometerSet}
 */
proto.events.Event.prototype.getOdometerSet = function() {
  return /** @type{?proto.events.OdometerSet} */ (
    jspb.Message.getWrapperField(this, proto.events.OdometerSet, 29));
};


/**
 * @param {?proto.events.OdometerSet|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setOdometerSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 29, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearOdometerSet = function() {
  return this.setOdometerSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasOdometerSet = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional StatementActivity statement_activity = 30;
 * @return {?proto.events.StatementActivity}
 */
proto.events.Event.prototype.getStatementActivity = function() {
  return /** @type{?proto.events.StatementActivity} */ (
    jspb.Message.getWrapperField(this, proto.events.StatementActivity, 30));
};


/**
 * @param {?proto.events.StatementActivity|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setStatementActivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearStatementActivity = function() {
  return this.setStatementActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasStatementActivity = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional PaymentActivity payment_activity = 31;
 * @return {?proto.events.PaymentActivity}
 */
proto.events.Event.prototype.getPaymentActivity = function() {
  return /** @type{?proto.events.PaymentActivity} */ (
    jspb.Message.getWrapperField(this, proto.events.PaymentActivity, 31));
};


/**
 * @param {?proto.events.PaymentActivity|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPaymentActivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 31, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPaymentActivity = function() {
  return this.setPaymentActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPaymentActivity = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional VehicleStateUpdated vehicle_state_updated = 32;
 * @return {?proto.events.VehicleStateUpdated}
 */
proto.events.Event.prototype.getVehicleStateUpdated = function() {
  return /** @type{?proto.events.VehicleStateUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleStateUpdated, 32));
};


/**
 * @param {?proto.events.VehicleStateUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleStateUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 32, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleStateUpdated = function() {
  return this.setVehicleStateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleStateUpdated = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional VehicleActivityUpdated vehicle_activity_updated = 33;
 * @return {?proto.events.VehicleActivityUpdated}
 */
proto.events.Event.prototype.getVehicleActivityUpdated = function() {
  return /** @type{?proto.events.VehicleActivityUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleActivityUpdated, 33));
};


/**
 * @param {?proto.events.VehicleActivityUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleActivityUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleActivityUpdated = function() {
  return this.setVehicleActivityUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleActivityUpdated = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional VehicleActivityStateUpdated vehicle_activity_state_updated = 34;
 * @return {?proto.events.VehicleActivityStateUpdated}
 */
proto.events.Event.prototype.getVehicleActivityStateUpdated = function() {
  return /** @type{?proto.events.VehicleActivityStateUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleActivityStateUpdated, 34));
};


/**
 * @param {?proto.events.VehicleActivityStateUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleActivityStateUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 34, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleActivityStateUpdated = function() {
  return this.setVehicleActivityStateUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleActivityStateUpdated = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional VehicleActivityFailureUpdated vehicle_activity_failure_updated = 35;
 * @return {?proto.events.VehicleActivityFailureUpdated}
 */
proto.events.Event.prototype.getVehicleActivityFailureUpdated = function() {
  return /** @type{?proto.events.VehicleActivityFailureUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleActivityFailureUpdated, 35));
};


/**
 * @param {?proto.events.VehicleActivityFailureUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleActivityFailureUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 35, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleActivityFailureUpdated = function() {
  return this.setVehicleActivityFailureUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleActivityFailureUpdated = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional CustomerInsuranceUpdated customer_insurance_updated = 36;
 * @return {?proto.events.CustomerInsuranceUpdated}
 */
proto.events.Event.prototype.getCustomerInsuranceUpdated = function() {
  return /** @type{?proto.events.CustomerInsuranceUpdated} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerInsuranceUpdated, 36));
};


/**
 * @param {?proto.events.CustomerInsuranceUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerInsuranceUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 36, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerInsuranceUpdated = function() {
  return this.setCustomerInsuranceUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerInsuranceUpdated = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional SyncDealerships sync_dealerships = 37;
 * @return {?proto.events.SyncDealerships}
 */
proto.events.Event.prototype.getSyncDealerships = function() {
  return /** @type{?proto.events.SyncDealerships} */ (
    jspb.Message.getWrapperField(this, proto.events.SyncDealerships, 37));
};


/**
 * @param {?proto.events.SyncDealerships|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSyncDealerships = function(value) {
  return jspb.Message.setOneofWrapperField(this, 37, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSyncDealerships = function() {
  return this.setSyncDealerships(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSyncDealerships = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional InvoiceToBePaid invoice_to_be_paid = 38;
 * @return {?proto.events.InvoiceToBePaid}
 */
proto.events.Event.prototype.getInvoiceToBePaid = function() {
  return /** @type{?proto.events.InvoiceToBePaid} */ (
    jspb.Message.getWrapperField(this, proto.events.InvoiceToBePaid, 38));
};


/**
 * @param {?proto.events.InvoiceToBePaid|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInvoiceToBePaid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 38, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInvoiceToBePaid = function() {
  return this.setInvoiceToBePaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInvoiceToBePaid = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional DocumentAdded document_added = 39;
 * @return {?proto.events.DocumentAdded}
 */
proto.events.Event.prototype.getDocumentAdded = function() {
  return /** @type{?proto.events.DocumentAdded} */ (
    jspb.Message.getWrapperField(this, proto.events.DocumentAdded, 39));
};


/**
 * @param {?proto.events.DocumentAdded|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDocumentAdded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 39, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDocumentAdded = function() {
  return this.setDocumentAdded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDocumentAdded = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional DocumentDeleted document_deleted = 40;
 * @return {?proto.events.DocumentDeleted}
 */
proto.events.Event.prototype.getDocumentDeleted = function() {
  return /** @type{?proto.events.DocumentDeleted} */ (
    jspb.Message.getWrapperField(this, proto.events.DocumentDeleted, 40));
};


/**
 * @param {?proto.events.DocumentDeleted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDocumentDeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 40, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDocumentDeleted = function() {
  return this.setDocumentDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDocumentDeleted = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional OfferSubmitted offer_submitted = 41;
 * @return {?proto.events.OfferSubmitted}
 */
proto.events.Event.prototype.getOfferSubmitted = function() {
  return /** @type{?proto.events.OfferSubmitted} */ (
    jspb.Message.getWrapperField(this, proto.events.OfferSubmitted, 41));
};


/**
 * @param {?proto.events.OfferSubmitted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setOfferSubmitted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 41, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearOfferSubmitted = function() {
  return this.setOfferSubmitted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasOfferSubmitted = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional SubscriptionContractAdded subscription_contract_added = 42;
 * @return {?proto.events.SubscriptionContractAdded}
 */
proto.events.Event.prototype.getSubscriptionContractAdded = function() {
  return /** @type{?proto.events.SubscriptionContractAdded} */ (
    jspb.Message.getWrapperField(this, proto.events.SubscriptionContractAdded, 42));
};


/**
 * @param {?proto.events.SubscriptionContractAdded|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionContractAdded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 42, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionContractAdded = function() {
  return this.setSubscriptionContractAdded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionContractAdded = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional VehicleInspectionCompleted vehicle_inspection_completed = 43;
 * @return {?proto.events.VehicleInspectionCompleted}
 */
proto.events.Event.prototype.getVehicleInspectionCompleted = function() {
  return /** @type{?proto.events.VehicleInspectionCompleted} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleInspectionCompleted, 43));
};


/**
 * @param {?proto.events.VehicleInspectionCompleted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleInspectionCompleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 43, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleInspectionCompleted = function() {
  return this.setVehicleInspectionCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleInspectionCompleted = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional VehicleInspectionStarted vehicle_inspection_started = 44;
 * @return {?proto.events.VehicleInspectionStarted}
 */
proto.events.Event.prototype.getVehicleInspectionStarted = function() {
  return /** @type{?proto.events.VehicleInspectionStarted} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleInspectionStarted, 44));
};


/**
 * @param {?proto.events.VehicleInspectionStarted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleInspectionStarted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 44, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleInspectionStarted = function() {
  return this.setVehicleInspectionStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleInspectionStarted = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional OfferResponse offer_response = 45;
 * @return {?proto.events.OfferResponse}
 */
proto.events.Event.prototype.getOfferResponse = function() {
  return /** @type{?proto.events.OfferResponse} */ (
    jspb.Message.getWrapperField(this, proto.events.OfferResponse, 45));
};


/**
 * @param {?proto.events.OfferResponse|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setOfferResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 45, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearOfferResponse = function() {
  return this.setOfferResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasOfferResponse = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional VehicleTosAccepted vehicle_tos_accepted = 46;
 * @return {?proto.events.VehicleTosAccepted}
 */
proto.events.Event.prototype.getVehicleTosAccepted = function() {
  return /** @type{?proto.events.VehicleTosAccepted} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleTosAccepted, 46));
};


/**
 * @param {?proto.events.VehicleTosAccepted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleTosAccepted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 46, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleTosAccepted = function() {
  return this.setVehicleTosAccepted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleTosAccepted = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional SubscriptionCancelled subscription_cancelled = 47;
 * @return {?proto.events.SubscriptionCancelled}
 */
proto.events.Event.prototype.getSubscriptionCancelled = function() {
  return /** @type{?proto.events.SubscriptionCancelled} */ (
    jspb.Message.getWrapperField(this, proto.events.SubscriptionCancelled, 47));
};


/**
 * @param {?proto.events.SubscriptionCancelled|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionCancelled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 47, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionCancelled = function() {
  return this.setSubscriptionCancelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionCancelled = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional InvoiceCancelled invoice_cancelled = 48;
 * @return {?proto.events.InvoiceCancelled}
 */
proto.events.Event.prototype.getInvoiceCancelled = function() {
  return /** @type{?proto.events.InvoiceCancelled} */ (
    jspb.Message.getWrapperField(this, proto.events.InvoiceCancelled, 48));
};


/**
 * @param {?proto.events.InvoiceCancelled|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInvoiceCancelled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 48, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInvoiceCancelled = function() {
  return this.setInvoiceCancelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInvoiceCancelled = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional ReloadDomain reload_domain = 49;
 * @return {?proto.events.ReloadDomain}
 */
proto.events.Event.prototype.getReloadDomain = function() {
  return /** @type{?proto.events.ReloadDomain} */ (
    jspb.Message.getWrapperField(this, proto.events.ReloadDomain, 49));
};


/**
 * @param {?proto.events.ReloadDomain|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setReloadDomain = function(value) {
  return jspb.Message.setOneofWrapperField(this, 49, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearReloadDomain = function() {
  return this.setReloadDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasReloadDomain = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional CancelPaymentTransaction cancel_payment_transaction = 50;
 * @return {?proto.events.CancelPaymentTransaction}
 */
proto.events.Event.prototype.getCancelPaymentTransaction = function() {
  return /** @type{?proto.events.CancelPaymentTransaction} */ (
    jspb.Message.getWrapperField(this, proto.events.CancelPaymentTransaction, 50));
};


/**
 * @param {?proto.events.CancelPaymentTransaction|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCancelPaymentTransaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCancelPaymentTransaction = function() {
  return this.setCancelPaymentTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCancelPaymentTransaction = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional SmsReceived sms_received = 51;
 * @return {?proto.events.SmsReceived}
 */
proto.events.Event.prototype.getSmsReceived = function() {
  return /** @type{?proto.events.SmsReceived} */ (
    jspb.Message.getWrapperField(this, proto.events.SmsReceived, 51));
};


/**
 * @param {?proto.events.SmsReceived|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSmsReceived = function(value) {
  return jspb.Message.setOneofWrapperField(this, 51, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSmsReceived = function() {
  return this.setSmsReceived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSmsReceived = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional CustomerScored customer_scored = 52;
 * @return {?proto.events.CustomerScored}
 */
proto.events.Event.prototype.getCustomerScored = function() {
  return /** @type{?proto.events.CustomerScored} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerScored, 52));
};


/**
 * @param {?proto.events.CustomerScored|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerScored = function(value) {
  return jspb.Message.setOneofWrapperField(this, 52, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerScored = function() {
  return this.setCustomerScored(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerScored = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional CustomerQualified customer_qualified = 53;
 * @return {?proto.events.CustomerQualified}
 */
proto.events.Event.prototype.getCustomerQualified = function() {
  return /** @type{?proto.events.CustomerQualified} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerQualified, 53));
};


/**
 * @param {?proto.events.CustomerQualified|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerQualified = function(value) {
  return jspb.Message.setOneofWrapperField(this, 53, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerQualified = function() {
  return this.setCustomerQualified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerQualified = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional CustomerScoreDisqualified customer_score_disqualified = 54;
 * @return {?proto.events.CustomerScoreDisqualified}
 */
proto.events.Event.prototype.getCustomerScoreDisqualified = function() {
  return /** @type{?proto.events.CustomerScoreDisqualified} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerScoreDisqualified, 54));
};


/**
 * @param {?proto.events.CustomerScoreDisqualified|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerScoreDisqualified = function(value) {
  return jspb.Message.setOneofWrapperField(this, 54, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerScoreDisqualified = function() {
  return this.setCustomerScoreDisqualified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerScoreDisqualified = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional PaymentRefunded payment_refunded = 55;
 * @return {?proto.events.PaymentRefunded}
 */
proto.events.Event.prototype.getPaymentRefunded = function() {
  return /** @type{?proto.events.PaymentRefunded} */ (
    jspb.Message.getWrapperField(this, proto.events.PaymentRefunded, 55));
};


/**
 * @param {?proto.events.PaymentRefunded|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPaymentRefunded = function(value) {
  return jspb.Message.setOneofWrapperField(this, 55, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPaymentRefunded = function() {
  return this.setPaymentRefunded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPaymentRefunded = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional CustomerDataEnriched customer_data_enriched = 56;
 * @return {?proto.events.CustomerDataEnriched}
 */
proto.events.Event.prototype.getCustomerDataEnriched = function() {
  return /** @type{?proto.events.CustomerDataEnriched} */ (
    jspb.Message.getWrapperField(this, proto.events.CustomerDataEnriched, 56));
};


/**
 * @param {?proto.events.CustomerDataEnriched|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerDataEnriched = function(value) {
  return jspb.Message.setOneofWrapperField(this, 56, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerDataEnriched = function() {
  return this.setCustomerDataEnriched(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerDataEnriched = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional subscription.ReservationCreated reservation_created = 57;
 * @return {?proto.subscription.ReservationCreated}
 */
proto.events.Event.prototype.getReservationCreated = function() {
  return /** @type{?proto.subscription.ReservationCreated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ReservationCreated, 57));
};


/**
 * @param {?proto.subscription.ReservationCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setReservationCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 57, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearReservationCreated = function() {
  return this.setReservationCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasReservationCreated = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional subscription.ReservationStatusUpdated reservation_status_updated = 58;
 * @return {?proto.subscription.ReservationStatusUpdated}
 */
proto.events.Event.prototype.getReservationStatusUpdated = function() {
  return /** @type{?proto.subscription.ReservationStatusUpdated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ReservationStatusUpdated, 58));
};


/**
 * @param {?proto.subscription.ReservationStatusUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setReservationStatusUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 58, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearReservationStatusUpdated = function() {
  return this.setReservationStatusUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasReservationStatusUpdated = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional subscription.ProgramCreated program_created = 59;
 * @return {?proto.subscription.ProgramCreated}
 */
proto.events.Event.prototype.getProgramCreated = function() {
  return /** @type{?proto.subscription.ProgramCreated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ProgramCreated, 59));
};


/**
 * @param {?proto.subscription.ProgramCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setProgramCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 59, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearProgramCreated = function() {
  return this.setProgramCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasProgramCreated = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional subscription.ColorOptionCreated color_option_created = 60;
 * @return {?proto.subscription.ColorOptionCreated}
 */
proto.events.Event.prototype.getColorOptionCreated = function() {
  return /** @type{?proto.subscription.ColorOptionCreated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ColorOptionCreated, 60));
};


/**
 * @param {?proto.subscription.ColorOptionCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setColorOptionCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 60, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearColorOptionCreated = function() {
  return this.setColorOptionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasColorOptionCreated = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional subscription.PricingOptionCreated pricing_option_created = 61;
 * @return {?proto.subscription.PricingOptionCreated}
 */
proto.events.Event.prototype.getPricingOptionCreated = function() {
  return /** @type{?proto.subscription.PricingOptionCreated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.PricingOptionCreated, 61));
};


/**
 * @param {?proto.subscription.PricingOptionCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPricingOptionCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 61, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPricingOptionCreated = function() {
  return this.setPricingOptionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPricingOptionCreated = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional subscription.PendingSubscriptionInitialized pending_subscription_initialized = 62;
 * @return {?proto.subscription.PendingSubscriptionInitialized}
 */
proto.events.Event.prototype.getPendingSubscriptionInitialized = function() {
  return /** @type{?proto.subscription.PendingSubscriptionInitialized} */ (
    jspb.Message.getWrapperField(this, subscription_pb.PendingSubscriptionInitialized, 62));
};


/**
 * @param {?proto.subscription.PendingSubscriptionInitialized|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPendingSubscriptionInitialized = function(value) {
  return jspb.Message.setOneofWrapperField(this, 62, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPendingSubscriptionInitialized = function() {
  return this.setPendingSubscriptionInitialized(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPendingSubscriptionInitialized = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional subscription.ReservationCancelled reservation_cancelled = 63;
 * @return {?proto.subscription.ReservationCancelled}
 */
proto.events.Event.prototype.getReservationCancelled = function() {
  return /** @type{?proto.subscription.ReservationCancelled} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ReservationCancelled, 63));
};


/**
 * @param {?proto.subscription.ReservationCancelled|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setReservationCancelled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 63, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearReservationCancelled = function() {
  return this.setReservationCancelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasReservationCancelled = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional SyncIngestion sync_ingestion = 64;
 * @return {?proto.events.SyncIngestion}
 */
proto.events.Event.prototype.getSyncIngestion = function() {
  return /** @type{?proto.events.SyncIngestion} */ (
    jspb.Message.getWrapperField(this, proto.events.SyncIngestion, 64));
};


/**
 * @param {?proto.events.SyncIngestion|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSyncIngestion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 64, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSyncIngestion = function() {
  return this.setSyncIngestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSyncIngestion = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional vehicle.Ingestion vehicle_ingestion = 65;
 * @return {?proto.vehicle.Ingestion}
 */
proto.events.Event.prototype.getVehicleIngestion = function() {
  return /** @type{?proto.vehicle.Ingestion} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Ingestion, 65));
};


/**
 * @param {?proto.vehicle.Ingestion|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleIngestion = function(value) {
  return jspb.Message.setOneofWrapperField(this, 65, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleIngestion = function() {
  return this.setVehicleIngestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleIngestion = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional vehicle.VehicleUpdated vehicle_updated = 66;
 * @return {?proto.vehicle.VehicleUpdated}
 */
proto.events.Event.prototype.getVehicleUpdated = function() {
  return /** @type{?proto.vehicle.VehicleUpdated} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.VehicleUpdated, 66));
};


/**
 * @param {?proto.vehicle.VehicleUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 66, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleUpdated = function() {
  return this.setVehicleUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleUpdated = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional vehicle.VehicleDeleted vehicle_deleted = 67;
 * @return {?proto.vehicle.VehicleDeleted}
 */
proto.events.Event.prototype.getVehicleDeleted = function() {
  return /** @type{?proto.vehicle.VehicleDeleted} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.VehicleDeleted, 67));
};


/**
 * @param {?proto.vehicle.VehicleDeleted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleDeleted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 67, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleDeleted = function() {
  return this.setVehicleDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleDeleted = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional subscription.ProcessDueStatements process_due_statements = 68;
 * @return {?proto.subscription.ProcessDueStatements}
 */
proto.events.Event.prototype.getProcessDueStatements = function() {
  return /** @type{?proto.subscription.ProcessDueStatements} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ProcessDueStatements, 68));
};


/**
 * @param {?proto.subscription.ProcessDueStatements|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setProcessDueStatements = function(value) {
  return jspb.Message.setOneofWrapperField(this, 68, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearProcessDueStatements = function() {
  return this.setProcessDueStatements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasProcessDueStatements = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional subscription.StatementDue statement_due = 69;
 * @return {?proto.subscription.StatementDue}
 */
proto.events.Event.prototype.getStatementDue = function() {
  return /** @type{?proto.subscription.StatementDue} */ (
    jspb.Message.getWrapperField(this, subscription_pb.StatementDue, 69));
};


/**
 * @param {?proto.subscription.StatementDue|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setStatementDue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 69, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearStatementDue = function() {
  return this.setStatementDue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasStatementDue = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional subscription.SubscriptionActivated subscription_activated = 70;
 * @return {?proto.subscription.SubscriptionActivated}
 */
proto.events.Event.prototype.getSubscriptionActivated = function() {
  return /** @type{?proto.subscription.SubscriptionActivated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.SubscriptionActivated, 70));
};


/**
 * @param {?proto.subscription.SubscriptionActivated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionActivated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 70, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionActivated = function() {
  return this.setSubscriptionActivated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionActivated = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional customer.CustomerPhoneUpdated customer_phone_updated = 71;
 * @return {?proto.customer.CustomerPhoneUpdated}
 */
proto.events.Event.prototype.getCustomerPhoneUpdated = function() {
  return /** @type{?proto.customer.CustomerPhoneUpdated} */ (
    jspb.Message.getWrapperField(this, customer_pb.CustomerPhoneUpdated, 71));
};


/**
 * @param {?proto.customer.CustomerPhoneUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerPhoneUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 71, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerPhoneUpdated = function() {
  return this.setCustomerPhoneUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerPhoneUpdated = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional billing_service.Promotion promotion_created = 72;
 * @return {?proto.billing_service.Promotion}
 */
proto.events.Event.prototype.getPromotionCreated = function() {
  return /** @type{?proto.billing_service.Promotion} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.Promotion, 72));
};


/**
 * @param {?proto.billing_service.Promotion|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPromotionCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 72, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPromotionCreated = function() {
  return this.setPromotionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPromotionCreated = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional billing_service.Promotion promotion_updated = 73;
 * @return {?proto.billing_service.Promotion}
 */
proto.events.Event.prototype.getPromotionUpdated = function() {
  return /** @type{?proto.billing_service.Promotion} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.Promotion, 73));
};


/**
 * @param {?proto.billing_service.Promotion|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPromotionUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 73, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPromotionUpdated = function() {
  return this.setPromotionUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPromotionUpdated = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional billing_service.PromotionActivity promotion_activity = 74;
 * @return {?proto.billing_service.PromotionActivity}
 */
proto.events.Event.prototype.getPromotionActivity = function() {
  return /** @type{?proto.billing_service.PromotionActivity} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.PromotionActivity, 74));
};


/**
 * @param {?proto.billing_service.PromotionActivity|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPromotionActivity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 74, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPromotionActivity = function() {
  return this.setPromotionActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPromotionActivity = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional billing_service.DraftOrderUpdated draft_order_updated = 75;
 * @return {?proto.billing_service.DraftOrderUpdated}
 */
proto.events.Event.prototype.getDraftOrderUpdated = function() {
  return /** @type{?proto.billing_service.DraftOrderUpdated} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.DraftOrderUpdated, 75));
};


/**
 * @param {?proto.billing_service.DraftOrderUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDraftOrderUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 75, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDraftOrderUpdated = function() {
  return this.setDraftOrderUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDraftOrderUpdated = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional subscription.ReservationOptionsUpdated reservation_options_updated = 76;
 * @return {?proto.subscription.ReservationOptionsUpdated}
 */
proto.events.Event.prototype.getReservationOptionsUpdated = function() {
  return /** @type{?proto.subscription.ReservationOptionsUpdated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ReservationOptionsUpdated, 76));
};


/**
 * @param {?proto.subscription.ReservationOptionsUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setReservationOptionsUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 76, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearReservationOptionsUpdated = function() {
  return this.setReservationOptionsUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasReservationOptionsUpdated = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional billing_service.PromotionPartner promotion_partner_created = 77;
 * @return {?proto.billing_service.PromotionPartner}
 */
proto.events.Event.prototype.getPromotionPartnerCreated = function() {
  return /** @type{?proto.billing_service.PromotionPartner} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.PromotionPartner, 77));
};


/**
 * @param {?proto.billing_service.PromotionPartner|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPromotionPartnerCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 77, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPromotionPartnerCreated = function() {
  return this.setPromotionPartnerCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPromotionPartnerCreated = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional billing_service.PromotionPartner promotion_partner_updated = 78;
 * @return {?proto.billing_service.PromotionPartner}
 */
proto.events.Event.prototype.getPromotionPartnerUpdated = function() {
  return /** @type{?proto.billing_service.PromotionPartner} */ (
    jspb.Message.getWrapperField(this, billing_service_pb.PromotionPartner, 78));
};


/**
 * @param {?proto.billing_service.PromotionPartner|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPromotionPartnerUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 78, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPromotionPartnerUpdated = function() {
  return this.setPromotionPartnerUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPromotionPartnerUpdated = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional vehicle.LocationUpdated location_updated = 79;
 * @return {?proto.vehicle.LocationUpdated}
 */
proto.events.Event.prototype.getLocationUpdated = function() {
  return /** @type{?proto.vehicle.LocationUpdated} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.LocationUpdated, 79));
};


/**
 * @param {?proto.vehicle.LocationUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setLocationUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 79, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearLocationUpdated = function() {
  return this.setLocationUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasLocationUpdated = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional subscription.SubscriptionUpdated subscription_updated = 80;
 * @return {?proto.subscription.SubscriptionUpdated}
 */
proto.events.Event.prototype.getSubscriptionUpdated = function() {
  return /** @type{?proto.subscription.SubscriptionUpdated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.SubscriptionUpdated, 80));
};


/**
 * @param {?proto.subscription.SubscriptionUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 80, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionUpdated = function() {
  return this.setSubscriptionUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionUpdated = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional vehicle.OdometerUpdate odometer_update = 81;
 * @return {?proto.vehicle.OdometerUpdate}
 */
proto.events.Event.prototype.getOdometerUpdate = function() {
  return /** @type{?proto.vehicle.OdometerUpdate} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.OdometerUpdate, 81));
};


/**
 * @param {?proto.vehicle.OdometerUpdate|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setOdometerUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 81, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearOdometerUpdate = function() {
  return this.setOdometerUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasOdometerUpdate = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional vehicle.LocationUpdate location_update = 82;
 * @return {?proto.vehicle.LocationUpdate}
 */
proto.events.Event.prototype.getLocationUpdate = function() {
  return /** @type{?proto.vehicle.LocationUpdate} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.LocationUpdate, 82));
};


/**
 * @param {?proto.vehicle.LocationUpdate|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setLocationUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 82, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearLocationUpdate = function() {
  return this.setLocationUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasLocationUpdate = function() {
  return jspb.Message.getField(this, 82) != null;
};


/**
 * optional appointment.AppointmentCreated appointment_created = 83;
 * @return {?proto.appointment.AppointmentCreated}
 */
proto.events.Event.prototype.getAppointmentCreated = function() {
  return /** @type{?proto.appointment.AppointmentCreated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentCreated, 83));
};


/**
 * @param {?proto.appointment.AppointmentCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 83, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentCreated = function() {
  return this.setAppointmentCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentCreated = function() {
  return jspb.Message.getField(this, 83) != null;
};


/**
 * optional appointment.AppointmentTypeUpdated appointment_type_updated = 84;
 * @return {?proto.appointment.AppointmentTypeUpdated}
 */
proto.events.Event.prototype.getAppointmentTypeUpdated = function() {
  return /** @type{?proto.appointment.AppointmentTypeUpdated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentTypeUpdated, 84));
};


/**
 * @param {?proto.appointment.AppointmentTypeUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentTypeUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 84, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentTypeUpdated = function() {
  return this.setAppointmentTypeUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentTypeUpdated = function() {
  return jspb.Message.getField(this, 84) != null;
};


/**
 * optional appointment.AppointmentStatusUpdated appointment_status_updated = 85;
 * @return {?proto.appointment.AppointmentStatusUpdated}
 */
proto.events.Event.prototype.getAppointmentStatusUpdated = function() {
  return /** @type{?proto.appointment.AppointmentStatusUpdated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentStatusUpdated, 85));
};


/**
 * @param {?proto.appointment.AppointmentStatusUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentStatusUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 85, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentStatusUpdated = function() {
  return this.setAppointmentStatusUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentStatusUpdated = function() {
  return jspb.Message.getField(this, 85) != null;
};


/**
 * optional appointment.AppointmentVehicleUpdated appointment_vehicle_updated = 86;
 * @return {?proto.appointment.AppointmentVehicleUpdated}
 */
proto.events.Event.prototype.getAppointmentVehicleUpdated = function() {
  return /** @type{?proto.appointment.AppointmentVehicleUpdated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentVehicleUpdated, 86));
};


/**
 * @param {?proto.appointment.AppointmentVehicleUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentVehicleUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 86, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentVehicleUpdated = function() {
  return this.setAppointmentVehicleUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentVehicleUpdated = function() {
  return jspb.Message.getField(this, 86) != null;
};


/**
 * optional appointment.AvailabilityCreated availability_created = 87;
 * @return {?proto.appointment.AvailabilityCreated}
 */
proto.events.Event.prototype.getAvailabilityCreated = function() {
  return /** @type{?proto.appointment.AvailabilityCreated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AvailabilityCreated, 87));
};


/**
 * @param {?proto.appointment.AvailabilityCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAvailabilityCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 87, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAvailabilityCreated = function() {
  return this.setAvailabilityCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAvailabilityCreated = function() {
  return jspb.Message.getField(this, 87) != null;
};


/**
 * optional appointment.AppointmentCancelled appointment_cancelled = 88;
 * @return {?proto.appointment.AppointmentCancelled}
 */
proto.events.Event.prototype.getAppointmentCancelled = function() {
  return /** @type{?proto.appointment.AppointmentCancelled} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentCancelled, 88));
};


/**
 * @param {?proto.appointment.AppointmentCancelled|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentCancelled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 88, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentCancelled = function() {
  return this.setAppointmentCancelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentCancelled = function() {
  return jspb.Message.getField(this, 88) != null;
};


/**
 * optional subscription.ProgramPricingOptionsUpdated program_pricing_options_updated = 89;
 * @return {?proto.subscription.ProgramPricingOptionsUpdated}
 */
proto.events.Event.prototype.getProgramPricingOptionsUpdated = function() {
  return /** @type{?proto.subscription.ProgramPricingOptionsUpdated} */ (
    jspb.Message.getWrapperField(this, subscription_pb.ProgramPricingOptionsUpdated, 89));
};


/**
 * @param {?proto.subscription.ProgramPricingOptionsUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setProgramPricingOptionsUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 89, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearProgramPricingOptionsUpdated = function() {
  return this.setProgramPricingOptionsUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasProgramPricingOptionsUpdated = function() {
  return jspb.Message.getField(this, 89) != null;
};


/**
 * optional appointment.AppointmentUpdated appointment_updated = 90;
 * @return {?proto.appointment.AppointmentUpdated}
 */
proto.events.Event.prototype.getAppointmentUpdated = function() {
  return /** @type{?proto.appointment.AppointmentUpdated} */ (
    jspb.Message.getWrapperField(this, appointment_pb.AppointmentUpdated, 90));
};


/**
 * @param {?proto.appointment.AppointmentUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setAppointmentUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 90, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearAppointmentUpdated = function() {
  return this.setAppointmentUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasAppointmentUpdated = function() {
  return jspb.Message.getField(this, 90) != null;
};


/**
 * optional vehicle.VehiclePreferencesNotAvailable vehicle_preferences_not_available = 91;
 * @return {?proto.vehicle.VehiclePreferencesNotAvailable}
 */
proto.events.Event.prototype.getVehiclePreferencesNotAvailable = function() {
  return /** @type{?proto.vehicle.VehiclePreferencesNotAvailable} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.VehiclePreferencesNotAvailable, 91));
};


/**
 * @param {?proto.vehicle.VehiclePreferencesNotAvailable|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehiclePreferencesNotAvailable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 91, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehiclePreferencesNotAvailable = function() {
  return this.setVehiclePreferencesNotAvailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehiclePreferencesNotAvailable = function() {
  return jspb.Message.getField(this, 91) != null;
};


/**
 * optional notifications.SendNotifications send_notifications = 92;
 * @return {?proto.notifications.SendNotifications}
 */
proto.events.Event.prototype.getSendNotifications = function() {
  return /** @type{?proto.notifications.SendNotifications} */ (
    jspb.Message.getWrapperField(this, notifications_pb.SendNotifications, 92));
};


/**
 * @param {?proto.notifications.SendNotifications|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSendNotifications = function(value) {
  return jspb.Message.setOneofWrapperField(this, 92, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSendNotifications = function() {
  return this.setSendNotifications(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSendNotifications = function() {
  return jspb.Message.getField(this, 92) != null;
};


/**
 * optional notifications.NotificationReceived notifications_received = 93;
 * @return {?proto.notifications.NotificationReceived}
 */
proto.events.Event.prototype.getNotificationsReceived = function() {
  return /** @type{?proto.notifications.NotificationReceived} */ (
    jspb.Message.getWrapperField(this, notifications_pb.NotificationReceived, 93));
};


/**
 * @param {?proto.notifications.NotificationReceived|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setNotificationsReceived = function(value) {
  return jspb.Message.setOneofWrapperField(this, 93, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearNotificationsReceived = function() {
  return this.setNotificationsReceived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasNotificationsReceived = function() {
  return jspb.Message.getField(this, 93) != null;
};


/**
 * optional notifications.NotificationStatusUpdated notification_status_updated = 94;
 * @return {?proto.notifications.NotificationStatusUpdated}
 */
proto.events.Event.prototype.getNotificationStatusUpdated = function() {
  return /** @type{?proto.notifications.NotificationStatusUpdated} */ (
    jspb.Message.getWrapperField(this, notifications_pb.NotificationStatusUpdated, 94));
};


/**
 * @param {?proto.notifications.NotificationStatusUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setNotificationStatusUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 94, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearNotificationStatusUpdated = function() {
  return this.setNotificationStatusUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasNotificationStatusUpdated = function() {
  return jspb.Message.getField(this, 94) != null;
};


/**
 * optional dealer.DealershipCreated dealership_created = 100;
 * @return {?proto.dealer.DealershipCreated}
 */
proto.events.Event.prototype.getDealershipCreated = function() {
  return /** @type{?proto.dealer.DealershipCreated} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipCreated, 100));
};


/**
 * @param {?proto.dealer.DealershipCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDealershipCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 100, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDealershipCreated = function() {
  return this.setDealershipCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDealershipCreated = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional dealer.DealershipUserCreated dealership_user_created = 101;
 * @return {?proto.dealer.DealershipUserCreated}
 */
proto.events.Event.prototype.getDealershipUserCreated = function() {
  return /** @type{?proto.dealer.DealershipUserCreated} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUserCreated, 101));
};


/**
 * @param {?proto.dealer.DealershipUserCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDealershipUserCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 101, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDealershipUserCreated = function() {
  return this.setDealershipUserCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDealershipUserCreated = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional customer.CustomerBankAccountVerified customer_bank_verified = 102;
 * @return {?proto.customer.CustomerBankAccountVerified}
 */
proto.events.Event.prototype.getCustomerBankVerified = function() {
  return /** @type{?proto.customer.CustomerBankAccountVerified} */ (
    jspb.Message.getWrapperField(this, customer_pb.CustomerBankAccountVerified, 102));
};


/**
 * @param {?proto.customer.CustomerBankAccountVerified|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setCustomerBankVerified = function(value) {
  return jspb.Message.setOneofWrapperField(this, 102, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearCustomerBankVerified = function() {
  return this.setCustomerBankVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasCustomerBankVerified = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional insurance_service.IsQualifiedForLiberty is_qualified_for_liberty = 103;
 * @return {?proto.insurance_service.IsQualifiedForLiberty}
 */
proto.events.Event.prototype.getIsQualifiedForLiberty = function() {
  return /** @type{?proto.insurance_service.IsQualifiedForLiberty} */ (
    jspb.Message.getWrapperField(this, insurance_service_pb.IsQualifiedForLiberty, 103));
};


/**
 * @param {?proto.insurance_service.IsQualifiedForLiberty|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setIsQualifiedForLiberty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 103, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearIsQualifiedForLiberty = function() {
  return this.setIsQualifiedForLiberty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasIsQualifiedForLiberty = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional insurance_service.InsurancePolicyCreated insurance_policy_created = 104;
 * @return {?proto.insurance_service.InsurancePolicyCreated}
 */
proto.events.Event.prototype.getInsurancePolicyCreated = function() {
  return /** @type{?proto.insurance_service.InsurancePolicyCreated} */ (
    jspb.Message.getWrapperField(this, insurance_service_pb.InsurancePolicyCreated, 104));
};


/**
 * @param {?proto.insurance_service.InsurancePolicyCreated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInsurancePolicyCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 104, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInsurancePolicyCreated = function() {
  return this.setInsurancePolicyCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInsurancePolicyCreated = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional dealer.DealershipUserUpdated dealership_user_updated = 105;
 * @return {?proto.dealer.DealershipUserUpdated}
 */
proto.events.Event.prototype.getDealershipUserUpdated = function() {
  return /** @type{?proto.dealer.DealershipUserUpdated} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUserUpdated, 105));
};


/**
 * @param {?proto.dealer.DealershipUserUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDealershipUserUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 105, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDealershipUserUpdated = function() {
  return this.setDealershipUserUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDealershipUserUpdated = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional dealer.DealershipUpdated dealership_updated = 106;
 * @return {?proto.dealer.DealershipUpdated}
 */
proto.events.Event.prototype.getDealershipUpdated = function() {
  return /** @type{?proto.dealer.DealershipUpdated} */ (
    jspb.Message.getWrapperField(this, dealer_pb.DealershipUpdated, 106));
};


/**
 * @param {?proto.dealer.DealershipUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setDealershipUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 106, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearDealershipUpdated = function() {
  return this.setDealershipUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasDealershipUpdated = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional customer.PhoneNumberEntered phone_number_entered = 107;
 * @return {?proto.customer.PhoneNumberEntered}
 */
proto.events.Event.prototype.getPhoneNumberEntered = function() {
  return /** @type{?proto.customer.PhoneNumberEntered} */ (
    jspb.Message.getWrapperField(this, customer_pb.PhoneNumberEntered, 107));
};


/**
 * @param {?proto.customer.PhoneNumberEntered|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setPhoneNumberEntered = function(value) {
  return jspb.Message.setOneofWrapperField(this, 107, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearPhoneNumberEntered = function() {
  return this.setPhoneNumberEntered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasPhoneNumberEntered = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional customer.LicenseInfoSubmitted license_info_submitted = 108;
 * @return {?proto.customer.LicenseInfoSubmitted}
 */
proto.events.Event.prototype.getLicenseInfoSubmitted = function() {
  return /** @type{?proto.customer.LicenseInfoSubmitted} */ (
    jspb.Message.getWrapperField(this, customer_pb.LicenseInfoSubmitted, 108));
};


/**
 * @param {?proto.customer.LicenseInfoSubmitted|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setLicenseInfoSubmitted = function(value) {
  return jspb.Message.setOneofWrapperField(this, 108, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearLicenseInfoSubmitted = function() {
  return this.setLicenseInfoSubmitted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasLicenseInfoSubmitted = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional vehicle.VehicleInfleetingUpdated vehicle_infleeting_updated = 109;
 * @return {?proto.vehicle.VehicleInfleetingUpdated}
 */
proto.events.Event.prototype.getVehicleInfleetingUpdated = function() {
  return /** @type{?proto.vehicle.VehicleInfleetingUpdated} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.VehicleInfleetingUpdated, 109));
};


/**
 * @param {?proto.vehicle.VehicleInfleetingUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setVehicleInfleetingUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 109, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearVehicleInfleetingUpdated = function() {
  return this.setVehicleInfleetingUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasVehicleInfleetingUpdated = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional ExtendInsurancePolicies extend_insurance_policies = 110;
 * @return {?proto.events.ExtendInsurancePolicies}
 */
proto.events.Event.prototype.getExtendInsurancePolicies = function() {
  return /** @type{?proto.events.ExtendInsurancePolicies} */ (
    jspb.Message.getWrapperField(this, proto.events.ExtendInsurancePolicies, 110));
};


/**
 * @param {?proto.events.ExtendInsurancePolicies|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setExtendInsurancePolicies = function(value) {
  return jspb.Message.setOneofWrapperField(this, 110, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearExtendInsurancePolicies = function() {
  return this.setExtendInsurancePolicies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasExtendInsurancePolicies = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional insurance_service.InsurancePolicyUpdated insurance_policy_updated = 111;
 * @return {?proto.insurance_service.InsurancePolicyUpdated}
 */
proto.events.Event.prototype.getInsurancePolicyUpdated = function() {
  return /** @type{?proto.insurance_service.InsurancePolicyUpdated} */ (
    jspb.Message.getWrapperField(this, insurance_service_pb.InsurancePolicyUpdated, 111));
};


/**
 * @param {?proto.insurance_service.InsurancePolicyUpdated|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInsurancePolicyUpdated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 111, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInsurancePolicyUpdated = function() {
  return this.setInsurancePolicyUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInsurancePolicyUpdated = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional MilesPurchased miles_purchased = 112;
 * @return {?proto.events.MilesPurchased}
 */
proto.events.Event.prototype.getMilesPurchased = function() {
  return /** @type{?proto.events.MilesPurchased} */ (
    jspb.Message.getWrapperField(this, proto.events.MilesPurchased, 112));
};


/**
 * @param {?proto.events.MilesPurchased|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setMilesPurchased = function(value) {
  return jspb.Message.setOneofWrapperField(this, 112, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearMilesPurchased = function() {
  return this.setMilesPurchased(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasMilesPurchased = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional insurance_service.InsurancePolicyCancelled insurance_policy_cancelled = 113;
 * @return {?proto.insurance_service.InsurancePolicyCancelled}
 */
proto.events.Event.prototype.getInsurancePolicyCancelled = function() {
  return /** @type{?proto.insurance_service.InsurancePolicyCancelled} */ (
    jspb.Message.getWrapperField(this, insurance_service_pb.InsurancePolicyCancelled, 113));
};


/**
 * @param {?proto.insurance_service.InsurancePolicyCancelled|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setInsurancePolicyCancelled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 113, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearInsurancePolicyCancelled = function() {
  return this.setInsurancePolicyCancelled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasInsurancePolicyCancelled = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional SubscriptionVehicleChange subscription_vehicle_change = 114;
 * @return {?proto.events.SubscriptionVehicleChange}
 */
proto.events.Event.prototype.getSubscriptionVehicleChange = function() {
  return /** @type{?proto.events.SubscriptionVehicleChange} */ (
    jspb.Message.getWrapperField(this, proto.events.SubscriptionVehicleChange, 114));
};


/**
 * @param {?proto.events.SubscriptionVehicleChange|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionVehicleChange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 114, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionVehicleChange = function() {
  return this.setSubscriptionVehicleChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionVehicleChange = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional SubscriptionVehicleReturned subscription_vehicle_returned = 115;
 * @return {?proto.events.SubscriptionVehicleReturned}
 */
proto.events.Event.prototype.getSubscriptionVehicleReturned = function() {
  return /** @type{?proto.events.SubscriptionVehicleReturned} */ (
    jspb.Message.getWrapperField(this, proto.events.SubscriptionVehicleReturned, 115));
};


/**
 * @param {?proto.events.SubscriptionVehicleReturned|undefined} value
 * @return {!proto.events.Event} returns this
*/
proto.events.Event.prototype.setSubscriptionVehicleReturned = function(value) {
  return jspb.Message.setOneofWrapperField(this, 115, proto.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.Event} returns this
 */
proto.events.Event.prototype.clearSubscriptionVehicleReturned = function() {
  return this.setSubscriptionVehicleReturned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.Event.prototype.hasSubscriptionVehicleReturned = function() {
  return jspb.Message.getField(this, 115) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.PaymentRefunded.prototype.toObject = function(opt_includeInstance) {
  return proto.events.PaymentRefunded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.PaymentRefunded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PaymentRefunded.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.PaymentRefunded}
 */
proto.events.PaymentRefunded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.PaymentRefunded;
  return proto.events.PaymentRefunded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.PaymentRefunded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.PaymentRefunded}
 */
proto.events.PaymentRefunded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.PaymentRefunded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.PaymentRefunded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.PaymentRefunded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PaymentRefunded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.PaymentRefunded.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.events.PaymentRefunded.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string invoice_number = 3;
 * @return {string}
 */
proto.events.PaymentRefunded.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.events.PaymentRefunded.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.events.PaymentRefunded.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional domain.Subscription.SubscriptionAction.ActionReason reason = 6;
 * @return {!proto.domain.Subscription.SubscriptionAction.ActionReason}
 */
proto.events.PaymentRefunded.prototype.getReason = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.events.PaymentRefunded} returns this
 */
proto.events.PaymentRefunded.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.InvoiceCancelled.prototype.toObject = function(opt_includeInstance) {
  return proto.events.InvoiceCancelled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.InvoiceCancelled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceCancelled.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.InvoiceCancelled}
 */
proto.events.InvoiceCancelled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.InvoiceCancelled;
  return proto.events.InvoiceCancelled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.InvoiceCancelled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.InvoiceCancelled}
 */
proto.events.InvoiceCancelled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.InvoiceCancelled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.InvoiceCancelled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.InvoiceCancelled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceCancelled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string invoice_id = 1;
 * @return {string}
 */
proto.events.InvoiceCancelled.prototype.getInvoiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.InvoiceCancelled} returns this
 */
proto.events.InvoiceCancelled.prototype.setInvoiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.SubscriptionCancelled.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SubscriptionCancelled.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SubscriptionCancelled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SubscriptionCancelled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionCancelled.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actionReason: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 6, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    domain_pb.Transaction.toObject, includeInstance),
    refundAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SubscriptionCancelled}
 */
proto.events.SubscriptionCancelled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SubscriptionCancelled;
  return proto.events.SubscriptionCancelled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SubscriptionCancelled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SubscriptionCancelled}
 */
proto.events.SubscriptionCancelled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 5:
      var value = /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (reader.readEnum());
      msg.setActionReason(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 7:
      var value = new domain_pb.Transaction;
      reader.readMessage(value,domain_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SubscriptionCancelled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SubscriptionCancelled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SubscriptionCancelled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionCancelled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActionReason();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      domain_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getRefundAmount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_email = 4;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional domain.Subscription.SubscriptionAction.ActionReason action_reason = 5;
 * @return {!proto.domain.Subscription.SubscriptionAction.ActionReason}
 */
proto.events.SubscriptionCancelled.prototype.getActionReason = function() {
  return /** @type {!proto.domain.Subscription.SubscriptionAction.ActionReason} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.domain.Subscription.SubscriptionAction.ActionReason} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setActionReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string notes = 6;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated domain.Transaction transactions = 7;
 * @return {!Array<!proto.domain.Transaction>}
 */
proto.events.SubscriptionCancelled.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.domain.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Transaction, 7));
};


/**
 * @param {!Array<!proto.domain.Transaction>} value
 * @return {!proto.events.SubscriptionCancelled} returns this
*/
proto.events.SubscriptionCancelled.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.domain.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Transaction}
 */
proto.events.SubscriptionCancelled.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.domain.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional int32 refund_amount = 8;
 * @return {number}
 */
proto.events.SubscriptionCancelled.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string account_number = 9;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string invoice_number = 10;
 * @return {string}
 */
proto.events.SubscriptionCancelled.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionCancelled} returns this
 */
proto.events.SubscriptionCancelled.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.TripReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.events.TripReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.TripReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.TripReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    trip: (f = msg.getTrip()) && telemetric_pb.TripReceived.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.TripReceived}
 */
proto.events.TripReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.TripReceived;
  return proto.events.TripReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.TripReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.TripReceived}
 */
proto.events.TripReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new telemetric_pb.TripReceived;
      reader.readMessage(value,telemetric_pb.TripReceived.deserializeBinaryFromReader);
      msg.setTrip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.TripReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.TripReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.TripReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.TripReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrip();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      telemetric_pb.TripReceived.serializeBinaryToWriter
    );
  }
};


/**
 * optional telemetric.TripReceived trip = 1;
 * @return {?proto.telemetric.TripReceived}
 */
proto.events.TripReceived.prototype.getTrip = function() {
  return /** @type{?proto.telemetric.TripReceived} */ (
    jspb.Message.getWrapperField(this, telemetric_pb.TripReceived, 1));
};


/**
 * @param {?proto.telemetric.TripReceived|undefined} value
 * @return {!proto.events.TripReceived} returns this
*/
proto.events.TripReceived.prototype.setTrip = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.TripReceived} returns this
 */
proto.events.TripReceived.prototype.clearTrip = function() {
  return this.setTrip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.TripReceived.prototype.hasTrip = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CollisionReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CollisionReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CollisionReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CollisionReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    collision: (f = msg.getCollision()) && telemetric_pb.CollisionReceived.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CollisionReceived}
 */
proto.events.CollisionReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CollisionReceived;
  return proto.events.CollisionReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CollisionReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CollisionReceived}
 */
proto.events.CollisionReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new telemetric_pb.CollisionReceived;
      reader.readMessage(value,telemetric_pb.CollisionReceived.deserializeBinaryFromReader);
      msg.setCollision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CollisionReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CollisionReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CollisionReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CollisionReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      telemetric_pb.CollisionReceived.serializeBinaryToWriter
    );
  }
};


/**
 * optional telemetric.CollisionReceived collision = 2;
 * @return {?proto.telemetric.CollisionReceived}
 */
proto.events.CollisionReceived.prototype.getCollision = function() {
  return /** @type{?proto.telemetric.CollisionReceived} */ (
    jspb.Message.getWrapperField(this, telemetric_pb.CollisionReceived, 2));
};


/**
 * @param {?proto.telemetric.CollisionReceived|undefined} value
 * @return {!proto.events.CollisionReceived} returns this
*/
proto.events.CollisionReceived.prototype.setCollision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CollisionReceived} returns this
 */
proto.events.CollisionReceived.prototype.clearCollision = function() {
  return this.setCollision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CollisionReceived.prototype.hasCollision = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.events.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.Notification}
 */
proto.events.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.Notification;
  return proto.events.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.Notification}
 */
proto.events.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.events.Notification.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.Notification} returns this
 */
proto.events.Notification.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerCreated}
 */
proto.events.CustomerCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerCreated;
  return proto.events.CustomerCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerCreated}
 */
proto.events.CustomerCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.events.CustomerCreated.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerCreated} returns this
 */
proto.events.CustomerCreated.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.CustomerCreated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerCreated} returns this
 */
proto.events.CustomerCreated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.CustomerInfoUpdated.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerInfoUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerInfoUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerInfoUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerInfoUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    licenseNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isPartial: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    scoresList: jspb.Message.toObjectList(msg.getScoresList(),
    domain_pb.Score.toObject, includeInstance),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    domain_pb.Address.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    domain_pb.Image.toObject, includeInstance),
    qualificationTier: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentMethodsRequired: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerInfoUpdated}
 */
proto.events.CustomerInfoUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerInfoUpdated;
  return proto.events.CustomerInfoUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerInfoUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerInfoUpdated}
 */
proto.events.CustomerInfoUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseNumber(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPartial(value);
      break;
    case 9:
      var value = new domain_pb.Score;
      reader.readMessage(value,domain_pb.Score.deserializeBinaryFromReader);
      msg.addScores(value);
      break;
    case 10:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 11:
      var value = new domain_pb.Image;
      reader.readMessage(value,domain_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 12:
      var value = /** @type {!proto.domain.QualificationTier} */ (reader.readEnum());
      msg.setQualificationTier(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentMethodsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerInfoUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerInfoUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerInfoUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerInfoUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLicenseNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsPartial();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      domain_pb.Score.serializeBinaryToWriter
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      domain_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getQualificationTier();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPaymentMethodsRequired();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string license_number = 7;
 * @return {string}
 */
proto.events.CustomerInfoUpdated.prototype.getLicenseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setLicenseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_partial = 8;
 * @return {boolean}
 */
proto.events.CustomerInfoUpdated.prototype.getIsPartial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setIsPartial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated domain.Score scores = 9;
 * @return {!Array<!proto.domain.Score>}
 */
proto.events.CustomerInfoUpdated.prototype.getScoresList = function() {
  return /** @type{!Array<!proto.domain.Score>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Score, 9));
};


/**
 * @param {!Array<!proto.domain.Score>} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
*/
proto.events.CustomerInfoUpdated.prototype.setScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.domain.Score=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Score}
 */
proto.events.CustomerInfoUpdated.prototype.addScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.domain.Score, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * repeated domain.Address addresses = 10;
 * @return {!Array<!proto.domain.Address>}
 */
proto.events.CustomerInfoUpdated.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.domain.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Address, 10));
};


/**
 * @param {!Array<!proto.domain.Address>} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
*/
proto.events.CustomerInfoUpdated.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.domain.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Address}
 */
proto.events.CustomerInfoUpdated.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.domain.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated domain.Image images = 11;
 * @return {!Array<!proto.domain.Image>}
 */
proto.events.CustomerInfoUpdated.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.domain.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Image, 11));
};


/**
 * @param {!Array<!proto.domain.Image>} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
*/
proto.events.CustomerInfoUpdated.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.domain.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Image}
 */
proto.events.CustomerInfoUpdated.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.domain.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional domain.QualificationTier qualification_tier = 12;
 * @return {!proto.domain.QualificationTier}
 */
proto.events.CustomerInfoUpdated.prototype.getQualificationTier = function() {
  return /** @type {!proto.domain.QualificationTier} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.domain.QualificationTier} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setQualificationTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int32 payment_methods_required = 13;
 * @return {number}
 */
proto.events.CustomerInfoUpdated.prototype.getPaymentMethodsRequired = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.CustomerInfoUpdated} returns this
 */
proto.events.CustomerInfoUpdated.prototype.setPaymentMethodsRequired = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerInsuranceUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerInsuranceUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerInsuranceUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerInsuranceUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestSource: jspb.Message.getFieldWithDefault(msg, 2, 0),
    insurancePolicy: (f = msg.getInsurancePolicy()) && domain_pb.InsurancePolicy.toObject(includeInstance, f),
    changeType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerInsuranceUpdated}
 */
proto.events.CustomerInsuranceUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerInsuranceUpdated;
  return proto.events.CustomerInsuranceUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerInsuranceUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerInsuranceUpdated}
 */
proto.events.CustomerInsuranceUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.events.CustomerInsuranceUpdated.RequestSource} */ (reader.readEnum());
      msg.setRequestSource(value);
      break;
    case 3:
      var value = new domain_pb.InsurancePolicy;
      reader.readMessage(value,domain_pb.InsurancePolicy.deserializeBinaryFromReader);
      msg.setInsurancePolicy(value);
      break;
    case 4:
      var value = /** @type {!proto.events.CustomerInsuranceUpdated.ChangeType} */ (reader.readEnum());
      msg.setChangeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerInsuranceUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerInsuranceUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerInsuranceUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerInsuranceUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestSource();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInsurancePolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.InsurancePolicy.serializeBinaryToWriter
    );
  }
  f = message.getChangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.events.CustomerInsuranceUpdated.RequestSource = {
  CUSTOMER: 0,
  CUSTOMER_SUPPORT: 1
};

/**
 * @enum {number}
 */
proto.events.CustomerInsuranceUpdated.ChangeType = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2
};

/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerInsuranceUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerInsuranceUpdated} returns this
 */
proto.events.CustomerInsuranceUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestSource request_source = 2;
 * @return {!proto.events.CustomerInsuranceUpdated.RequestSource}
 */
proto.events.CustomerInsuranceUpdated.prototype.getRequestSource = function() {
  return /** @type {!proto.events.CustomerInsuranceUpdated.RequestSource} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.events.CustomerInsuranceUpdated.RequestSource} value
 * @return {!proto.events.CustomerInsuranceUpdated} returns this
 */
proto.events.CustomerInsuranceUpdated.prototype.setRequestSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional domain.InsurancePolicy insurance_policy = 3;
 * @return {?proto.domain.InsurancePolicy}
 */
proto.events.CustomerInsuranceUpdated.prototype.getInsurancePolicy = function() {
  return /** @type{?proto.domain.InsurancePolicy} */ (
    jspb.Message.getWrapperField(this, domain_pb.InsurancePolicy, 3));
};


/**
 * @param {?proto.domain.InsurancePolicy|undefined} value
 * @return {!proto.events.CustomerInsuranceUpdated} returns this
*/
proto.events.CustomerInsuranceUpdated.prototype.setInsurancePolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CustomerInsuranceUpdated} returns this
 */
proto.events.CustomerInsuranceUpdated.prototype.clearInsurancePolicy = function() {
  return this.setInsurancePolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CustomerInsuranceUpdated.prototype.hasInsurancePolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChangeType change_type = 4;
 * @return {!proto.events.CustomerInsuranceUpdated.ChangeType}
 */
proto.events.CustomerInsuranceUpdated.prototype.getChangeType = function() {
  return /** @type {!proto.events.CustomerInsuranceUpdated.ChangeType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.events.CustomerInsuranceUpdated.ChangeType} value
 * @return {!proto.events.CustomerInsuranceUpdated} returns this
 */
proto.events.CustomerInsuranceUpdated.prototype.setChangeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerStateUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerStateUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerStateUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerStateUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    newState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerStateUpdated}
 */
proto.events.CustomerStateUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerStateUpdated;
  return proto.events.CustomerStateUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerStateUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerStateUpdated}
 */
proto.events.CustomerStateUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.CustomerState} */ (reader.readEnum());
      msg.setNewState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerStateUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerStateUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerStateUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerStateUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional domain.CustomerState new_state = 1;
 * @return {!proto.domain.CustomerState}
 */
proto.events.CustomerStateUpdated.prototype.getNewState = function() {
  return /** @type {!proto.domain.CustomerState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.CustomerState} value
 * @return {!proto.events.CustomerStateUpdated} returns this
 */
proto.events.CustomerStateUpdated.prototype.setNewState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.CustomerStateUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerStateUpdated} returns this
 */
proto.events.CustomerStateUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerFailureStateUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerFailureStateUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerFailureStateUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerFailureStateUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    newState: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerFailureStateUpdated}
 */
proto.events.CustomerFailureStateUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerFailureStateUpdated;
  return proto.events.CustomerFailureStateUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerFailureStateUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerFailureStateUpdated}
 */
proto.events.CustomerFailureStateUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.CustomerFailureState} */ (reader.readEnum());
      msg.setNewState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerFailureStateUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerFailureStateUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerFailureStateUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerFailureStateUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional domain.CustomerFailureState new_state = 1;
 * @return {!proto.domain.CustomerFailureState}
 */
proto.events.CustomerFailureStateUpdated.prototype.getNewState = function() {
  return /** @type {!proto.domain.CustomerFailureState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.CustomerFailureState} value
 * @return {!proto.events.CustomerFailureStateUpdated} returns this
 */
proto.events.CustomerFailureStateUpdated.prototype.setNewState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerActivityUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerActivityUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerActivityUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerActivityUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    newActivity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerActivityUpdated}
 */
proto.events.CustomerActivityUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerActivityUpdated;
  return proto.events.CustomerActivityUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerActivityUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerActivityUpdated}
 */
proto.events.CustomerActivityUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.CustomerActivity} */ (reader.readEnum());
      msg.setNewActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerActivityUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerActivityUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerActivityUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerActivityUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional domain.CustomerActivity new_activity = 1;
 * @return {!proto.domain.CustomerActivity}
 */
proto.events.CustomerActivityUpdated.prototype.getNewActivity = function() {
  return /** @type {!proto.domain.CustomerActivity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.CustomerActivity} value
 * @return {!proto.events.CustomerActivityUpdated} returns this
 */
proto.events.CustomerActivityUpdated.prototype.setNewActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ActivityStateUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ActivityStateUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ActivityStateUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ActivityStateUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    newState: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ActivityStateUpdated}
 */
proto.events.ActivityStateUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ActivityStateUpdated;
  return proto.events.ActivityStateUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ActivityStateUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ActivityStateUpdated}
 */
proto.events.ActivityStateUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.domain.ActivityState} */ (reader.readEnum());
      msg.setNewState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ActivityStateUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ActivityStateUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ActivityStateUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ActivityStateUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional domain.ActivityState new_state = 1;
 * @return {!proto.domain.ActivityState}
 */
proto.events.ActivityStateUpdated.prototype.getNewState = function() {
  return /** @type {!proto.domain.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.domain.ActivityState} value
 * @return {!proto.events.ActivityStateUpdated} returns this
 */
proto.events.ActivityStateUpdated.prototype.setNewState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VendorAccountUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VendorAccountUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VendorAccountUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VendorAccountUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vendorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VendorAccountUpdated}
 */
proto.events.VendorAccountUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VendorAccountUpdated;
  return proto.events.VendorAccountUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VendorAccountUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VendorAccountUpdated}
 */
proto.events.VendorAccountUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VendorAccountUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VendorAccountUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VendorAccountUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VendorAccountUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.VendorAccountUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VendorAccountUpdated} returns this
 */
proto.events.VendorAccountUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vendor_name = 2;
 * @return {string}
 */
proto.events.VendorAccountUpdated.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VendorAccountUpdated} returns this
 */
proto.events.VendorAccountUpdated.prototype.setVendorName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.events.VendorAccountUpdated.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VendorAccountUpdated} returns this
 */
proto.events.VendorAccountUpdated.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerDeleted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerDeleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerDeleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerDeleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerDeleted}
 */
proto.events.CustomerDeleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerDeleted;
  return proto.events.CustomerDeleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerDeleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerDeleted}
 */
proto.events.CustomerDeleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerDeleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerDeleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerDeleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerDeleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerDeleted.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDeleted} returns this
 */
proto.events.CustomerDeleted.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.PickupDateSet.prototype.toObject = function(opt_includeInstance) {
  return proto.events.PickupDateSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.PickupDateSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PickupDateSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pickupDate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.PickupDateSet}
 */
proto.events.PickupDateSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.PickupDateSet;
  return proto.events.PickupDateSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.PickupDateSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.PickupDateSet}
 */
proto.events.PickupDateSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.PickupDateSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.PickupDateSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.PickupDateSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PickupDateSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPickupDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.PickupDateSet.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PickupDateSet} returns this
 */
proto.events.PickupDateSet.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.PickupDateSet.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PickupDateSet} returns this
 */
proto.events.PickupDateSet.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 pickup_date = 3;
 * @return {number}
 */
proto.events.PickupDateSet.prototype.getPickupDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.PickupDateSet} returns this
 */
proto.events.PickupDateSet.prototype.setPickupDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehiclePrePickup.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehiclePrePickup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehiclePrePickup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehiclePrePickup.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehiclePrePickup}
 */
proto.events.VehiclePrePickup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehiclePrePickup;
  return proto.events.VehiclePrePickup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehiclePrePickup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehiclePrePickup}
 */
proto.events.VehiclePrePickup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehiclePrePickup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehiclePrePickup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehiclePrePickup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehiclePrePickup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.VehiclePrePickup.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehiclePrePickup} returns this
 */
proto.events.VehiclePrePickup.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehiclePickup.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehiclePickup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehiclePickup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehiclePickup.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehiclePickup}
 */
proto.events.VehiclePickup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehiclePickup;
  return proto.events.VehiclePickup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehiclePickup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehiclePickup}
 */
proto.events.VehiclePickup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehiclePickup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehiclePickup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehiclePickup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehiclePickup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.VehiclePickup.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehiclePickup} returns this
 */
proto.events.VehiclePickup.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.PrepickupAuth.prototype.toObject = function(opt_includeInstance) {
  return proto.events.PrepickupAuth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.PrepickupAuth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PrepickupAuth.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.PrepickupAuth}
 */
proto.events.PrepickupAuth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.PrepickupAuth;
  return proto.events.PrepickupAuth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.PrepickupAuth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.PrepickupAuth}
 */
proto.events.PrepickupAuth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 3:
      var value = /** @type {!proto.events.AuthStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.PrepickupAuth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.PrepickupAuth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.PrepickupAuth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PrepickupAuth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.PrepickupAuth.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PrepickupAuth} returns this
 */
proto.events.PrepickupAuth.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_method_id = 2;
 * @return {string}
 */
proto.events.PrepickupAuth.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PrepickupAuth} returns this
 */
proto.events.PrepickupAuth.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AuthStatus status = 3;
 * @return {!proto.events.AuthStatus}
 */
proto.events.PrepickupAuth.prototype.getStatus = function() {
  return /** @type {!proto.events.AuthStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.events.AuthStatus} value
 * @return {!proto.events.PrepickupAuth} returns this
 */
proto.events.PrepickupAuth.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.PickupPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.events.PickupPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.PickupPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PickupPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.PickupPayment}
 */
proto.events.PickupPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.PickupPayment;
  return proto.events.PickupPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.PickupPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.PickupPayment}
 */
proto.events.PickupPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 3:
      var value = /** @type {!proto.events.PaymentStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.PickupPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.PickupPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.PickupPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PickupPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.PickupPayment.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PickupPayment} returns this
 */
proto.events.PickupPayment.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string payment_method_id = 2;
 * @return {string}
 */
proto.events.PickupPayment.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PickupPayment} returns this
 */
proto.events.PickupPayment.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PaymentStatus status = 3;
 * @return {!proto.events.PaymentStatus}
 */
proto.events.PickupPayment.prototype.getStatus = function() {
  return /** @type {!proto.events.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.events.PaymentStatus} value
 * @return {!proto.events.PickupPayment} returns this
 */
proto.events.PickupPayment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleCreated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleCreated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleCreated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleCreated.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleCreated}
 */
proto.events.VehicleCreated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleCreated;
  return proto.events.VehicleCreated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleCreated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleCreated}
 */
proto.events.VehicleCreated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleCreated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleCreated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleCreated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleCreated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.events.VehicleCreated.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.events.VehicleCreated} returns this
*/
proto.events.VehicleCreated.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.VehicleCreated} returns this
 */
proto.events.VehicleCreated.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.VehicleCreated.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.TransferVehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.events.TransferVehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.TransferVehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.TransferVehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.TransferVehicle}
 */
proto.events.TransferVehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.TransferVehicle;
  return proto.events.TransferVehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.TransferVehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.TransferVehicle}
 */
proto.events.TransferVehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.TransferVehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.TransferVehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.TransferVehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.TransferVehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.events.TransferVehicle.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.events.TransferVehicle} returns this
*/
proto.events.TransferVehicle.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.TransferVehicle} returns this
 */
proto.events.TransferVehicle.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.TransferVehicle.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.InvoiceReady.prototype.toObject = function(opt_includeInstance) {
  return proto.events.InvoiceReady.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.InvoiceReady} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceReady.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statement: (f = msg.getStatement()) && domain_pb.Statement.toObject(includeInstance, f),
    recurring: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.InvoiceReady}
 */
proto.events.InvoiceReady.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.InvoiceReady;
  return proto.events.InvoiceReady.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.InvoiceReady} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.InvoiceReady}
 */
proto.events.InvoiceReady.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new domain_pb.Statement;
      reader.readMessage(value,domain_pb.Statement.deserializeBinaryFromReader);
      msg.setStatement(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecurring(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.InvoiceReady.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.InvoiceReady.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.InvoiceReady} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceReady.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Statement.serializeBinaryToWriter
    );
  }
  f = message.getRecurring();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.events.InvoiceReady.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.InvoiceReady} returns this
 */
proto.events.InvoiceReady.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.InvoiceReady.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.InvoiceReady} returns this
 */
proto.events.InvoiceReady.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional domain.Statement statement = 3;
 * @return {?proto.domain.Statement}
 */
proto.events.InvoiceReady.prototype.getStatement = function() {
  return /** @type{?proto.domain.Statement} */ (
    jspb.Message.getWrapperField(this, domain_pb.Statement, 3));
};


/**
 * @param {?proto.domain.Statement|undefined} value
 * @return {!proto.events.InvoiceReady} returns this
*/
proto.events.InvoiceReady.prototype.setStatement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.InvoiceReady} returns this
 */
proto.events.InvoiceReady.prototype.clearStatement = function() {
  return this.setStatement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.InvoiceReady.prototype.hasStatement = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool recurring = 4;
 * @return {boolean}
 */
proto.events.InvoiceReady.prototype.getRecurring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.events.InvoiceReady} returns this
 */
proto.events.InvoiceReady.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OdometerUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OdometerUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OdometerUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OdometerUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OdometerUpdated}
 */
proto.events.OdometerUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OdometerUpdated;
  return proto.events.OdometerUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OdometerUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OdometerUpdated}
 */
proto.events.OdometerUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OdometerUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OdometerUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OdometerUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OdometerUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.events.OdometerUpdated.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OdometerUpdated} returns this
 */
proto.events.OdometerUpdated.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.events.OdometerUpdated.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OdometerUpdated} returns this
 */
proto.events.OdometerUpdated.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OdometerSet.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OdometerSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OdometerSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OdometerSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OdometerSet}
 */
proto.events.OdometerSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OdometerSet;
  return proto.events.OdometerSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OdometerSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OdometerSet}
 */
proto.events.OdometerSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OdometerSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OdometerSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OdometerSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OdometerSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.events.OdometerSet.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OdometerSet} returns this
 */
proto.events.OdometerSet.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.events.OdometerSet.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.events.OdometerSet} returns this
 */
proto.events.OdometerSet.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.StatementActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.events.StatementActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.StatementActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StatementActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statementId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statementDueDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    daysPastDue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    activityType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.StatementActivity}
 */
proto.events.StatementActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.StatementActivity;
  return proto.events.StatementActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.StatementActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.StatementActivity}
 */
proto.events.StatementActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatementId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatementDueDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysPastDue(value);
      break;
    case 6:
      var value = /** @type {!proto.events.StatementActivity.ActivityType} */ (reader.readEnum());
      msg.setActivityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.StatementActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.StatementActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.StatementActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.StatementActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatementDueDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDaysPastDue();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getActivityType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.events.StatementActivity.ActivityType = {
  LINE_ITEM_ADDED: 0,
  STATEMENT_CANCELLED: 1
};

/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.StatementActivity.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.StatementActivity.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string statement_id = 3;
 * @return {string}
 */
proto.events.StatementActivity.prototype.getStatementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setStatementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 statement_due_date = 4;
 * @return {number}
 */
proto.events.StatementActivity.prototype.getStatementDueDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setStatementDueDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 days_past_due = 5;
 * @return {number}
 */
proto.events.StatementActivity.prototype.getDaysPastDue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setDaysPastDue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ActivityType activity_type = 6;
 * @return {!proto.events.StatementActivity.ActivityType}
 */
proto.events.StatementActivity.prototype.getActivityType = function() {
  return /** @type {!proto.events.StatementActivity.ActivityType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.events.StatementActivity.ActivityType} value
 * @return {!proto.events.StatementActivity} returns this
 */
proto.events.StatementActivity.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.PaymentActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.events.PaymentActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.PaymentActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PaymentActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statementId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transactionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paymentMethodId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    succeeded: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    last4: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activityType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    achReturnCode: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.PaymentActivity}
 */
proto.events.PaymentActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.PaymentActivity;
  return proto.events.PaymentActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.PaymentActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.PaymentActivity}
 */
proto.events.PaymentActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatementId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentMethodId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSucceeded(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 9:
      var value = /** @type {!proto.events.PaymentActivityType} */ (reader.readEnum());
      msg.setActivityType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchReturnCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.PaymentActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.PaymentActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.PaymentActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.PaymentActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaymentMethodId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSucceeded();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivityType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAchReturnCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string statement_id = 3;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getStatementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setStatementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transaction_id = 4;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string payment_method_id = 5;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getPaymentMethodId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setPaymentMethodId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 amount = 6;
 * @return {number}
 */
proto.events.PaymentActivity.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool succeeded = 7;
 * @return {boolean}
 */
proto.events.PaymentActivity.prototype.getSucceeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setSucceeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string last4 = 8;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setLast4 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional PaymentActivityType activity_type = 9;
 * @return {!proto.events.PaymentActivityType}
 */
proto.events.PaymentActivity.prototype.getActivityType = function() {
  return /** @type {!proto.events.PaymentActivityType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.events.PaymentActivityType} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string account_number = 10;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string reservation_id = 12;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string invoice_number = 13;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string ach_return_code = 14;
 * @return {string}
 */
proto.events.PaymentActivity.prototype.getAchReturnCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.PaymentActivity} returns this
 */
proto.events.PaymentActivity.prototype.setAchReturnCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.VehicleStateUpdated.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleStateUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleStateUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleStateUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleStateUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleVin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 4, 0),
    user: (f = msg.getUser()) && domain_pb.AutonomyUser.toObject(includeInstance, f),
    notes: jspb.Message.getFieldWithDefault(msg, 6, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    newCustomerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    proto.events.ReturnLineItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleStateUpdated}
 */
proto.events.VehicleStateUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleStateUpdated;
  return proto.events.VehicleStateUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleStateUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleStateUpdated}
 */
proto.events.VehicleStateUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleVin(value);
      break;
    case 4:
      var value = /** @type {!proto.events.VehicleStateUpdated.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 5:
      var value = new domain_pb.AutonomyUser;
      reader.readMessage(value,domain_pb.AutonomyUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 7:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewCustomerId(value);
      break;
    case 9:
      var value = new proto.events.ReturnLineItem;
      reader.readMessage(value,proto.events.ReturnLineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleStateUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleStateUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleStateUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleStateUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleVin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      domain_pb.AutonomyUser.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getNewCustomerId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.events.ReturnLineItem.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.events.VehicleStateUpdated.Reason = {
  REASON_UNSPECIFIED: 0,
  REASON_ASSIGNMENT: 1,
  REASON_RETURN: 3,
  REASON_TRANSFER: 4,
  REASON_REPO: 5,
  REASON_COLLISION: 6,
  REASON_UNASSIGNMENT: 7,
  REASON_DELISTING: 8,
  REASON_ACTIVATION: 9,
  REASON_LISTING: 10
};

/**
 * optional vehicle.State state = 1;
 * @return {!proto.vehicle.State}
 */
proto.events.VehicleStateUpdated.prototype.getState = function() {
  return /** @type {!proto.vehicle.State} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.State} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.events.VehicleStateUpdated.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_vin = 3;
 * @return {string}
 */
proto.events.VehicleStateUpdated.prototype.getVehicleVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setVehicleVin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Reason reason = 4;
 * @return {!proto.events.VehicleStateUpdated.Reason}
 */
proto.events.VehicleStateUpdated.prototype.getReason = function() {
  return /** @type {!proto.events.VehicleStateUpdated.Reason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.events.VehicleStateUpdated.Reason} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional domain.AutonomyUser user = 5;
 * @return {?proto.domain.AutonomyUser}
 */
proto.events.VehicleStateUpdated.prototype.getUser = function() {
  return /** @type{?proto.domain.AutonomyUser} */ (
    jspb.Message.getWrapperField(this, domain_pb.AutonomyUser, 5));
};


/**
 * @param {?proto.domain.AutonomyUser|undefined} value
 * @return {!proto.events.VehicleStateUpdated} returns this
*/
proto.events.VehicleStateUpdated.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.VehicleStateUpdated.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string notes = 6;
 * @return {string}
 */
proto.events.VehicleStateUpdated.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.events.VehicleStateUpdated.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;};


/**
 * optional string new_customer_id = 8;
 * @return {string}
 */
proto.events.VehicleStateUpdated.prototype.getNewCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.setNewCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ReturnLineItem line_items = 9;
 * @return {!Array<!proto.events.ReturnLineItem>}
 */
proto.events.VehicleStateUpdated.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.events.ReturnLineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.ReturnLineItem, 9));
};


/**
 * @param {!Array<!proto.events.ReturnLineItem>} value
 * @return {!proto.events.VehicleStateUpdated} returns this
*/
proto.events.VehicleStateUpdated.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.events.ReturnLineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.ReturnLineItem}
 */
proto.events.VehicleStateUpdated.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.events.ReturnLineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.VehicleStateUpdated} returns this
 */
proto.events.VehicleStateUpdated.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ReturnLineItem.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ReturnLineItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ReturnLineItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ReturnLineItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ReturnLineItem}
 */
proto.events.ReturnLineItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ReturnLineItem;
  return proto.events.ReturnLineItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ReturnLineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ReturnLineItem}
 */
proto.events.ReturnLineItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ReturnLineItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ReturnLineItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ReturnLineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ReturnLineItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.events.ReturnLineItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.ReturnLineItem} returns this
 */
proto.events.ReturnLineItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 amount = 2;
 * @return {number}
 */
proto.events.ReturnLineItem.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.ReturnLineItem} returns this
 */
proto.events.ReturnLineItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleActivityUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleActivityUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleActivityUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleActivityUpdated}
 */
proto.events.VehicleActivityUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleActivityUpdated;
  return proto.events.VehicleActivityUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleActivityUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleActivityUpdated}
 */
proto.events.VehicleActivityUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.Activity} */ (reader.readEnum());
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleActivityUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleActivityUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleActivityUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional vehicle.Activity activity = 1;
 * @return {!proto.vehicle.Activity}
 */
proto.events.VehicleActivityUpdated.prototype.getActivity = function() {
  return /** @type {!proto.vehicle.Activity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.Activity} value
 * @return {!proto.events.VehicleActivityUpdated} returns this
 */
proto.events.VehicleActivityUpdated.prototype.setActivity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleActivityStateUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleActivityStateUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleActivityStateUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityStateUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityState: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleActivityStateUpdated}
 */
proto.events.VehicleActivityStateUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleActivityStateUpdated;
  return proto.events.VehicleActivityStateUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleActivityStateUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleActivityStateUpdated}
 */
proto.events.VehicleActivityStateUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.ActivityState} */ (reader.readEnum());
      msg.setActivityState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleActivityStateUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleActivityStateUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleActivityStateUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityStateUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional vehicle.ActivityState activity_state = 1;
 * @return {!proto.vehicle.ActivityState}
 */
proto.events.VehicleActivityStateUpdated.prototype.getActivityState = function() {
  return /** @type {!proto.vehicle.ActivityState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.ActivityState} value
 * @return {!proto.events.VehicleActivityStateUpdated} returns this
 */
proto.events.VehicleActivityStateUpdated.prototype.setActivityState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleActivityFailureUpdated.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleActivityFailureUpdated.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleActivityFailureUpdated} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityFailureUpdated.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityFailure: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleActivityFailureUpdated}
 */
proto.events.VehicleActivityFailureUpdated.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleActivityFailureUpdated;
  return proto.events.VehicleActivityFailureUpdated.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleActivityFailureUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleActivityFailureUpdated}
 */
proto.events.VehicleActivityFailureUpdated.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vehicle.ActivityFailure} */ (reader.readEnum());
      msg.setActivityFailure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleActivityFailureUpdated.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleActivityFailureUpdated.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleActivityFailureUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleActivityFailureUpdated.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityFailure();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional vehicle.ActivityFailure activity_failure = 1;
 * @return {!proto.vehicle.ActivityFailure}
 */
proto.events.VehicleActivityFailureUpdated.prototype.getActivityFailure = function() {
  return /** @type {!proto.vehicle.ActivityFailure} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vehicle.ActivityFailure} value
 * @return {!proto.events.VehicleActivityFailureUpdated} returns this
 */
proto.events.VehicleActivityFailureUpdated.prototype.setActivityFailure = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.InvoiceToBePaid.prototype.toObject = function(opt_includeInstance) {
  return proto.events.InvoiceToBePaid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.InvoiceToBePaid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceToBePaid.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.InvoiceToBePaid}
 */
proto.events.InvoiceToBePaid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.InvoiceToBePaid;
  return proto.events.InvoiceToBePaid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.InvoiceToBePaid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.InvoiceToBePaid}
 */
proto.events.InvoiceToBePaid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.InvoiceToBePaid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.InvoiceToBePaid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.InvoiceToBePaid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.InvoiceToBePaid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.InvoiceToBePaid.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.InvoiceToBePaid} returns this
 */
proto.events.InvoiceToBePaid.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.InvoiceToBePaid.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.InvoiceToBePaid} returns this
 */
proto.events.InvoiceToBePaid.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SyncDealerships.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SyncDealerships.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SyncDealerships} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SyncDealerships.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SyncDealerships}
 */
proto.events.SyncDealerships.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SyncDealerships;
  return proto.events.SyncDealerships.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SyncDealerships} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SyncDealerships}
 */
proto.events.SyncDealerships.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SyncDealerships.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SyncDealerships.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SyncDealerships} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SyncDealerships.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.events.DocumentAdded.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.events.DocumentAdded.DoctypeCase = {
  DOCTYPE_NOT_SET: 0,
  INSPECTION: 1,
  REGISTRATION: 2,
  WARRANTY: 3,
  TITLE: 4,
  MAINTENANCE: 5,
  REG262: 6,
  REG51: 7,
  REG553: 8,
  PURCHASE_ORDER: 9,
  USED_VEHICLE_HISTORY_DISCLOSURE: 10,
  BUYERS_GUIDE: 11
};

/**
 * @return {proto.events.DocumentAdded.DoctypeCase}
 */
proto.events.DocumentAdded.prototype.getDoctypeCase = function() {
  return /** @type {proto.events.DocumentAdded.DoctypeCase} */(jspb.Message.computeOneofCase(this, proto.events.DocumentAdded.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.DocumentAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.events.DocumentAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.DocumentAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DocumentAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspection: (f = msg.getInspection()) && vehicle_pb.Inspection.toObject(includeInstance, f),
    registration: (f = msg.getRegistration()) && vehicle_pb.Registration.toObject(includeInstance, f),
    warranty: (f = msg.getWarranty()) && vehicle_pb.Warranty.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && vehicle_pb.Title.toObject(includeInstance, f),
    maintenance: (f = msg.getMaintenance()) && vehicle_pb.Maintenance.toObject(includeInstance, f),
    reg262: (f = msg.getReg262()) && vehicle_pb.Reg262.toObject(includeInstance, f),
    reg51: (f = msg.getReg51()) && vehicle_pb.Reg51.toObject(includeInstance, f),
    reg553: (f = msg.getReg553()) && vehicle_pb.Reg553.toObject(includeInstance, f),
    purchaseOrder: (f = msg.getPurchaseOrder()) && vehicle_pb.PurchaseOrder.toObject(includeInstance, f),
    usedVehicleHistoryDisclosure: (f = msg.getUsedVehicleHistoryDisclosure()) && vehicle_pb.UsedVehicleHistoryDisclosure.toObject(includeInstance, f),
    buyersGuide: (f = msg.getBuyersGuide()) && vehicle_pb.BuyersGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.DocumentAdded}
 */
proto.events.DocumentAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.DocumentAdded;
  return proto.events.DocumentAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.DocumentAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.DocumentAdded}
 */
proto.events.DocumentAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Inspection;
      reader.readMessage(value,vehicle_pb.Inspection.deserializeBinaryFromReader);
      msg.setInspection(value);
      break;
    case 2:
      var value = new vehicle_pb.Registration;
      reader.readMessage(value,vehicle_pb.Registration.deserializeBinaryFromReader);
      msg.setRegistration(value);
      break;
    case 3:
      var value = new vehicle_pb.Warranty;
      reader.readMessage(value,vehicle_pb.Warranty.deserializeBinaryFromReader);
      msg.setWarranty(value);
      break;
    case 4:
      var value = new vehicle_pb.Title;
      reader.readMessage(value,vehicle_pb.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 5:
      var value = new vehicle_pb.Maintenance;
      reader.readMessage(value,vehicle_pb.Maintenance.deserializeBinaryFromReader);
      msg.setMaintenance(value);
      break;
    case 6:
      var value = new vehicle_pb.Reg262;
      reader.readMessage(value,vehicle_pb.Reg262.deserializeBinaryFromReader);
      msg.setReg262(value);
      break;
    case 7:
      var value = new vehicle_pb.Reg51;
      reader.readMessage(value,vehicle_pb.Reg51.deserializeBinaryFromReader);
      msg.setReg51(value);
      break;
    case 8:
      var value = new vehicle_pb.Reg553;
      reader.readMessage(value,vehicle_pb.Reg553.deserializeBinaryFromReader);
      msg.setReg553(value);
      break;
    case 9:
      var value = new vehicle_pb.PurchaseOrder;
      reader.readMessage(value,vehicle_pb.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    case 10:
      var value = new vehicle_pb.UsedVehicleHistoryDisclosure;
      reader.readMessage(value,vehicle_pb.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader);
      msg.setUsedVehicleHistoryDisclosure(value);
      break;
    case 11:
      var value = new vehicle_pb.BuyersGuide;
      reader.readMessage(value,vehicle_pb.BuyersGuide.deserializeBinaryFromReader);
      msg.setBuyersGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.DocumentAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.DocumentAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.DocumentAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DocumentAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Inspection.serializeBinaryToWriter
    );
  }
  f = message.getRegistration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Registration.serializeBinaryToWriter
    );
  }
  f = message.getWarranty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vehicle_pb.Warranty.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vehicle_pb.Title.serializeBinaryToWriter
    );
  }
  f = message.getMaintenance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vehicle_pb.Maintenance.serializeBinaryToWriter
    );
  }
  f = message.getReg262();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vehicle_pb.Reg262.serializeBinaryToWriter
    );
  }
  f = message.getReg51();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vehicle_pb.Reg51.serializeBinaryToWriter
    );
  }
  f = message.getReg553();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vehicle_pb.Reg553.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vehicle_pb.PurchaseOrder.serializeBinaryToWriter
    );
  }
  f = message.getUsedVehicleHistoryDisclosure();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vehicle_pb.UsedVehicleHistoryDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getBuyersGuide();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vehicle_pb.BuyersGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Inspection inspection = 1;
 * @return {?proto.vehicle.Inspection}
 */
proto.events.DocumentAdded.prototype.getInspection = function() {
  return /** @type{?proto.vehicle.Inspection} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Inspection, 1));
};


/**
 * @param {?proto.vehicle.Inspection|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setInspection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearInspection = function() {
  return this.setInspection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasInspection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional vehicle.Registration registration = 2;
 * @return {?proto.vehicle.Registration}
 */
proto.events.DocumentAdded.prototype.getRegistration = function() {
  return /** @type{?proto.vehicle.Registration} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Registration, 2));
};


/**
 * @param {?proto.vehicle.Registration|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setRegistration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearRegistration = function() {
  return this.setRegistration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasRegistration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional vehicle.Warranty warranty = 3;
 * @return {?proto.vehicle.Warranty}
 */
proto.events.DocumentAdded.prototype.getWarranty = function() {
  return /** @type{?proto.vehicle.Warranty} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Warranty, 3));
};


/**
 * @param {?proto.vehicle.Warranty|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setWarranty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearWarranty = function() {
  return this.setWarranty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasWarranty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional vehicle.Title title = 4;
 * @return {?proto.vehicle.Title}
 */
proto.events.DocumentAdded.prototype.getTitle = function() {
  return /** @type{?proto.vehicle.Title} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Title, 4));
};


/**
 * @param {?proto.vehicle.Title|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setTitle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional vehicle.Maintenance maintenance = 5;
 * @return {?proto.vehicle.Maintenance}
 */
proto.events.DocumentAdded.prototype.getMaintenance = function() {
  return /** @type{?proto.vehicle.Maintenance} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Maintenance, 5));
};


/**
 * @param {?proto.vehicle.Maintenance|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setMaintenance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearMaintenance = function() {
  return this.setMaintenance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasMaintenance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional vehicle.Reg262 reg262 = 6;
 * @return {?proto.vehicle.Reg262}
 */
proto.events.DocumentAdded.prototype.getReg262 = function() {
  return /** @type{?proto.vehicle.Reg262} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg262, 6));
};


/**
 * @param {?proto.vehicle.Reg262|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setReg262 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearReg262 = function() {
  return this.setReg262(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasReg262 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional vehicle.Reg51 reg51 = 7;
 * @return {?proto.vehicle.Reg51}
 */
proto.events.DocumentAdded.prototype.getReg51 = function() {
  return /** @type{?proto.vehicle.Reg51} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg51, 7));
};


/**
 * @param {?proto.vehicle.Reg51|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setReg51 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearReg51 = function() {
  return this.setReg51(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasReg51 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional vehicle.Reg553 reg553 = 8;
 * @return {?proto.vehicle.Reg553}
 */
proto.events.DocumentAdded.prototype.getReg553 = function() {
  return /** @type{?proto.vehicle.Reg553} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg553, 8));
};


/**
 * @param {?proto.vehicle.Reg553|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setReg553 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearReg553 = function() {
  return this.setReg553(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasReg553 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional vehicle.PurchaseOrder purchase_order = 9;
 * @return {?proto.vehicle.PurchaseOrder}
 */
proto.events.DocumentAdded.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vehicle.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.PurchaseOrder, 9));
};


/**
 * @param {?proto.vehicle.PurchaseOrder|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional vehicle.UsedVehicleHistoryDisclosure used_vehicle_history_disclosure = 10;
 * @return {?proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.events.DocumentAdded.prototype.getUsedVehicleHistoryDisclosure = function() {
  return /** @type{?proto.vehicle.UsedVehicleHistoryDisclosure} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.UsedVehicleHistoryDisclosure, 10));
};


/**
 * @param {?proto.vehicle.UsedVehicleHistoryDisclosure|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setUsedVehicleHistoryDisclosure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearUsedVehicleHistoryDisclosure = function() {
  return this.setUsedVehicleHistoryDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasUsedVehicleHistoryDisclosure = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional vehicle.BuyersGuide buyers_guide = 11;
 * @return {?proto.vehicle.BuyersGuide}
 */
proto.events.DocumentAdded.prototype.getBuyersGuide = function() {
  return /** @type{?proto.vehicle.BuyersGuide} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.BuyersGuide, 11));
};


/**
 * @param {?proto.vehicle.BuyersGuide|undefined} value
 * @return {!proto.events.DocumentAdded} returns this
*/
proto.events.DocumentAdded.prototype.setBuyersGuide = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.events.DocumentAdded.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentAdded} returns this
 */
proto.events.DocumentAdded.prototype.clearBuyersGuide = function() {
  return this.setBuyersGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentAdded.prototype.hasBuyersGuide = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.events.DocumentDeleted.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.events.DocumentDeleted.DoctypeCase = {
  DOCTYPE_NOT_SET: 0,
  INSPECTION: 1,
  REGISTRATION: 2,
  WARRANTY: 3,
  TITLE: 4,
  MAINTENANCE: 5,
  REG262: 6,
  REG51: 7,
  REG553: 8,
  PURCHASE_ORDER: 9,
  USED_VEHICLE_HISTORY_DISCLOSURE: 10,
  BUYERS_GUIDE: 11
};

/**
 * @return {proto.events.DocumentDeleted.DoctypeCase}
 */
proto.events.DocumentDeleted.prototype.getDoctypeCase = function() {
  return /** @type {proto.events.DocumentDeleted.DoctypeCase} */(jspb.Message.computeOneofCase(this, proto.events.DocumentDeleted.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.DocumentDeleted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.DocumentDeleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.DocumentDeleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DocumentDeleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspection: (f = msg.getInspection()) && vehicle_pb.Inspection.toObject(includeInstance, f),
    registration: (f = msg.getRegistration()) && vehicle_pb.Registration.toObject(includeInstance, f),
    warranty: (f = msg.getWarranty()) && vehicle_pb.Warranty.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && vehicle_pb.Title.toObject(includeInstance, f),
    maintenance: (f = msg.getMaintenance()) && vehicle_pb.Maintenance.toObject(includeInstance, f),
    reg262: (f = msg.getReg262()) && vehicle_pb.Reg262.toObject(includeInstance, f),
    reg51: (f = msg.getReg51()) && vehicle_pb.Reg51.toObject(includeInstance, f),
    reg553: (f = msg.getReg553()) && vehicle_pb.Reg553.toObject(includeInstance, f),
    purchaseOrder: (f = msg.getPurchaseOrder()) && vehicle_pb.PurchaseOrder.toObject(includeInstance, f),
    usedVehicleHistoryDisclosure: (f = msg.getUsedVehicleHistoryDisclosure()) && vehicle_pb.UsedVehicleHistoryDisclosure.toObject(includeInstance, f),
    buyersGuide: (f = msg.getBuyersGuide()) && vehicle_pb.BuyersGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.DocumentDeleted}
 */
proto.events.DocumentDeleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.DocumentDeleted;
  return proto.events.DocumentDeleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.DocumentDeleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.DocumentDeleted}
 */
proto.events.DocumentDeleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Inspection;
      reader.readMessage(value,vehicle_pb.Inspection.deserializeBinaryFromReader);
      msg.setInspection(value);
      break;
    case 2:
      var value = new vehicle_pb.Registration;
      reader.readMessage(value,vehicle_pb.Registration.deserializeBinaryFromReader);
      msg.setRegistration(value);
      break;
    case 3:
      var value = new vehicle_pb.Warranty;
      reader.readMessage(value,vehicle_pb.Warranty.deserializeBinaryFromReader);
      msg.setWarranty(value);
      break;
    case 4:
      var value = new vehicle_pb.Title;
      reader.readMessage(value,vehicle_pb.Title.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 5:
      var value = new vehicle_pb.Maintenance;
      reader.readMessage(value,vehicle_pb.Maintenance.deserializeBinaryFromReader);
      msg.setMaintenance(value);
      break;
    case 6:
      var value = new vehicle_pb.Reg262;
      reader.readMessage(value,vehicle_pb.Reg262.deserializeBinaryFromReader);
      msg.setReg262(value);
      break;
    case 7:
      var value = new vehicle_pb.Reg51;
      reader.readMessage(value,vehicle_pb.Reg51.deserializeBinaryFromReader);
      msg.setReg51(value);
      break;
    case 8:
      var value = new vehicle_pb.Reg553;
      reader.readMessage(value,vehicle_pb.Reg553.deserializeBinaryFromReader);
      msg.setReg553(value);
      break;
    case 9:
      var value = new vehicle_pb.PurchaseOrder;
      reader.readMessage(value,vehicle_pb.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchaseOrder(value);
      break;
    case 10:
      var value = new vehicle_pb.UsedVehicleHistoryDisclosure;
      reader.readMessage(value,vehicle_pb.UsedVehicleHistoryDisclosure.deserializeBinaryFromReader);
      msg.setUsedVehicleHistoryDisclosure(value);
      break;
    case 11:
      var value = new vehicle_pb.BuyersGuide;
      reader.readMessage(value,vehicle_pb.BuyersGuide.deserializeBinaryFromReader);
      msg.setBuyersGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.DocumentDeleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.DocumentDeleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.DocumentDeleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.DocumentDeleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Inspection.serializeBinaryToWriter
    );
  }
  f = message.getRegistration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Registration.serializeBinaryToWriter
    );
  }
  f = message.getWarranty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vehicle_pb.Warranty.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vehicle_pb.Title.serializeBinaryToWriter
    );
  }
  f = message.getMaintenance();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vehicle_pb.Maintenance.serializeBinaryToWriter
    );
  }
  f = message.getReg262();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vehicle_pb.Reg262.serializeBinaryToWriter
    );
  }
  f = message.getReg51();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vehicle_pb.Reg51.serializeBinaryToWriter
    );
  }
  f = message.getReg553();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vehicle_pb.Reg553.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrder();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vehicle_pb.PurchaseOrder.serializeBinaryToWriter
    );
  }
  f = message.getUsedVehicleHistoryDisclosure();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vehicle_pb.UsedVehicleHistoryDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getBuyersGuide();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vehicle_pb.BuyersGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Inspection inspection = 1;
 * @return {?proto.vehicle.Inspection}
 */
proto.events.DocumentDeleted.prototype.getInspection = function() {
  return /** @type{?proto.vehicle.Inspection} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Inspection, 1));
};


/**
 * @param {?proto.vehicle.Inspection|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setInspection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearInspection = function() {
  return this.setInspection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasInspection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional vehicle.Registration registration = 2;
 * @return {?proto.vehicle.Registration}
 */
proto.events.DocumentDeleted.prototype.getRegistration = function() {
  return /** @type{?proto.vehicle.Registration} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Registration, 2));
};


/**
 * @param {?proto.vehicle.Registration|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setRegistration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearRegistration = function() {
  return this.setRegistration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasRegistration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional vehicle.Warranty warranty = 3;
 * @return {?proto.vehicle.Warranty}
 */
proto.events.DocumentDeleted.prototype.getWarranty = function() {
  return /** @type{?proto.vehicle.Warranty} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Warranty, 3));
};


/**
 * @param {?proto.vehicle.Warranty|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setWarranty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearWarranty = function() {
  return this.setWarranty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasWarranty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional vehicle.Title title = 4;
 * @return {?proto.vehicle.Title}
 */
proto.events.DocumentDeleted.prototype.getTitle = function() {
  return /** @type{?proto.vehicle.Title} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Title, 4));
};


/**
 * @param {?proto.vehicle.Title|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setTitle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional vehicle.Maintenance maintenance = 5;
 * @return {?proto.vehicle.Maintenance}
 */
proto.events.DocumentDeleted.prototype.getMaintenance = function() {
  return /** @type{?proto.vehicle.Maintenance} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Maintenance, 5));
};


/**
 * @param {?proto.vehicle.Maintenance|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setMaintenance = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearMaintenance = function() {
  return this.setMaintenance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasMaintenance = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional vehicle.Reg262 reg262 = 6;
 * @return {?proto.vehicle.Reg262}
 */
proto.events.DocumentDeleted.prototype.getReg262 = function() {
  return /** @type{?proto.vehicle.Reg262} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg262, 6));
};


/**
 * @param {?proto.vehicle.Reg262|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setReg262 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearReg262 = function() {
  return this.setReg262(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasReg262 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional vehicle.Reg51 reg51 = 7;
 * @return {?proto.vehicle.Reg51}
 */
proto.events.DocumentDeleted.prototype.getReg51 = function() {
  return /** @type{?proto.vehicle.Reg51} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg51, 7));
};


/**
 * @param {?proto.vehicle.Reg51|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setReg51 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearReg51 = function() {
  return this.setReg51(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasReg51 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional vehicle.Reg553 reg553 = 8;
 * @return {?proto.vehicle.Reg553}
 */
proto.events.DocumentDeleted.prototype.getReg553 = function() {
  return /** @type{?proto.vehicle.Reg553} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Reg553, 8));
};


/**
 * @param {?proto.vehicle.Reg553|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setReg553 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearReg553 = function() {
  return this.setReg553(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasReg553 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional vehicle.PurchaseOrder purchase_order = 9;
 * @return {?proto.vehicle.PurchaseOrder}
 */
proto.events.DocumentDeleted.prototype.getPurchaseOrder = function() {
  return /** @type{?proto.vehicle.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.PurchaseOrder, 9));
};


/**
 * @param {?proto.vehicle.PurchaseOrder|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setPurchaseOrder = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearPurchaseOrder = function() {
  return this.setPurchaseOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasPurchaseOrder = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional vehicle.UsedVehicleHistoryDisclosure used_vehicle_history_disclosure = 10;
 * @return {?proto.vehicle.UsedVehicleHistoryDisclosure}
 */
proto.events.DocumentDeleted.prototype.getUsedVehicleHistoryDisclosure = function() {
  return /** @type{?proto.vehicle.UsedVehicleHistoryDisclosure} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.UsedVehicleHistoryDisclosure, 10));
};


/**
 * @param {?proto.vehicle.UsedVehicleHistoryDisclosure|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setUsedVehicleHistoryDisclosure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearUsedVehicleHistoryDisclosure = function() {
  return this.setUsedVehicleHistoryDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasUsedVehicleHistoryDisclosure = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional vehicle.BuyersGuide buyers_guide = 11;
 * @return {?proto.vehicle.BuyersGuide}
 */
proto.events.DocumentDeleted.prototype.getBuyersGuide = function() {
  return /** @type{?proto.vehicle.BuyersGuide} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.BuyersGuide, 11));
};


/**
 * @param {?proto.vehicle.BuyersGuide|undefined} value
 * @return {!proto.events.DocumentDeleted} returns this
*/
proto.events.DocumentDeleted.prototype.setBuyersGuide = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.events.DocumentDeleted.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.DocumentDeleted} returns this
 */
proto.events.DocumentDeleted.prototype.clearBuyersGuide = function() {
  return this.setBuyersGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.DocumentDeleted.prototype.hasBuyersGuide = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OfferSubmitted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OfferSubmitted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OfferSubmitted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OfferSubmitted.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OfferSubmitted}
 */
proto.events.OfferSubmitted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OfferSubmitted;
  return proto.events.OfferSubmitted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OfferSubmitted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OfferSubmitted}
 */
proto.events.OfferSubmitted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OfferSubmitted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OfferSubmitted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OfferSubmitted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OfferSubmitted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.events.OfferSubmitted.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferSubmitted} returns this
 */
proto.events.OfferSubmitted.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.OfferSubmitted.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferSubmitted} returns this
 */
proto.events.OfferSubmitted.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 4;
 * @return {string}
 */
proto.events.OfferSubmitted.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferSubmitted} returns this
 */
proto.events.OfferSubmitted.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 amount = 5;
 * @return {number}
 */
proto.events.OfferSubmitted.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.OfferSubmitted} returns this
 */
proto.events.OfferSubmitted.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SubscriptionContractAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SubscriptionContractAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SubscriptionContractAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionContractAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractUri: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contractStartMileage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SubscriptionContractAdded}
 */
proto.events.SubscriptionContractAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SubscriptionContractAdded;
  return proto.events.SubscriptionContractAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SubscriptionContractAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SubscriptionContractAdded}
 */
proto.events.SubscriptionContractAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractUri(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContractStartMileage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SubscriptionContractAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SubscriptionContractAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SubscriptionContractAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionContractAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContractUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContractStartMileage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.SubscriptionContractAdded.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionContractAdded} returns this
 */
proto.events.SubscriptionContractAdded.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.events.SubscriptionContractAdded.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionContractAdded} returns this
 */
proto.events.SubscriptionContractAdded.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contract_uri = 3;
 * @return {string}
 */
proto.events.SubscriptionContractAdded.prototype.getContractUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionContractAdded} returns this
 */
proto.events.SubscriptionContractAdded.prototype.setContractUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 contract_start_mileage = 4;
 * @return {number}
 */
proto.events.SubscriptionContractAdded.prototype.getContractStartMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.SubscriptionContractAdded} returns this
 */
proto.events.SubscriptionContractAdded.prototype.setContractStartMileage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleInspectionStarted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleInspectionStarted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleInspectionStarted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionStarted.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inspectionStarted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    vin: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleInspectionStarted}
 */
proto.events.VehicleInspectionStarted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleInspectionStarted;
  return proto.events.VehicleInspectionStarted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleInspectionStarted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleInspectionStarted}
 */
proto.events.VehicleInspectionStarted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInspectionStarted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleInspectionStarted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleInspectionStarted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleInspectionStarted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionStarted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInspectionStarted();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.VehicleInspectionStarted.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionStarted} returns this
 */
proto.events.VehicleInspectionStarted.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.VehicleInspectionStarted.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionStarted} returns this
 */
proto.events.VehicleInspectionStarted.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 inspection_started = 3;
 * @return {number}
 */
proto.events.VehicleInspectionStarted.prototype.getInspectionStarted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.VehicleInspectionStarted} returns this
 */
proto.events.VehicleInspectionStarted.prototype.setInspectionStarted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string vin = 4;
 * @return {string}
 */
proto.events.VehicleInspectionStarted.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionStarted} returns this
 */
proto.events.VehicleInspectionStarted.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleInspectionPhotoUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleInspectionPhotoUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleInspectionPhotoUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionPhotoUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleInspectionPhotoUrl}
 */
proto.events.VehicleInspectionPhotoUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleInspectionPhotoUrl;
  return proto.events.VehicleInspectionPhotoUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleInspectionPhotoUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleInspectionPhotoUrl}
 */
proto.events.VehicleInspectionPhotoUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleInspectionPhotoUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleInspectionPhotoUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleInspectionPhotoUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionPhotoUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.events.VehicleInspectionPhotoUrl.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionPhotoUrl} returns this
 */
proto.events.VehicleInspectionPhotoUrl.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.events.VehicleInspectionPhotoUrl.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionPhotoUrl} returns this
 */
proto.events.VehicleInspectionPhotoUrl.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.VehicleInspectionCompleted.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleInspectionCompleted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleInspectionCompleted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleInspectionCompleted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionCompleted.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    photoUrlsList: jspb.Message.toObjectList(msg.getPhotoUrlsList(),
    proto.events.VehicleInspectionPhotoUrl.toObject, includeInstance),
    reportPdfUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rawReport: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    inspectionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    results: (f = msg.getResults()) && inspection_pb.InspectionResults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleInspectionCompleted}
 */
proto.events.VehicleInspectionCompleted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleInspectionCompleted;
  return proto.events.VehicleInspectionCompleted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleInspectionCompleted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleInspectionCompleted}
 */
proto.events.VehicleInspectionCompleted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = new proto.events.VehicleInspectionPhotoUrl;
      reader.readMessage(value,proto.events.VehicleInspectionPhotoUrl.deserializeBinaryFromReader);
      msg.addPhotoUrls(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportPdfUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawReport(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInspectionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVin(value);
      break;
    case 10:
      var value = new inspection_pb.InspectionResults;
      reader.readMessage(value,inspection_pb.InspectionResults.deserializeBinaryFromReader);
      msg.setResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleInspectionCompleted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleInspectionCompleted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleInspectionCompleted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleInspectionCompleted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhotoUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.events.VehicleInspectionPhotoUrl.serializeBinaryToWriter
    );
  }
  f = message.getReportPdfUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRawReport();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInspectionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getResults();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      inspection_pb.InspectionResults.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated VehicleInspectionPhotoUrl photo_urls = 3;
 * @return {!Array<!proto.events.VehicleInspectionPhotoUrl>}
 */
proto.events.VehicleInspectionCompleted.prototype.getPhotoUrlsList = function() {
  return /** @type{!Array<!proto.events.VehicleInspectionPhotoUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.events.VehicleInspectionPhotoUrl, 3));
};


/**
 * @param {!Array<!proto.events.VehicleInspectionPhotoUrl>} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
*/
proto.events.VehicleInspectionCompleted.prototype.setPhotoUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.events.VehicleInspectionPhotoUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.events.VehicleInspectionPhotoUrl}
 */
proto.events.VehicleInspectionCompleted.prototype.addPhotoUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.events.VehicleInspectionPhotoUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.clearPhotoUrlsList = function() {
  return this.setPhotoUrlsList([]);
};


/**
 * optional string report_pdf_url = 4;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getReportPdfUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setReportPdfUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string raw_report = 5;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getRawReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setRawReport = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subscription_id = 6;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dealer_id = 7;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string inspection_id = 8;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getInspectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setInspectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vin = 9;
 * @return {string}
 */
proto.events.VehicleInspectionCompleted.prototype.getVin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.setVin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional inspection.InspectionResults results = 10;
 * @return {?proto.inspection.InspectionResults}
 */
proto.events.VehicleInspectionCompleted.prototype.getResults = function() {
  return /** @type{?proto.inspection.InspectionResults} */ (
    jspb.Message.getWrapperField(this, inspection_pb.InspectionResults, 10));
};


/**
 * @param {?proto.inspection.InspectionResults|undefined} value
 * @return {!proto.events.VehicleInspectionCompleted} returns this
*/
proto.events.VehicleInspectionCompleted.prototype.setResults = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.VehicleInspectionCompleted} returns this
 */
proto.events.VehicleInspectionCompleted.prototype.clearResults = function() {
  return this.setResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.VehicleInspectionCompleted.prototype.hasResults = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.OfferResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.events.OfferResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.OfferResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OfferResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    response: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    reason: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.OfferResponse}
 */
proto.events.OfferResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.OfferResponse;
  return proto.events.OfferResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.OfferResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.OfferResponse}
 */
proto.events.OfferResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResponse(value);
      break;
    case 5:
      var value = /** @type {!proto.events.OfferReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.OfferResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.OfferResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.OfferResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.OfferResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResponse();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string dealer_id = 1;
 * @return {string}
 */
proto.events.OfferResponse.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferResponse} returns this
 */
proto.events.OfferResponse.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.OfferResponse.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferResponse} returns this
 */
proto.events.OfferResponse.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.events.OfferResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.OfferResponse} returns this
 */
proto.events.OfferResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool response = 4;
 * @return {boolean}
 */
proto.events.OfferResponse.prototype.getResponse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.events.OfferResponse} returns this
 */
proto.events.OfferResponse.prototype.setResponse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional OfferReason reason = 5;
 * @return {!proto.events.OfferReason}
 */
proto.events.OfferResponse.prototype.getReason = function() {
  return /** @type {!proto.events.OfferReason} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.events.OfferReason} value
 * @return {!proto.events.OfferResponse} returns this
 */
proto.events.OfferResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleTosAccepted.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleTosAccepted.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleTosAccepted} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleTosAccepted.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dealerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleTosAccepted}
 */
proto.events.VehicleTosAccepted.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleTosAccepted;
  return proto.events.VehicleTosAccepted.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleTosAccepted} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleTosAccepted}
 */
proto.events.VehicleTosAccepted.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleTosAccepted.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleTosAccepted.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleTosAccepted} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleTosAccepted.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.events.VehicleTosAccepted.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleTosAccepted} returns this
 */
proto.events.VehicleTosAccepted.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.events.VehicleTosAccepted.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.VehicleTosAccepted} returns this
 */
proto.events.VehicleTosAccepted.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dealer_id = 3;
 * @return {string}
 */
proto.events.VehicleTosAccepted.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleTosAccepted} returns this
 */
proto.events.VehicleTosAccepted.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.events.VehicleTosAccepted.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleTosAccepted} returns this
 */
proto.events.VehicleTosAccepted.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ReloadDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ReloadDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ReloadDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ReloadDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, 0),
    domainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ReloadDomain}
 */
proto.events.ReloadDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ReloadDomain;
  return proto.events.ReloadDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ReloadDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ReloadDomain}
 */
proto.events.ReloadDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.events.Domain} */ (reader.readEnum());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ReloadDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ReloadDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ReloadDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ReloadDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Domain domain = 1;
 * @return {!proto.events.Domain}
 */
proto.events.ReloadDomain.prototype.getDomain = function() {
  return /** @type {!proto.events.Domain} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.events.Domain} value
 * @return {!proto.events.ReloadDomain} returns this
 */
proto.events.ReloadDomain.prototype.setDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string domain_id = 2;
 * @return {string}
 */
proto.events.ReloadDomain.prototype.getDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.ReloadDomain} returns this
 */
proto.events.ReloadDomain.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.events.CancelPaymentTransaction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CancelPaymentTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CancelPaymentTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CancelPaymentTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CancelPaymentTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    domain_pb.Transaction.toObject, includeInstance),
    refundAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CancelPaymentTransaction}
 */
proto.events.CancelPaymentTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CancelPaymentTransaction;
  return proto.events.CancelPaymentTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CancelPaymentTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CancelPaymentTransaction}
 */
proto.events.CancelPaymentTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new domain_pb.Transaction;
      reader.readMessage(value,domain_pb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefundAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CancelPaymentTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CancelPaymentTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CancelPaymentTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CancelPaymentTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getRefundAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CancelPaymentTransaction.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated domain.Transaction transactions = 2;
 * @return {!Array<!proto.domain.Transaction>}
 */
proto.events.CancelPaymentTransaction.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.domain.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Transaction, 2));
};


/**
 * @param {!Array<!proto.domain.Transaction>} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
*/
proto.events.CancelPaymentTransaction.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Transaction}
 */
proto.events.CancelPaymentTransaction.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional int32 refund_amount = 3;
 * @return {number}
 */
proto.events.CancelPaymentTransaction.prototype.getRefundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.setRefundAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string account_number = 4;
 * @return {string}
 */
proto.events.CancelPaymentTransaction.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string invoice_number = 5;
 * @return {string}
 */
proto.events.CancelPaymentTransaction.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string subscription_id = 6;
 * @return {string}
 */
proto.events.CancelPaymentTransaction.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CancelPaymentTransaction} returns this
 */
proto.events.CancelPaymentTransaction.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SmsReceived.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SmsReceived.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SmsReceived} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SmsReceived.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderDomain: jspb.Message.getFieldWithDefault(msg, 1, 0),
    senderDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderPhone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SmsReceived}
 */
proto.events.SmsReceived.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SmsReceived;
  return proto.events.SmsReceived.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SmsReceived} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SmsReceived}
 */
proto.events.SmsReceived.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.events.Domain} */ (reader.readEnum());
      msg.setSenderDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SmsReceived.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SmsReceived.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SmsReceived} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SmsReceived.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderDomain();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSenderDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Domain sender_domain = 1;
 * @return {!proto.events.Domain}
 */
proto.events.SmsReceived.prototype.getSenderDomain = function() {
  return /** @type {!proto.events.Domain} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.events.Domain} value
 * @return {!proto.events.SmsReceived} returns this
 */
proto.events.SmsReceived.prototype.setSenderDomain = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string sender_domain_id = 2;
 * @return {string}
 */
proto.events.SmsReceived.prototype.getSenderDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SmsReceived} returns this
 */
proto.events.SmsReceived.prototype.setSenderDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_phone = 3;
 * @return {string}
 */
proto.events.SmsReceived.prototype.getSenderPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SmsReceived} returns this
 */
proto.events.SmsReceived.prototype.setSenderPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.events.SmsReceived.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SmsReceived} returns this
 */
proto.events.SmsReceived.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerScored.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerScored.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerScored} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerScored.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: (f = msg.getScore()) && domain_pb.Score.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerScored}
 */
proto.events.CustomerScored.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerScored;
  return proto.events.CustomerScored.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerScored} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerScored}
 */
proto.events.CustomerScored.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new domain_pb.Score;
      reader.readMessage(value,domain_pb.Score.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerScored.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerScored.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerScored} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerScored.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      domain_pb.Score.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerScored.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerScored} returns this
 */
proto.events.CustomerScored.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.Score score = 2;
 * @return {?proto.domain.Score}
 */
proto.events.CustomerScored.prototype.getScore = function() {
  return /** @type{?proto.domain.Score} */ (
    jspb.Message.getWrapperField(this, domain_pb.Score, 2));
};


/**
 * @param {?proto.domain.Score|undefined} value
 * @return {!proto.events.CustomerScored} returns this
*/
proto.events.CustomerScored.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CustomerScored} returns this
 */
proto.events.CustomerScored.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CustomerScored.prototype.hasScore = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerQualified.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerQualified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerQualified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerQualified.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: (f = msg.getScore()) && domain_pb.Score.toObject(includeInstance, f),
    origin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerQualified}
 */
proto.events.CustomerQualified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerQualified;
  return proto.events.CustomerQualified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerQualified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerQualified}
 */
proto.events.CustomerQualified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.QualificationTier} */ (reader.readEnum());
      msg.setTier(value);
      break;
    case 3:
      var value = new domain_pb.Score;
      reader.readMessage(value,domain_pb.Score.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {!proto.domain.QualificationOrigin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 5:
      var value = /** @type {!proto.customer_service.QualificationState} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerQualified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerQualified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerQualified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerQualified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Score.serializeBinaryToWriter
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerQualified.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerQualified} returns this
 */
proto.events.CustomerQualified.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.QualificationTier tier = 2;
 * @return {!proto.domain.QualificationTier}
 */
proto.events.CustomerQualified.prototype.getTier = function() {
  return /** @type {!proto.domain.QualificationTier} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.QualificationTier} value
 * @return {!proto.events.CustomerQualified} returns this
 */
proto.events.CustomerQualified.prototype.setTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional domain.Score score = 3;
 * @return {?proto.domain.Score}
 */
proto.events.CustomerQualified.prototype.getScore = function() {
  return /** @type{?proto.domain.Score} */ (
    jspb.Message.getWrapperField(this, domain_pb.Score, 3));
};


/**
 * @param {?proto.domain.Score|undefined} value
 * @return {!proto.events.CustomerQualified} returns this
*/
proto.events.CustomerQualified.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CustomerQualified} returns this
 */
proto.events.CustomerQualified.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CustomerQualified.prototype.hasScore = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional domain.QualificationOrigin origin = 4;
 * @return {!proto.domain.QualificationOrigin}
 */
proto.events.CustomerQualified.prototype.getOrigin = function() {
  return /** @type {!proto.domain.QualificationOrigin} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.domain.QualificationOrigin} value
 * @return {!proto.events.CustomerQualified} returns this
 */
proto.events.CustomerQualified.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional customer_service.QualificationState state = 5;
 * @return {!proto.customer_service.QualificationState}
 */
proto.events.CustomerQualified.prototype.getState = function() {
  return /** @type {!proto.customer_service.QualificationState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.customer_service.QualificationState} value
 * @return {!proto.events.CustomerQualified} returns this
 */
proto.events.CustomerQualified.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerScoreDisqualified.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerScoreDisqualified.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerScoreDisqualified} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerScoreDisqualified.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    score: (f = msg.getScore()) && domain_pb.Score.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerScoreDisqualified}
 */
proto.events.CustomerScoreDisqualified.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerScoreDisqualified;
  return proto.events.CustomerScoreDisqualified.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerScoreDisqualified} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerScoreDisqualified}
 */
proto.events.CustomerScoreDisqualified.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.domain.DisqualificationReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 3:
      var value = new domain_pb.Score;
      reader.readMessage(value,domain_pb.Score.deserializeBinaryFromReader);
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerScoreDisqualified.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerScoreDisqualified.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerScoreDisqualified} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerScoreDisqualified.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getScore();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_pb.Score.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerScoreDisqualified.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerScoreDisqualified} returns this
 */
proto.events.CustomerScoreDisqualified.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional domain.DisqualificationReason reason = 2;
 * @return {!proto.domain.DisqualificationReason}
 */
proto.events.CustomerScoreDisqualified.prototype.getReason = function() {
  return /** @type {!proto.domain.DisqualificationReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.domain.DisqualificationReason} value
 * @return {!proto.events.CustomerScoreDisqualified} returns this
 */
proto.events.CustomerScoreDisqualified.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional domain.Score score = 3;
 * @return {?proto.domain.Score}
 */
proto.events.CustomerScoreDisqualified.prototype.getScore = function() {
  return /** @type{?proto.domain.Score} */ (
    jspb.Message.getWrapperField(this, domain_pb.Score, 3));
};


/**
 * @param {?proto.domain.Score|undefined} value
 * @return {!proto.events.CustomerScoreDisqualified} returns this
*/
proto.events.CustomerScoreDisqualified.prototype.setScore = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CustomerScoreDisqualified} returns this
 */
proto.events.CustomerScoreDisqualified.prototype.clearScore = function() {
  return this.setScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CustomerScoreDisqualified.prototype.hasScore = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.CustomerDataEnriched.prototype.toObject = function(opt_includeInstance) {
  return proto.events.CustomerDataEnriched.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.CustomerDataEnriched} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerDataEnriched.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: (f = msg.getAddress()) && domain_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.CustomerDataEnriched}
 */
proto.events.CustomerDataEnriched.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.CustomerDataEnriched;
  return proto.events.CustomerDataEnriched.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.CustomerDataEnriched} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.CustomerDataEnriched}
 */
proto.events.CustomerDataEnriched.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = new domain_pb.Address;
      reader.readMessage(value,domain_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.CustomerDataEnriched.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.CustomerDataEnriched.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.CustomerDataEnriched} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.CustomerDataEnriched.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      domain_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.events.CustomerDataEnriched.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.events.CustomerDataEnriched.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.events.CustomerDataEnriched.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.events.CustomerDataEnriched.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.events.CustomerDataEnriched.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional domain.Address address = 6;
 * @return {?proto.domain.Address}
 */
proto.events.CustomerDataEnriched.prototype.getAddress = function() {
  return /** @type{?proto.domain.Address} */ (
    jspb.Message.getWrapperField(this, domain_pb.Address, 6));
};


/**
 * @param {?proto.domain.Address|undefined} value
 * @return {!proto.events.CustomerDataEnriched} returns this
*/
proto.events.CustomerDataEnriched.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.CustomerDataEnriched} returns this
 */
proto.events.CustomerDataEnriched.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.CustomerDataEnriched.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SyncIngestion.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SyncIngestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SyncIngestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SyncIngestion.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SyncIngestion}
 */
proto.events.SyncIngestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SyncIngestion;
  return proto.events.SyncIngestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SyncIngestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SyncIngestion}
 */
proto.events.SyncIngestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SyncIngestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SyncIngestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SyncIngestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SyncIngestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.ExtendInsurancePolicies.prototype.toObject = function(opt_includeInstance) {
  return proto.events.ExtendInsurancePolicies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.ExtendInsurancePolicies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ExtendInsurancePolicies.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.ExtendInsurancePolicies}
 */
proto.events.ExtendInsurancePolicies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.ExtendInsurancePolicies;
  return proto.events.ExtendInsurancePolicies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.ExtendInsurancePolicies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.ExtendInsurancePolicies}
 */
proto.events.ExtendInsurancePolicies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.ExtendInsurancePolicies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.ExtendInsurancePolicies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.ExtendInsurancePolicies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.ExtendInsurancePolicies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.MilesPurchased.prototype.toObject = function(opt_includeInstance) {
  return proto.events.MilesPurchased.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.MilesPurchased} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.MilesPurchased.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    miles: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purchaseAmount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.MilesPurchased}
 */
proto.events.MilesPurchased.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.MilesPurchased;
  return proto.events.MilesPurchased.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.MilesPurchased} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.MilesPurchased}
 */
proto.events.MilesPurchased.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMiles(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPurchaseAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.MilesPurchased.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.MilesPurchased.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.MilesPurchased} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.MilesPurchased.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiles();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurchaseAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.events.MilesPurchased.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.MilesPurchased} returns this
 */
proto.events.MilesPurchased.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 miles = 2;
 * @return {number}
 */
proto.events.MilesPurchased.prototype.getMiles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.MilesPurchased} returns this
 */
proto.events.MilesPurchased.prototype.setMiles = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.events.MilesPurchased.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.MilesPurchased} returns this
 */
proto.events.MilesPurchased.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 purchase_amount = 4;
 * @return {number}
 */
proto.events.MilesPurchased.prototype.getPurchaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.MilesPurchased} returns this
 */
proto.events.MilesPurchased.prototype.setPurchaseAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.VehicleChangeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.events.VehicleChangeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.VehicleChangeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleChangeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odometer: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.VehicleChangeDetails}
 */
proto.events.VehicleChangeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.VehicleChangeDetails;
  return proto.events.VehicleChangeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.VehicleChangeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.VehicleChangeDetails}
 */
proto.events.VehicleChangeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdometer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.VehicleChangeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.VehicleChangeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.VehicleChangeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.VehicleChangeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdometer();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.events.VehicleChangeDetails.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.VehicleChangeDetails} returns this
 */
proto.events.VehicleChangeDetails.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 odometer = 2;
 * @return {number}
 */
proto.events.VehicleChangeDetails.prototype.getOdometer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.VehicleChangeDetails} returns this
 */
proto.events.VehicleChangeDetails.prototype.setOdometer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SubscriptionVehicleChange.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SubscriptionVehicleChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SubscriptionVehicleChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionVehicleChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_return: (f = msg.getReturn()) && proto.events.VehicleChangeDetails.toObject(includeInstance, f),
    pickup: (f = msg.getPickup()) && proto.events.VehicleChangeDetails.toObject(includeInstance, f),
    timeOfChange: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SubscriptionVehicleChange}
 */
proto.events.SubscriptionVehicleChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SubscriptionVehicleChange;
  return proto.events.SubscriptionVehicleChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SubscriptionVehicleChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SubscriptionVehicleChange}
 */
proto.events.SubscriptionVehicleChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = new proto.events.VehicleChangeDetails;
      reader.readMessage(value,proto.events.VehicleChangeDetails.deserializeBinaryFromReader);
      msg.setReturn(value);
      break;
    case 3:
      var value = new proto.events.VehicleChangeDetails;
      reader.readMessage(value,proto.events.VehicleChangeDetails.deserializeBinaryFromReader);
      msg.setPickup(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeOfChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SubscriptionVehicleChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SubscriptionVehicleChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SubscriptionVehicleChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionVehicleChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReturn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.events.VehicleChangeDetails.serializeBinaryToWriter
    );
  }
  f = message.getPickup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.events.VehicleChangeDetails.serializeBinaryToWriter
    );
  }
  f = message.getTimeOfChange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.events.SubscriptionVehicleChange.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionVehicleChange} returns this
 */
proto.events.SubscriptionVehicleChange.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleChangeDetails return = 2;
 * @return {?proto.events.VehicleChangeDetails}
 */
proto.events.SubscriptionVehicleChange.prototype.getReturn = function() {
  return /** @type{?proto.events.VehicleChangeDetails} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleChangeDetails, 2));
};


/**
 * @param {?proto.events.VehicleChangeDetails|undefined} value
 * @return {!proto.events.SubscriptionVehicleChange} returns this
*/
proto.events.SubscriptionVehicleChange.prototype.setReturn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.SubscriptionVehicleChange} returns this
 */
proto.events.SubscriptionVehicleChange.prototype.clearReturn = function() {
  return this.setReturn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.SubscriptionVehicleChange.prototype.hasReturn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional VehicleChangeDetails pickup = 3;
 * @return {?proto.events.VehicleChangeDetails}
 */
proto.events.SubscriptionVehicleChange.prototype.getPickup = function() {
  return /** @type{?proto.events.VehicleChangeDetails} */ (
    jspb.Message.getWrapperField(this, proto.events.VehicleChangeDetails, 3));
};


/**
 * @param {?proto.events.VehicleChangeDetails|undefined} value
 * @return {!proto.events.SubscriptionVehicleChange} returns this
*/
proto.events.SubscriptionVehicleChange.prototype.setPickup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.events.SubscriptionVehicleChange} returns this
 */
proto.events.SubscriptionVehicleChange.prototype.clearPickup = function() {
  return this.setPickup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.events.SubscriptionVehicleChange.prototype.hasPickup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string time_of_change = 4;
 * @return {string}
 */
proto.events.SubscriptionVehicleChange.prototype.getTimeOfChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionVehicleChange} returns this
 */
proto.events.SubscriptionVehicleChange.prototype.setTimeOfChange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.events.SubscriptionVehicleReturned.prototype.toObject = function(opt_includeInstance) {
  return proto.events.SubscriptionVehicleReturned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.events.SubscriptionVehicleReturned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionVehicleReturned.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    excessMileageAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    missingAssetsAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    initialDamagesAmount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.events.SubscriptionVehicleReturned}
 */
proto.events.SubscriptionVehicleReturned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.events.SubscriptionVehicleReturned;
  return proto.events.SubscriptionVehicleReturned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.events.SubscriptionVehicleReturned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.events.SubscriptionVehicleReturned}
 */
proto.events.SubscriptionVehicleReturned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcessMileageAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMissingAssetsAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInitialDamagesAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.events.SubscriptionVehicleReturned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.events.SubscriptionVehicleReturned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.events.SubscriptionVehicleReturned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.events.SubscriptionVehicleReturned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExcessMileageAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMissingAssetsAmount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getInitialDamagesAmount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.events.SubscriptionVehicleReturned.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionVehicleReturned} returns this
 */
proto.events.SubscriptionVehicleReturned.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.events.SubscriptionVehicleReturned.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.events.SubscriptionVehicleReturned} returns this
 */
proto.events.SubscriptionVehicleReturned.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 excess_mileage_amount = 3;
 * @return {number}
 */
proto.events.SubscriptionVehicleReturned.prototype.getExcessMileageAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.SubscriptionVehicleReturned} returns this
 */
proto.events.SubscriptionVehicleReturned.prototype.setExcessMileageAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 missing_assets_amount = 4;
 * @return {number}
 */
proto.events.SubscriptionVehicleReturned.prototype.getMissingAssetsAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.SubscriptionVehicleReturned} returns this
 */
proto.events.SubscriptionVehicleReturned.prototype.setMissingAssetsAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 initial_damages_amount = 5;
 * @return {number}
 */
proto.events.SubscriptionVehicleReturned.prototype.getInitialDamagesAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.events.SubscriptionVehicleReturned} returns this
 */
proto.events.SubscriptionVehicleReturned.prototype.setInitialDamagesAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.events.EventCategory = {
  COMMAND_ACCEPTED: 0,
  EFFECT: 1
};

/**
 * @enum {number}
 */
proto.events.Domain = {
  SYSTEM: 0,
  VENDOR: 1,
  CUSTOMER: 2,
  SUBSCRIPTION: 3,
  FLEET: 4,
  INSURANCE: 5,
  DEALER: 6,
  NOTIFICATION: 7,
  RESERVATION: 8,
  PROGRAM: 9,
  PROMOTION: 10,
  APPOINTMENT: 11,
  DEALER_USER: 12,
  TELEMATICS: 13
};

/**
 * @enum {number}
 */
proto.events.AuthStatus = {
  AUTH_SUCCESS: 0,
  AUTH_FAILED: 1,
  AUTH_ERROR: 2
};

/**
 * @enum {number}
 */
proto.events.PaymentStatus = {
  PAYMENT_SUCCESS: 0,
  PAYMENT_FAILED: 1,
  PAYMENT_ERROR: 2
};

/**
 * @enum {number}
 */
proto.events.PaymentActivityType = {
  SECURITY_DEPOSIT_PAYMENT_TYPE: 0,
  START_PAYMENT_TYPE: 1,
  SUBSCRIPTION_PAYMENT_TYPE: 2,
  SECURITY_DEPOSIT_REFUNDED: 3,
  SECURITY_DEPOSIT_REFUND_UNSUCCESSFUL: 4,
  AUTHORIZATION_VOIDED: 5,
  WAITLIST_DEPOSIT_REFUNDED: 6,
  WAITLIST_DEPOSIT_PAYMENT_TYPE: 7,
  ADD_PAYMENT_METHOD_PAYMENT_TYPE: 8,
  AUTHORIZATION_PAYMENT_TYPE: 9,
  CHARGE_DISPUTE_OPENED: 10,
  CHARGE_DISPUTE_LOST: 11,
  CHARGE_DISPUTE_WON: 12,
  TRANSACTION_SETTLEMENT_DECLINED: 13,
  AUTHORIZATION_NOT_REQUIRED: 14,
  TRANSACTION_SETTLED: 15,
  SYSTEM_RESERVED_BLACK_HOLE: 100
};

/**
 * @enum {number}
 */
proto.events.OfferReason = {
  VEHICLE_AVAILABLE: 0,
  VEHICLE_UNAVAILABLE: 1,
  PRICE_ACCEPTED: 2,
  PRICE_REJECTED: 3
};

goog.object.extend(exports, proto.events);
