import { useQuery } from 'react-query'
import {
  getCustomerDocuments,
  getCustomerDocument,
} from 'global-apis/nexus-service-document'
import { useParams } from 'react-router-dom'

const useDocuments = () => {
  const { customerId } = useParams<{ customerId: string }>()

  const { data, error, isLoading } = useQuery(
    ['customerDocuments', customerId],
    () => getCustomerDocuments(customerId),
    {
      onError(err) {
        console.error('Error fetching documents: ', err)
      },
    }
  )

  const useDocumentUri = (documentId, customerId) => {
    return useQuery(
      ['downloadDocument', documentId],
      () => getCustomerDocument(customerId, documentId),
      {
        enabled: false,
      }
    )
  }
  const contracts = filterAndAddFileName(data?.contractsMap?.[0]) || []
  const pickupReceipts = filterAndAddFileName(data?.pickupReceiptMap?.[0]) || []
  const insuranceCards =
    filterAndAddFileName(data?.insuranceCardsMap?.[0]) || []
  const autoPayAuthorizations =
    filterAndAddFileName(data?.autoPayAuthorizationsMap?.[0]) || []

  return {
    contracts,
    pickupReceipts,
    insuranceCards,
    autoPayAuthorizations,
    error,
    isLoading,
    useDocumentUri,
  }
}

export default useDocuments

function extractFileNameFromUri(uri) {
  return uri.split('/').pop()
}

function filterAndAddFileName(inputArray) {
  const filteredObjects = inputArray?.filter(
    (item) => typeof item === 'object' && item !== null
  )

  return filteredObjects?.map((obj) => {
    return {
      ...obj,
      fileName: extractFileNameFromUri(obj.documentUri),
    }
  })
}
