import React, { Fragment } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import useDocuments from '../useCustomerDocuments'
import { formatDate } from '../../../utils/date-formatter'
import { getGridRowClass } from '../../../utils/getGridRowClass'

import Loading from 'shared/loading'
import toast from 'react-hot-toast'

const CustomerDocuments = () => {
  const {
    contracts,
    pickupReceipts,
    insuranceCards,
    autoPayAuthorizations,
    error,
    isLoading,
  } = useDocuments()

  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <div>Error on page: {error}</div>
  }

  return (
    <Grid container spacing={2} p={3} direction="row">
      <Grid item xs={12}>
        <DGrid
          columns={contractColumns}
          title={'Contracts'}
          data={contracts}
          loading={isLoading}
        />
        <DGrid
          columns={contractColumns}
          title={'Pickup Receipts'}
          data={pickupReceipts}
          loading={isLoading}
        />
        <DGrid
          columns={contractColumns}
          title={'Insurance Cards'}
          data={insuranceCards}
          loading={isLoading}
        />
        <DGrid
          columns={contractColumns}
          title={'AutoPay Authorizations'}
          data={autoPayAuthorizations}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerDocuments

const contractColumns: GridColumns = [
  {
    field: 'fileName',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    width: 150,
    renderCell: (params) => <>{formatDate(params.row.creationDate)}</>,
  },
  {
    field: 'subscriptionId',
    headerName: 'Subscription ID',
    width: 300,
  },
  {
    field: 'documentUri',
    headerName: 'Action',
    width: 150,
    renderCell: (params) => <DownloadButton documentId={params.row.id} />,
  },
]

const DGrid = ({ title, columns, data, ...rest }) => {
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h4">{title}</Typography>
      </Grid>

      <DataGrid
        autoHeight
        rowHeight={35}
        sx={{ pt: 2 }}
        columns={columns}
        getRowClassName={(params) =>
          getGridRowClass(params.indexRelativeToCurrentPage)
        }
        rows={data}
        {...rest}
      />
    </Fragment>
  )
}

const DownloadButton = ({ documentId }) => {
  const { useDocumentUri } = useDocuments()
  const { customerId } = useParams<{ customerId: string }>()
  const { refetch } = useDocumentUri(documentId, customerId)

  const onClick = async () => {
    const { data } = await refetch()
    if (data) {
      handleDownload(data)
    }
  }

  return <Button onClick={onClick}>Download</Button>
}

const handleDownload = ({ documentUri }) => {
  if (documentUri) {
    const link = document.createElement('a')
    link.href = documentUri
    link.download = documentUri.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    console.error('Document URI not found')
    toast.error('Document not found.')
  }
}
