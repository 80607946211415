import { Button, Grid, Typography, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import * as React from 'react'
import { QualificationTierMap } from '@nxcr-org/web-api-interface/lib/domain_pb'
import { AssignableVehicle, useAssignmentBlock } from './useAssignmentBlock'
import { Vehicle } from '@nxcr-org/web-api-interface/lib/vehicle_pb'
import { getIsQualified } from '../../useReservation'
import { TableSearch } from '../../../../../shared/TableSearch'
import { VinAssignmentModal } from './VinAssignmentModal'

interface AssignmentBlockProps {
  customerTier: QualificationTierMap[keyof QualificationTierMap]
  customerHasVehicle: boolean
  assignVehicle: (vin: string) => void
  reload: boolean
  reservationLoading: boolean
  assignedVehicle: Vehicle.AsObject
  unAssignVehicle: () => void
  reservationStatus: string
  make?: string
}

export const AssignmentBlock: React.FC<AssignmentBlockProps> = (props) => {
  const { make } = props
  const {
    vehicles,
    setPage,
    vehiclePage,
    isFetching,
    onColumnVisibilityModelChange,
    visibleAssignmentColumns,
    setVIN,
    vin,
    vinModal,
    setVinModal,
    defaultVinModalState,
  } = useAssignmentBlock({
    assignVehicle: props.assignVehicle,
    customerTier: props.customerTier,
    customerHasVehicle: props.customerHasVehicle,
    reload: props.reload,
    assignedVehicle: props.assignedVehicle,
    unAssignVehicle: props.unAssignVehicle,
    reservationStatus: props.reservationStatus,
    make,
  })

  const columns: GridColumns = [
    {
      field: 'color',
      headerName: 'Color',
      flex: 0.75,
    },
    {
      field: 'make',
      headerName: 'Make',
      flex: 0.5,
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 0.5,
    },
    {
      field: 'mileage',
      headerName: 'Odometer',
      flex: 0.5,
    },
    {
      field: 'trim',
      headerName: 'Trim',
      flex: 0.75,
      renderCell(props: GridRenderCellParams<any, AssignableVehicle>) {
        return (
          <Tooltip title={props.row.trim}>
            <span>{props.row.trim}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'dealerName',
      headerName: 'Dealership',
      flex: 1.2,
      renderCell(props: GridRenderCellParams<any, AssignableVehicle>) {
        return (
          <Tooltip title={props.row.dealerAddress}>
            <span>{props.row.dealerName}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'dealerAddress',
      headerName: 'Dealer Address',
      flex: 1.2,
      renderCell(props: GridRenderCellParams<any, AssignableVehicle>) {
        return (
          <Tooltip title={props.row.dealerAddress}>
            <span>{props.row.dealerAddress}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'acquired',
      headerName: 'Acquired',
      flex: 0.75,
    },
    {
      field: 'vin',
      headerName: 'VIN',
      flex: 1.2,
      renderCell(props: GridRenderCellParams<any, AssignableVehicle>) {
        return (
          <Tooltip title={props.row.vin}>
            <span>{props.row.vin}</span>
          </Tooltip>
        )
      },
    },
    {
      field: 'assignment',
      headerName: '',
      flex: 1,
      renderCell(props) {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.row.assignVehicle()}
            disabled={props.row.disabled}
          >
            {props.row.text}
          </Button>
        )
      },
    },
  ]

  return (
    <Grid item>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {getIsQualified(props.customerTier) && !props.assignedVehicle && (
            <Typography>
              Select a vehicle to assign to this customer.
            </Typography>
          )}
          {/* @ts-ignore :: Typescript is struggling with this comparison */}
          {!getIsQualified(props.customerTier) && (
            <Typography>
              Approve the customer before assigning a vehicle
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} justifyContent="flex-end">
          <TableSearch
            label="Search by VIN"
            search={vin}
            setQuery={(evt) => {
              setVIN(evt.target.value)
            }}
          />
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        columns={columns}
        rows={vehicles?.vehiclesList || []}
        rowCount={vehicles?.total}
        loading={props.reservationLoading || isFetching}
        pagination={true}
        rowsPerPageOptions={[5]}
        pageSize={5}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        columnVisibilityModel={visibleAssignmentColumns}
        disableColumnFilter
        paginationMode="server"
        page={vehiclePage}
        onPageChange={(newPage) => setPage(newPage)}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <div style={{ flex: 1 }} />
              </GridToolbarContainer>
            )
          },
        }}
      />
      <VinAssignmentModal
        open={vinModal.open}
        onClose={() => setVinModal(defaultVinModalState)}
        headerText={vinModal.headerText}
        handleYesClick={vinModal.handleYesClick}
      />
    </Grid>
  )
}
