// source: spre.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var domain_pb = require('./domain_pb.js');
goog.object.extend(proto, domain_pb);
var vehicle_pb = require('./vehicle_pb.js');
goog.object.extend(proto, vehicle_pb);
var subscription_pb = require('./subscription_pb.js');
goog.object.extend(proto, subscription_pb);
goog.exportSymbol('proto.spre.Algorithm', null, global);
goog.exportSymbol('proto.spre.Band', null, global);
goog.exportSymbol('proto.spre.CalculateCustomerVehiclePricingRequest', null, global);
goog.exportSymbol('proto.spre.CalculateCustomerVehiclePricingResponse', null, global);
goog.exportSymbol('proto.spre.CalculateVehiclePricingRequest', null, global);
goog.exportSymbol('proto.spre.CalculateVehiclePricingResponse', null, global);
goog.exportSymbol('proto.spre.Calculation', null, global);
goog.exportSymbol('proto.spre.CreateIdResponse', null, global);
goog.exportSymbol('proto.spre.CreateResponse', null, global);
goog.exportSymbol('proto.spre.CustomerRequest', null, global);
goog.exportSymbol('proto.spre.ExecuteFunctionRequest', null, global);
goog.exportSymbol('proto.spre.Expense', null, global);
goog.exportSymbol('proto.spre.Expense.Category', null, global);
goog.exportSymbol('proto.spre.Expense.Type', null, global);
goog.exportSymbol('proto.spre.HypotheticalRequest', null, global);
goog.exportSymbol('proto.spre.HypotheticalResponse', null, global);
goog.exportSymbol('proto.spre.Item', null, global);
goog.exportSymbol('proto.spre.ItemResponse', null, global);
goog.exportSymbol('proto.spre.ItemTypeQuery', null, global);
goog.exportSymbol('proto.spre.ListProductsRequest', null, global);
goog.exportSymbol('proto.spre.ListProductsResponse', null, global);
goog.exportSymbol('proto.spre.ListProgramPricingOptionsRequest', null, global);
goog.exportSymbol('proto.spre.ListProgramPricingOptionsResponse', null, global);
goog.exportSymbol('proto.spre.Lut', null, global);
goog.exportSymbol('proto.spre.Price', null, global);
goog.exportSymbol('proto.spre.Profile', null, global);
goog.exportSymbol('proto.spre.Quote', null, global);
goog.exportSymbol('proto.spre.QuoteRequest', null, global);
goog.exportSymbol('proto.spre.Report', null, global);
goog.exportSymbol('proto.spre.VariableMap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ItemTypeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.ItemTypeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ItemTypeQuery.displayName = 'proto.spre.ItemTypeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.ItemResponse.repeatedFields_, null);
};
goog.inherits(proto.spre.ItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ItemResponse.displayName = 'proto.spre.ItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Item.displayName = 'proto.spre.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CustomerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CustomerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CustomerRequest.displayName = 'proto.spre.CustomerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.QuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.QuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.QuoteRequest.displayName = 'proto.spre.QuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CreateIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CreateIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CreateIdResponse.displayName = 'proto.spre.CreateIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CreateResponse.displayName = 'proto.spre.CreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ExecuteFunctionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.ExecuteFunctionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ExecuteFunctionRequest.displayName = 'proto.spre.ExecuteFunctionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.VariableMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.VariableMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.VariableMap.displayName = 'proto.spre.VariableMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Calculation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.Calculation.repeatedFields_, null);
};
goog.inherits(proto.spre.Calculation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Calculation.displayName = 'proto.spre.Calculation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Report = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.Report.repeatedFields_, null);
};
goog.inherits(proto.spre.Report, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Report.displayName = 'proto.spre.Report';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Expense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Expense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Expense.displayName = 'proto.spre.Expense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Profile.displayName = 'proto.spre.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Quote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.Quote.repeatedFields_, null);
};
goog.inherits(proto.spre.Quote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Quote.displayName = 'proto.spre.Quote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Algorithm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Algorithm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Algorithm.displayName = 'proto.spre.Algorithm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Lut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.Lut.repeatedFields_, null);
};
goog.inherits(proto.spre.Lut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Lut.displayName = 'proto.spre.Lut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Band = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Band, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Band.displayName = 'proto.spre.Band';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.HypotheticalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.HypotheticalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.HypotheticalRequest.displayName = 'proto.spre.HypotheticalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.HypotheticalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.HypotheticalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.HypotheticalResponse.displayName = 'proto.spre.HypotheticalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.Price = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.Price, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.Price.displayName = 'proto.spre.Price';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ListProductsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.ListProductsResponse.repeatedFields_, null);
};
goog.inherits(proto.spre.ListProductsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ListProductsResponse.displayName = 'proto.spre.ListProductsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ListProductsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.ListProductsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ListProductsRequest.displayName = 'proto.spre.ListProductsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ListProgramPricingOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.ListProgramPricingOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ListProgramPricingOptionsRequest.displayName = 'proto.spre.ListProgramPricingOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.ListProgramPricingOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.spre.ListProgramPricingOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.spre.ListProgramPricingOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.ListProgramPricingOptionsResponse.displayName = 'proto.spre.ListProgramPricingOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CalculateVehiclePricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CalculateVehiclePricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CalculateVehiclePricingRequest.displayName = 'proto.spre.CalculateVehiclePricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CalculateVehiclePricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CalculateVehiclePricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CalculateVehiclePricingResponse.displayName = 'proto.spre.CalculateVehiclePricingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CalculateCustomerVehiclePricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CalculateCustomerVehiclePricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CalculateCustomerVehiclePricingRequest.displayName = 'proto.spre.CalculateCustomerVehiclePricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.spre.CalculateCustomerVehiclePricingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.spre.CalculateCustomerVehiclePricingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.spre.CalculateCustomerVehiclePricingResponse.displayName = 'proto.spre.CalculateCustomerVehiclePricingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ItemTypeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ItemTypeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ItemTypeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ItemTypeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ItemTypeQuery}
 */
proto.spre.ItemTypeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ItemTypeQuery;
  return proto.spre.ItemTypeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ItemTypeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ItemTypeQuery}
 */
proto.spre.ItemTypeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ItemTypeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ItemTypeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ItemTypeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ItemTypeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.spre.ItemTypeQuery.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.ItemTypeQuery} returns this
 */
proto.spre.ItemTypeQuery.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.ItemResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.spre.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ItemResponse}
 */
proto.spre.ItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ItemResponse;
  return proto.spre.ItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ItemResponse}
 */
proto.spre.ItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.spre.Item;
      reader.readMessage(value,proto.spre.Item.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.spre.Item.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Item items = 1;
 * @return {!Array<!proto.spre.Item>}
 */
proto.spre.ItemResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.spre.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.spre.Item, 1));
};


/**
 * @param {!Array<!proto.spre.Item>} value
 * @return {!proto.spre.ItemResponse} returns this
*/
proto.spre.ItemResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.spre.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.spre.Item}
 */
proto.spre.ItemResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.spre.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.ItemResponse} returns this
 */
proto.spre.ItemResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Item}
 */
proto.spre.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Item;
  return proto.spre.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Item}
 */
proto.spre.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.Item.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Item} returns this
 */
proto.spre.Item.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CustomerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CustomerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CustomerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CustomerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CustomerRequest}
 */
proto.spre.CustomerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CustomerRequest;
  return proto.spre.CustomerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CustomerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CustomerRequest}
 */
proto.spre.CustomerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CustomerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CustomerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CustomerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CustomerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.spre.CustomerRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.CustomerRequest} returns this
 */
proto.spre.CustomerRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.QuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.QuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.QuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.QuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reservationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pricingOption: (f = msg.getPricingOption()) && subscription_pb.PricingOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.QuoteRequest}
 */
proto.spre.QuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.QuoteRequest;
  return proto.spre.QuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.QuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.QuoteRequest}
 */
proto.spre.QuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    case 4:
      var value = new subscription_pb.PricingOption;
      reader.readMessage(value,subscription_pb.PricingOption.deserializeBinaryFromReader);
      msg.setPricingOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.QuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.QuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.QuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.QuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPricingOption();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      subscription_pb.PricingOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.spre.QuoteRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.QuoteRequest} returns this
 */
proto.spre.QuoteRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_id = 2;
 * @return {string}
 */
proto.spre.QuoteRequest.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.QuoteRequest} returns this
 */
proto.spre.QuoteRequest.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reservation_id = 3;
 * @return {string}
 */
proto.spre.QuoteRequest.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.QuoteRequest} returns this
 */
proto.spre.QuoteRequest.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional subscription.PricingOption pricing_option = 4;
 * @return {?proto.subscription.PricingOption}
 */
proto.spre.QuoteRequest.prototype.getPricingOption = function() {
  return /** @type{?proto.subscription.PricingOption} */ (
    jspb.Message.getWrapperField(this, subscription_pb.PricingOption, 4));
};


/**
 * @param {?proto.subscription.PricingOption|undefined} value
 * @return {!proto.spre.QuoteRequest} returns this
*/
proto.spre.QuoteRequest.prototype.setPricingOption = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.QuoteRequest} returns this
 */
proto.spre.QuoteRequest.prototype.clearPricingOption = function() {
  return this.setPricingOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.QuoteRequest.prototype.hasPricingOption = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CreateIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CreateIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CreateIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CreateIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CreateIdResponse}
 */
proto.spre.CreateIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CreateIdResponse;
  return proto.spre.CreateIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CreateIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CreateIdResponse}
 */
proto.spre.CreateIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CreateIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CreateIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CreateIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CreateIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.CreateIdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.CreateIdResponse} returns this
 */
proto.spre.CreateIdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.spre.CreateIdResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.spre.CreateIdResponse} returns this
 */
proto.spre.CreateIdResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CreateResponse}
 */
proto.spre.CreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CreateResponse;
  return proto.spre.CreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CreateResponse}
 */
proto.spre.CreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.spre.CreateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.spre.CreateResponse} returns this
 */
proto.spre.CreateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ExecuteFunctionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ExecuteFunctionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ExecuteFunctionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ExecuteFunctionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    calculationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    target: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lutId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ExecuteFunctionRequest}
 */
proto.spre.ExecuteFunctionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ExecuteFunctionRequest;
  return proto.spre.ExecuteFunctionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ExecuteFunctionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ExecuteFunctionRequest}
 */
proto.spre.ExecuteFunctionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalculationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLutId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ExecuteFunctionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ExecuteFunctionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ExecuteFunctionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ExecuteFunctionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalculationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLutId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string calculation_id = 1;
 * @return {string}
 */
proto.spre.ExecuteFunctionRequest.prototype.getCalculationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.ExecuteFunctionRequest} returns this
 */
proto.spre.ExecuteFunctionRequest.prototype.setCalculationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target = 2;
 * @return {string}
 */
proto.spre.ExecuteFunctionRequest.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.ExecuteFunctionRequest} returns this
 */
proto.spre.ExecuteFunctionRequest.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lut_id = 3;
 * @return {string}
 */
proto.spre.ExecuteFunctionRequest.prototype.getLutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.ExecuteFunctionRequest} returns this
 */
proto.spre.ExecuteFunctionRequest.prototype.setLutId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.VariableMap.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.VariableMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.VariableMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.VariableMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    column: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.VariableMap}
 */
proto.spre.VariableMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.VariableMap;
  return proto.spre.VariableMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.VariableMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.VariableMap}
 */
proto.spre.VariableMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.VariableMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.VariableMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.VariableMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.VariableMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColumn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.spre.VariableMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.VariableMap} returns this
 */
proto.spre.VariableMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string column = 2;
 * @return {string}
 */
proto.spre.VariableMap.prototype.getColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.VariableMap} returns this
 */
proto.spre.VariableMap.prototype.setColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.Calculation.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Calculation.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Calculation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Calculation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Calculation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    inputsList: jspb.Message.toObjectList(msg.getInputsList(),
    proto.spre.VariableMap.toObject, includeInstance),
    outputsList: jspb.Message.toObjectList(msg.getOutputsList(),
    proto.spre.VariableMap.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Calculation}
 */
proto.spre.Calculation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Calculation;
  return proto.spre.Calculation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Calculation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Calculation}
 */
proto.spre.Calculation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperation(value);
      break;
    case 3:
      var value = new proto.spre.VariableMap;
      reader.readMessage(value,proto.spre.VariableMap.deserializeBinaryFromReader);
      msg.addInputs(value);
      break;
    case 4:
      var value = new proto.spre.VariableMap;
      reader.readMessage(value,proto.spre.VariableMap.deserializeBinaryFromReader);
      msg.addOutputs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Calculation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Calculation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Calculation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Calculation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.spre.VariableMap.serializeBinaryToWriter
    );
  }
  f = message.getOutputsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.spre.VariableMap.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.Calculation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Calculation} returns this
 */
proto.spre.Calculation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operation = 2;
 * @return {string}
 */
proto.spre.Calculation.prototype.getOperation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Calculation} returns this
 */
proto.spre.Calculation.prototype.setOperation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated VariableMap inputs = 3;
 * @return {!Array<!proto.spre.VariableMap>}
 */
proto.spre.Calculation.prototype.getInputsList = function() {
  return /** @type{!Array<!proto.spre.VariableMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.spre.VariableMap, 3));
};


/**
 * @param {!Array<!proto.spre.VariableMap>} value
 * @return {!proto.spre.Calculation} returns this
*/
proto.spre.Calculation.prototype.setInputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.spre.VariableMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.spre.VariableMap}
 */
proto.spre.Calculation.prototype.addInputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.spre.VariableMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Calculation} returns this
 */
proto.spre.Calculation.prototype.clearInputsList = function() {
  return this.setInputsList([]);
};


/**
 * repeated VariableMap outputs = 4;
 * @return {!Array<!proto.spre.VariableMap>}
 */
proto.spre.Calculation.prototype.getOutputsList = function() {
  return /** @type{!Array<!proto.spre.VariableMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.spre.VariableMap, 4));
};


/**
 * @param {!Array<!proto.spre.VariableMap>} value
 * @return {!proto.spre.Calculation} returns this
*/
proto.spre.Calculation.prototype.setOutputsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.spre.VariableMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.spre.VariableMap}
 */
proto.spre.Calculation.prototype.addOutputs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.spre.VariableMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Calculation} returns this
 */
proto.spre.Calculation.prototype.clearOutputsList = function() {
  return this.setOutputsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.Report.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Report.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Report.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Report} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Report.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fico: jspb.Message.getFieldWithDefault(msg, 2, 0),
    income: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expensesList: jspb.Message.toObjectList(msg.getExpensesList(),
    proto.spre.Expense.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Report}
 */
proto.spre.Report.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Report;
  return proto.spre.Report.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Report} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Report}
 */
proto.spre.Report.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFico(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIncome(value);
      break;
    case 4:
      var value = new proto.spre.Expense;
      reader.readMessage(value,proto.spre.Expense.deserializeBinaryFromReader);
      msg.addExpenses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Report.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Report.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Report} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Report.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFico();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIncome();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExpensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.spre.Expense.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.spre.Report.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Report} returns this
 */
proto.spre.Report.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 fico = 2;
 * @return {number}
 */
proto.spre.Report.prototype.getFico = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Report} returns this
 */
proto.spre.Report.prototype.setFico = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 income = 3;
 * @return {number}
 */
proto.spre.Report.prototype.getIncome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Report} returns this
 */
proto.spre.Report.prototype.setIncome = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Expense expenses = 4;
 * @return {!Array<!proto.spre.Expense>}
 */
proto.spre.Report.prototype.getExpensesList = function() {
  return /** @type{!Array<!proto.spre.Expense>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.spre.Expense, 4));
};


/**
 * @param {!Array<!proto.spre.Expense>} value
 * @return {!proto.spre.Report} returns this
*/
proto.spre.Report.prototype.setExpensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.spre.Expense=} opt_value
 * @param {number=} opt_index
 * @return {!proto.spre.Expense}
 */
proto.spre.Report.prototype.addExpenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.spre.Expense, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Report} returns this
 */
proto.spre.Report.prototype.clearExpensesList = function() {
  return this.setExpensesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Expense.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Expense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Expense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Expense.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Expense}
 */
proto.spre.Expense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Expense;
  return proto.spre.Expense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Expense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Expense}
 */
proto.spre.Expense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.spre.Expense.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {!proto.spre.Expense.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Expense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Expense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Expense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Expense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.spre.Expense.Category = {
  NOCATEGORY: 0,
  MORTGAGE: 1,
  RENT: 2,
  STUDENT_LOAN: 3,
  AUTO_LOAN: 4,
  AUTO_LEASE: 5,
  AUTO_INS: 6,
  CREDIT_CARD_MIN: 7
};

/**
 * @enum {number}
 */
proto.spre.Expense.Type = {
  NOTYPE: 0,
  FIXED: 1,
  VARIABLE: 2
};

/**
 * optional Category category = 1;
 * @return {!proto.spre.Expense.Category}
 */
proto.spre.Expense.prototype.getCategory = function() {
  return /** @type {!proto.spre.Expense.Category} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.spre.Expense.Category} value
 * @return {!proto.spre.Expense} returns this
 */
proto.spre.Expense.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.spre.Expense.Type}
 */
proto.spre.Expense.prototype.getType = function() {
  return /** @type {!proto.spre.Expense.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.spre.Expense.Type} value
 * @return {!proto.spre.Expense} returns this
 */
proto.spre.Expense.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 amount = 3;
 * @return {number}
 */
proto.spre.Expense.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Expense} returns this
 */
proto.spre.Expense.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalExpenses: jspb.Message.getFieldWithDefault(msg, 1, 0),
    netIncome: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dti: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxPtiPct: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxPtiPmt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxDtiPct: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxDtiPmt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    incDtiPmt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    maxPmt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    vehicleBronze: jspb.Message.getFieldWithDefault(msg, 10, 0),
    vehicleSilver: jspb.Message.getFieldWithDefault(msg, 11, 0),
    vehicleGold: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Profile}
 */
proto.spre.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Profile;
  return proto.spre.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Profile}
 */
proto.spre.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalExpenses(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNetIncome(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDti(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPtiPct(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPtiPmt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDtiPct(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDtiPmt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIncDtiPmt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPmt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleBronze(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleSilver(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleGold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalExpenses();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNetIncome();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDti();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxPtiPct();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMaxPtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxDtiPct();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxDtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getIncDtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMaxPmt();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getVehicleBronze();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getVehicleSilver();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getVehicleGold();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int32 total_expenses = 1;
 * @return {number}
 */
proto.spre.Profile.prototype.getTotalExpenses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setTotalExpenses = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 net_income = 2;
 * @return {number}
 */
proto.spre.Profile.prototype.getNetIncome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setNetIncome = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 dti = 3;
 * @return {number}
 */
proto.spre.Profile.prototype.getDti = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setDti = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 max_pti_pct = 4;
 * @return {number}
 */
proto.spre.Profile.prototype.getMaxPtiPct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setMaxPtiPct = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 max_pti_pmt = 5;
 * @return {number}
 */
proto.spre.Profile.prototype.getMaxPtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setMaxPtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_dti_pct = 6;
 * @return {number}
 */
proto.spre.Profile.prototype.getMaxDtiPct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setMaxDtiPct = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 max_dti_pmt = 7;
 * @return {number}
 */
proto.spre.Profile.prototype.getMaxDtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setMaxDtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 inc_dti_pmt = 8;
 * @return {number}
 */
proto.spre.Profile.prototype.getIncDtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setIncDtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 max_pmt = 9;
 * @return {number}
 */
proto.spre.Profile.prototype.getMaxPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setMaxPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 vehicle_bronze = 10;
 * @return {number}
 */
proto.spre.Profile.prototype.getVehicleBronze = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setVehicleBronze = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 vehicle_silver = 11;
 * @return {number}
 */
proto.spre.Profile.prototype.getVehicleSilver = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setVehicleSilver = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 vehicle_gold = 12;
 * @return {number}
 */
proto.spre.Profile.prototype.getVehicleGold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Profile} returns this
 */
proto.spre.Profile.prototype.setVehicleGold = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.Quote.repeatedFields_ = [6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Quote.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Quote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Quote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Quote.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dealerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lutId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startupList: jspb.Message.toObjectList(msg.getStartupList(),
    domain_pb.Product.toObject, includeInstance),
    monthlyList: jspb.Message.toObjectList(msg.getMonthlyList(),
    domain_pb.Product.toObject, includeInstance),
    lineItemsList: jspb.Message.toObjectList(msg.getLineItemsList(),
    domain_pb.LineItem.toObject, includeInstance),
    valuationsList: jspb.Message.toObjectList(msg.getValuationsList(),
    vehicle_pb.Valuation.toObject, includeInstance),
    reservationId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Quote}
 */
proto.spre.Quote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Quote;
  return proto.spre.Quote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Quote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Quote}
 */
proto.spre.Quote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLutId(value);
      break;
    case 6:
      var value = new domain_pb.Product;
      reader.readMessage(value,domain_pb.Product.deserializeBinaryFromReader);
      msg.addStartup(value);
      break;
    case 7:
      var value = new domain_pb.Product;
      reader.readMessage(value,domain_pb.Product.deserializeBinaryFromReader);
      msg.addMonthly(value);
      break;
    case 8:
      var value = new domain_pb.LineItem;
      reader.readMessage(value,domain_pb.LineItem.deserializeBinaryFromReader);
      msg.addLineItems(value);
      break;
    case 9:
      var value = new vehicle_pb.Valuation;
      reader.readMessage(value,vehicle_pb.Valuation.deserializeBinaryFromReader);
      msg.addValuations(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Quote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Quote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Quote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Quote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDealerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLutId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      domain_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      domain_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getLineItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      domain_pb.LineItem.serializeBinaryToWriter
    );
  }
  f = message.getValuationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      vehicle_pb.Valuation.serializeBinaryToWriter
    );
  }
  f = message.getReservationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.Quote.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.spre.Quote.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.spre.Quote.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dealer_id = 4;
 * @return {string}
 */
proto.spre.Quote.prototype.getDealerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setDealerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lut_id = 5;
 * @return {string}
 */
proto.spre.Quote.prototype.getLutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setLutId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated domain.Product startup = 6;
 * @return {!Array<!proto.domain.Product>}
 */
proto.spre.Quote.prototype.getStartupList = function() {
  return /** @type{!Array<!proto.domain.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Product, 6));
};


/**
 * @param {!Array<!proto.domain.Product>} value
 * @return {!proto.spre.Quote} returns this
*/
proto.spre.Quote.prototype.setStartupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.domain.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Product}
 */
proto.spre.Quote.prototype.addStartup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.domain.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.clearStartupList = function() {
  return this.setStartupList([]);
};


/**
 * repeated domain.Product monthly = 7;
 * @return {!Array<!proto.domain.Product>}
 */
proto.spre.Quote.prototype.getMonthlyList = function() {
  return /** @type{!Array<!proto.domain.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Product, 7));
};


/**
 * @param {!Array<!proto.domain.Product>} value
 * @return {!proto.spre.Quote} returns this
*/
proto.spre.Quote.prototype.setMonthlyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.domain.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Product}
 */
proto.spre.Quote.prototype.addMonthly = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.domain.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.clearMonthlyList = function() {
  return this.setMonthlyList([]);
};


/**
 * repeated domain.LineItem line_items = 8;
 * @return {!Array<!proto.domain.LineItem>}
 */
proto.spre.Quote.prototype.getLineItemsList = function() {
  return /** @type{!Array<!proto.domain.LineItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.LineItem, 8));
};


/**
 * @param {!Array<!proto.domain.LineItem>} value
 * @return {!proto.spre.Quote} returns this
*/
proto.spre.Quote.prototype.setLineItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.domain.LineItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.LineItem}
 */
proto.spre.Quote.prototype.addLineItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.domain.LineItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.clearLineItemsList = function() {
  return this.setLineItemsList([]);
};


/**
 * repeated vehicle.Valuation valuations = 9;
 * @return {!Array<!proto.vehicle.Valuation>}
 */
proto.spre.Quote.prototype.getValuationsList = function() {
  return /** @type{!Array<!proto.vehicle.Valuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, vehicle_pb.Valuation, 9));
};


/**
 * @param {!Array<!proto.vehicle.Valuation>} value
 * @return {!proto.spre.Quote} returns this
*/
proto.spre.Quote.prototype.setValuationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vehicle.Valuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vehicle.Valuation}
 */
proto.spre.Quote.prototype.addValuations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vehicle.Valuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.clearValuationsList = function() {
  return this.setValuationsList([]);
};


/**
 * optional string reservation_id = 10;
 * @return {string}
 */
proto.spre.Quote.prototype.getReservationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Quote} returns this
 */
proto.spre.Quote.prototype.setReservationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Algorithm.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Algorithm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Algorithm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Algorithm.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    formula: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lut: (f = msg.getLut()) && proto.spre.Lut.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Algorithm}
 */
proto.spre.Algorithm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Algorithm;
  return proto.spre.Algorithm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Algorithm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Algorithm}
 */
proto.spre.Algorithm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormula(value);
      break;
    case 4:
      var value = new proto.spre.Lut;
      reader.readMessage(value,proto.spre.Lut.deserializeBinaryFromReader);
      msg.setLut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Algorithm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Algorithm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Algorithm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Algorithm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormula();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLut();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.spre.Lut.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.Algorithm.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Algorithm} returns this
 */
proto.spre.Algorithm.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.spre.Algorithm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Algorithm} returns this
 */
proto.spre.Algorithm.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string formula = 3;
 * @return {string}
 */
proto.spre.Algorithm.prototype.getFormula = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Algorithm} returns this
 */
proto.spre.Algorithm.prototype.setFormula = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Lut lut = 4;
 * @return {?proto.spre.Lut}
 */
proto.spre.Algorithm.prototype.getLut = function() {
  return /** @type{?proto.spre.Lut} */ (
    jspb.Message.getWrapperField(this, proto.spre.Lut, 4));
};


/**
 * @param {?proto.spre.Lut|undefined} value
 * @return {!proto.spre.Algorithm} returns this
*/
proto.spre.Algorithm.prototype.setLut = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.Algorithm} returns this
 */
proto.spre.Algorithm.prototype.clearLut = function() {
  return this.setLut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.Algorithm.prototype.hasLut = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.Lut.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Lut.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Lut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Lut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Lut.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bandsList: jspb.Message.toObjectList(msg.getBandsList(),
    proto.spre.Band.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Lut}
 */
proto.spre.Lut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Lut;
  return proto.spre.Lut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Lut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Lut}
 */
proto.spre.Lut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.spre.Band;
      reader.readMessage(value,proto.spre.Band.deserializeBinaryFromReader);
      msg.addBands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Lut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Lut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Lut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Lut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.spre.Band.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.spre.Lut.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Lut} returns this
 */
proto.spre.Lut.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Band bands = 2;
 * @return {!Array<!proto.spre.Band>}
 */
proto.spre.Lut.prototype.getBandsList = function() {
  return /** @type{!Array<!proto.spre.Band>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.spre.Band, 2));
};


/**
 * @param {!Array<!proto.spre.Band>} value
 * @return {!proto.spre.Lut} returns this
*/
proto.spre.Lut.prototype.setBandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.spre.Band=} opt_value
 * @param {number=} opt_index
 * @return {!proto.spre.Band}
 */
proto.spre.Lut.prototype.addBands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.spre.Band, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.Lut} returns this
 */
proto.spre.Lut.prototype.clearBandsList = function() {
  return this.setBandsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Band.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Band.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Band} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Band.toObject = function(includeInstance, msg) {
  var f, obj = {
    fico: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ficoBand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ficoMin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ficoMax: jspb.Message.getFieldWithDefault(msg, 4, 0),
    riskBand: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pti: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dti: jspb.Message.getFieldWithDefault(msg, 7, 0),
    start15k: jspb.Message.getFieldWithDefault(msg, 8, 0),
    start20k: jspb.Message.getFieldWithDefault(msg, 9, 0),
    start25k: jspb.Message.getFieldWithDefault(msg, 10, 0),
    start30k: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 12, 0),
    inscAndLiSm: jspb.Message.getFieldWithDefault(msg, 13, 0),
    inscAndLiMd: jspb.Message.getFieldWithDefault(msg, 14, 0),
    inscAndLiLg: jspb.Message.getFieldWithDefault(msg, 15, 0),
    tax: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pOverFmvSm: jspb.Message.getFieldWithDefault(msg, 17, 0),
    pOverFmvMd: jspb.Message.getFieldWithDefault(msg, 18, 0),
    pOverFmvLg: jspb.Message.getFieldWithDefault(msg, 19, 0),
    loan36: jspb.Message.getFieldWithDefault(msg, 20, 0),
    loan48: jspb.Message.getFieldWithDefault(msg, 21, 0),
    loan60: jspb.Message.getFieldWithDefault(msg, 22, 0),
    loan72: jspb.Message.getFieldWithDefault(msg, 23, 0),
    lse24: jspb.Message.getFieldWithDefault(msg, 24, 0),
    lse36: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Band}
 */
proto.spre.Band.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Band;
  return proto.spre.Band.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Band} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Band}
 */
proto.spre.Band.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFico(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFicoBand(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFicoMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFicoMax(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskBand(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPti(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDti(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart15k(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart20k(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart25k(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart30k(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInscAndLiSm(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInscAndLiMd(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInscAndLiLg(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTax(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPOverFmvSm(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPOverFmvMd(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPOverFmvLg(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoan36(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoan48(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoan60(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoan72(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLse24(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLse36(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Band.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Band.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Band} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Band.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFico();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFicoBand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFicoMin();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFicoMax();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRiskBand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPti();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDti();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStart15k();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStart20k();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getStart25k();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getStart30k();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getInscAndLiSm();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getInscAndLiMd();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getInscAndLiLg();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getTax();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPOverFmvSm();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPOverFmvMd();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getPOverFmvLg();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getLoan36();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getLoan48();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getLoan60();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getLoan72();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getLse24();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getLse36();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional int32 fico = 1;
 * @return {number}
 */
proto.spre.Band.prototype.getFico = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setFico = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string fico_band = 2;
 * @return {string}
 */
proto.spre.Band.prototype.getFicoBand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setFicoBand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 fico_min = 3;
 * @return {number}
 */
proto.spre.Band.prototype.getFicoMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setFicoMin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 fico_max = 4;
 * @return {number}
 */
proto.spre.Band.prototype.getFicoMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setFicoMax = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string risk_band = 5;
 * @return {string}
 */
proto.spre.Band.prototype.getRiskBand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setRiskBand = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 pti = 6;
 * @return {number}
 */
proto.spre.Band.prototype.getPti = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setPti = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 dti = 7;
 * @return {number}
 */
proto.spre.Band.prototype.getDti = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setDti = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 start15k = 8;
 * @return {number}
 */
proto.spre.Band.prototype.getStart15k = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setStart15k = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 start20k = 9;
 * @return {number}
 */
proto.spre.Band.prototype.getStart20k = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setStart20k = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 start25k = 10;
 * @return {number}
 */
proto.spre.Band.prototype.getStart25k = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setStart25k = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 start30k = 11;
 * @return {number}
 */
proto.spre.Band.prototype.getStart30k = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setStart30k = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 deposit = 12;
 * @return {number}
 */
proto.spre.Band.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 insc_and_li_sm = 13;
 * @return {number}
 */
proto.spre.Band.prototype.getInscAndLiSm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setInscAndLiSm = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 insc_and_li_md = 14;
 * @return {number}
 */
proto.spre.Band.prototype.getInscAndLiMd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setInscAndLiMd = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 insc_and_li_lg = 15;
 * @return {number}
 */
proto.spre.Band.prototype.getInscAndLiLg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setInscAndLiLg = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 tax = 16;
 * @return {number}
 */
proto.spre.Band.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setTax = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 p_over_fmv_sm = 17;
 * @return {number}
 */
proto.spre.Band.prototype.getPOverFmvSm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setPOverFmvSm = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 p_over_fmv_md = 18;
 * @return {number}
 */
proto.spre.Band.prototype.getPOverFmvMd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setPOverFmvMd = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 p_over_fmv_lg = 19;
 * @return {number}
 */
proto.spre.Band.prototype.getPOverFmvLg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setPOverFmvLg = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 loan36 = 20;
 * @return {number}
 */
proto.spre.Band.prototype.getLoan36 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLoan36 = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 loan48 = 21;
 * @return {number}
 */
proto.spre.Band.prototype.getLoan48 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLoan48 = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 loan60 = 22;
 * @return {number}
 */
proto.spre.Band.prototype.getLoan60 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLoan60 = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 loan72 = 23;
 * @return {number}
 */
proto.spre.Band.prototype.getLoan72 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLoan72 = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 lse24 = 24;
 * @return {number}
 */
proto.spre.Band.prototype.getLse24 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLse24 = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 lse36 = 25;
 * @return {number}
 */
proto.spre.Band.prototype.getLse36 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Band} returns this
 */
proto.spre.Band.prototype.setLse36 = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.HypotheticalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.HypotheticalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.HypotheticalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.HypotheticalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lutId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minRent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minIns: jspb.Message.getFieldWithDefault(msg, 3, 0),
    colAndLi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    exm: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ewt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    colAndLiDeduct: jspb.Message.getFieldWithDefault(msg, 7, 0),
    fico: jspb.Message.getFieldWithDefault(msg, 8, 0),
    grossAnnualIncome: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mortgage: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rent: jspb.Message.getFieldWithDefault(msg, 11, 0),
    studentLoan: jspb.Message.getFieldWithDefault(msg, 12, 0),
    autoLoan: jspb.Message.getFieldWithDefault(msg, 13, 0),
    autoLease: jspb.Message.getFieldWithDefault(msg, 14, 0),
    autoIns: jspb.Message.getFieldWithDefault(msg, 15, 0),
    creditCardMin: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.HypotheticalRequest}
 */
proto.spre.HypotheticalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.HypotheticalRequest;
  return proto.spre.HypotheticalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.HypotheticalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.HypotheticalRequest}
 */
proto.spre.HypotheticalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLutId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinRent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinIns(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColAndLi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExm(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEwt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColAndLiDeduct(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFico(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGrossAnnualIncome(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMortgage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRent(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStudentLoan(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutoLoan(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutoLease(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAutoIns(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreditCardMin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.HypotheticalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.HypotheticalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.HypotheticalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.HypotheticalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLutId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinRent();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinIns();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getColAndLi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getExm();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEwt();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getColAndLiDeduct();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFico();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGrossAnnualIncome();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMortgage();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRent();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStudentLoan();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAutoLoan();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getAutoLease();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getAutoIns();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCreditCardMin();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
};


/**
 * optional string lut_id = 1;
 * @return {string}
 */
proto.spre.HypotheticalRequest.prototype.getLutId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setLutId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 min_rent = 2;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getMinRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setMinRent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 min_ins = 3;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getMinIns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setMinIns = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 col_and_li = 4;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getColAndLi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setColAndLi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 exm = 5;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getExm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setExm = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 ewt = 6;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getEwt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setEwt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 col_and_li_deduct = 7;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getColAndLiDeduct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setColAndLiDeduct = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 fico = 8;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getFico = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setFico = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 gross_annual_income = 9;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getGrossAnnualIncome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setGrossAnnualIncome = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 mortgage = 10;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getMortgage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setMortgage = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 rent = 11;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getRent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setRent = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 student_loan = 12;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getStudentLoan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setStudentLoan = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 auto_loan = 13;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getAutoLoan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setAutoLoan = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 auto_lease = 14;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getAutoLease = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setAutoLease = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 auto_ins = 15;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getAutoIns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setAutoIns = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 credit_card_min = 16;
 * @return {number}
 */
proto.spre.HypotheticalRequest.prototype.getCreditCardMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalRequest} returns this
 */
proto.spre.HypotheticalRequest.prototype.setCreditCardMin = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.HypotheticalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.HypotheticalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.HypotheticalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.HypotheticalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ficoBand: jspb.Message.getFieldWithDefault(msg, 1, ""),
    riskBand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    grossIncome: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalExpenses: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dti: jspb.Message.getFieldWithDefault(msg, 5, 0),
    netIncome: jspb.Message.getFieldWithDefault(msg, 6, 0),
    maxDtiPct: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxDtiPmt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    incDtiPmt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    maxPtiPct: jspb.Message.getFieldWithDefault(msg, 10, 0),
    maxPtiPmt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    minSubAvail: jspb.Message.getFieldWithDefault(msg, 12, 0),
    maxPmt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    subscription: jspb.Message.getFieldWithDefault(msg, 14, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 15, 0),
    startPmt: jspb.Message.getFieldWithDefault(msg, 16, 0),
    tax: jspb.Message.getFieldWithDefault(msg, 17, 0),
    driveOff: jspb.Message.getFieldWithDefault(msg, 18, 0),
    vehicleBronze: jspb.Message.getFieldWithDefault(msg, 19, 0),
    vehicleSilver: jspb.Message.getFieldWithDefault(msg, 20, 0),
    vehicleGold: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.HypotheticalResponse}
 */
proto.spre.HypotheticalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.HypotheticalResponse;
  return proto.spre.HypotheticalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.HypotheticalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.HypotheticalResponse}
 */
proto.spre.HypotheticalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFicoBand(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskBand(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGrossIncome(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalExpenses(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDti(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNetIncome(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDtiPct(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDtiPmt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIncDtiPmt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPtiPct(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPtiPmt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinSubAvail(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxPmt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubscription(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeposit(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPmt(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTax(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriveOff(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleBronze(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleSilver(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVehicleGold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.HypotheticalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.HypotheticalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.HypotheticalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.HypotheticalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFicoBand();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRiskBand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGrossIncome();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalExpenses();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDti();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNetIncome();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMaxDtiPct();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMaxDtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIncDtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getMaxPtiPct();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getMaxPtiPmt();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMinSubAvail();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getMaxPmt();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSubscription();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getStartPmt();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTax();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getDriveOff();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getVehicleBronze();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getVehicleSilver();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getVehicleGold();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
};


/**
 * optional string fico_band = 1;
 * @return {string}
 */
proto.spre.HypotheticalResponse.prototype.getFicoBand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setFicoBand = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string risk_band = 2;
 * @return {string}
 */
proto.spre.HypotheticalResponse.prototype.getRiskBand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setRiskBand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 gross_income = 3;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getGrossIncome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setGrossIncome = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 total_expenses = 4;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getTotalExpenses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setTotalExpenses = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 dti = 5;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getDti = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setDti = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 net_income = 6;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getNetIncome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setNetIncome = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 max_dti_pct = 7;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMaxDtiPct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMaxDtiPct = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 max_dti_pmt = 8;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMaxDtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMaxDtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 inc_dti_pmt = 9;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getIncDtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setIncDtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 max_pti_pct = 10;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMaxPtiPct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMaxPtiPct = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 max_pti_pmt = 11;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMaxPtiPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMaxPtiPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 min_sub_avail = 12;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMinSubAvail = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMinSubAvail = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 max_pmt = 13;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getMaxPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setMaxPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 subscription = 14;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getSubscription = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setSubscription = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 deposit = 15;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 start_pmt = 16;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getStartPmt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setStartPmt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 tax = 17;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setTax = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 drive_off = 18;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getDriveOff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setDriveOff = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 vehicle_bronze = 19;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getVehicleBronze = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setVehicleBronze = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 vehicle_silver = 20;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getVehicleSilver = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setVehicleSilver = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 vehicle_gold = 21;
 * @return {number}
 */
proto.spre.HypotheticalResponse.prototype.getVehicleGold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.HypotheticalResponse} returns this
 */
proto.spre.HypotheticalResponse.prototype.setVehicleGold = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.Price.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.Price.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.Price} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Price.toObject = function(includeInstance, msg) {
  var f, obj = {
    product: (f = msg.getProduct()) && domain_pb.Product.toObject(includeInstance, f),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.Price}
 */
proto.spre.Price.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.Price;
  return proto.spre.Price.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.Price} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.Price}
 */
proto.spre.Price.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_pb.Product;
      reader.readMessage(value,domain_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.Price.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.Price.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.Price} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.Price.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional domain.Product product = 1;
 * @return {?proto.domain.Product}
 */
proto.spre.Price.prototype.getProduct = function() {
  return /** @type{?proto.domain.Product} */ (
    jspb.Message.getWrapperField(this, domain_pb.Product, 1));
};


/**
 * @param {?proto.domain.Product|undefined} value
 * @return {!proto.spre.Price} returns this
*/
proto.spre.Price.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.Price} returns this
 */
proto.spre.Price.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.Price.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.spre.Price.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.Price} returns this
 */
proto.spre.Price.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.spre.Price.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.Price} returns this
 */
proto.spre.Price.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.ListProductsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ListProductsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ListProductsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ListProductsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProductsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    domain_pb.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ListProductsResponse}
 */
proto.spre.ListProductsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ListProductsResponse;
  return proto.spre.ListProductsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ListProductsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ListProductsResponse}
 */
proto.spre.ListProductsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new domain_pb.Product;
      reader.readMessage(value,domain_pb.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ListProductsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ListProductsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ListProductsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProductsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      domain_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.spre.ListProductsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.spre.ListProductsResponse} returns this
 */
proto.spre.ListProductsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated domain.Product products = 2;
 * @return {!Array<!proto.domain.Product>}
 */
proto.spre.ListProductsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.domain.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_pb.Product, 2));
};


/**
 * @param {!Array<!proto.domain.Product>} value
 * @return {!proto.spre.ListProductsResponse} returns this
*/
proto.spre.ListProductsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.domain.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.domain.Product}
 */
proto.spre.ListProductsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.domain.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.ListProductsResponse} returns this
 */
proto.spre.ListProductsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ListProductsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ListProductsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ListProductsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProductsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ListProductsRequest}
 */
proto.spre.ListProductsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ListProductsRequest;
  return proto.spre.ListProductsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ListProductsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ListProductsRequest}
 */
proto.spre.ListProductsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ListProductsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ListProductsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ListProductsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProductsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.spre.ListProductsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.spre.ListProductsRequest} returns this
 */
proto.spre.ListProductsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ListProgramPricingOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ListProgramPricingOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ListProgramPricingOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProgramPricingOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ListProgramPricingOptionsRequest}
 */
proto.spre.ListProgramPricingOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ListProgramPricingOptionsRequest;
  return proto.spre.ListProgramPricingOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ListProgramPricingOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ListProgramPricingOptionsRequest}
 */
proto.spre.ListProgramPricingOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ListProgramPricingOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ListProgramPricingOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ListProgramPricingOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProgramPricingOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string program_id = 1;
 * @return {string}
 */
proto.spre.ListProgramPricingOptionsRequest.prototype.getProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.ListProgramPricingOptionsRequest} returns this
 */
proto.spre.ListProgramPricingOptionsRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.spre.ListProgramPricingOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.ListProgramPricingOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.ListProgramPricingOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.ListProgramPricingOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProgramPricingOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingOptionsList: jspb.Message.toObjectList(msg.getPricingOptionsList(),
    subscription_pb.PricingOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.ListProgramPricingOptionsResponse}
 */
proto.spre.ListProgramPricingOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.ListProgramPricingOptionsResponse;
  return proto.spre.ListProgramPricingOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.ListProgramPricingOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.ListProgramPricingOptionsResponse}
 */
proto.spre.ListProgramPricingOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new subscription_pb.PricingOption;
      reader.readMessage(value,subscription_pb.PricingOption.deserializeBinaryFromReader);
      msg.addPricingOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.ListProgramPricingOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.ListProgramPricingOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.ListProgramPricingOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.ListProgramPricingOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      subscription_pb.PricingOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated subscription.PricingOption pricing_options = 1;
 * @return {!Array<!proto.subscription.PricingOption>}
 */
proto.spre.ListProgramPricingOptionsResponse.prototype.getPricingOptionsList = function() {
  return /** @type{!Array<!proto.subscription.PricingOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, subscription_pb.PricingOption, 1));
};


/**
 * @param {!Array<!proto.subscription.PricingOption>} value
 * @return {!proto.spre.ListProgramPricingOptionsResponse} returns this
*/
proto.spre.ListProgramPricingOptionsResponse.prototype.setPricingOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.subscription.PricingOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.subscription.PricingOption}
 */
proto.spre.ListProgramPricingOptionsResponse.prototype.addPricingOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.subscription.PricingOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.spre.ListProgramPricingOptionsResponse} returns this
 */
proto.spre.ListProgramPricingOptionsResponse.prototype.clearPricingOptionsList = function() {
  return this.setPricingOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CalculateVehiclePricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CalculateVehiclePricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CalculateVehiclePricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateVehiclePricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CalculateVehiclePricingRequest}
 */
proto.spre.CalculateVehiclePricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CalculateVehiclePricingRequest;
  return proto.spre.CalculateVehiclePricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CalculateVehiclePricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CalculateVehiclePricingRequest}
 */
proto.spre.CalculateVehiclePricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CalculateVehiclePricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CalculateVehiclePricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CalculateVehiclePricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateVehiclePricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional vehicle.Vehicle vehicle = 1;
 * @return {?proto.vehicle.Vehicle}
 */
proto.spre.CalculateVehiclePricingRequest.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 1));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.spre.CalculateVehiclePricingRequest} returns this
*/
proto.spre.CalculateVehiclePricingRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.CalculateVehiclePricingRequest} returns this
 */
proto.spre.CalculateVehiclePricingRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.CalculateVehiclePricingRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CalculateVehiclePricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CalculateVehiclePricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CalculateVehiclePricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateVehiclePricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CalculateVehiclePricingResponse}
 */
proto.spre.CalculateVehiclePricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CalculateVehiclePricingResponse;
  return proto.spre.CalculateVehiclePricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CalculateVehiclePricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CalculateVehiclePricingResponse}
 */
proto.spre.CalculateVehiclePricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CalculateVehiclePricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CalculateVehiclePricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CalculateVehiclePricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateVehiclePricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.spre.CalculateVehiclePricingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.spre.CalculateVehiclePricingResponse} returns this
 */
proto.spre.CalculateVehiclePricingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional vehicle.Vehicle vehicle = 2;
 * @return {?proto.vehicle.Vehicle}
 */
proto.spre.CalculateVehiclePricingResponse.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.spre.CalculateVehiclePricingResponse} returns this
*/
proto.spre.CalculateVehiclePricingResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.CalculateVehiclePricingResponse} returns this
 */
proto.spre.CalculateVehiclePricingResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.CalculateVehiclePricingResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CalculateCustomerVehiclePricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CalculateCustomerVehiclePricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateCustomerVehiclePricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CalculateCustomerVehiclePricingRequest}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CalculateCustomerVehiclePricingRequest;
  return proto.spre.CalculateCustomerVehiclePricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CalculateCustomerVehiclePricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CalculateCustomerVehiclePricingRequest}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CalculateCustomerVehiclePricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CalculateCustomerVehiclePricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateCustomerVehiclePricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.spre.CalculateCustomerVehiclePricingRequest} returns this
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional vehicle.Vehicle vehicle = 2;
 * @return {?proto.vehicle.Vehicle}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.spre.CalculateCustomerVehiclePricingRequest} returns this
*/
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.CalculateCustomerVehiclePricingRequest} returns this
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.CalculateCustomerVehiclePricingRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.spre.CalculateCustomerVehiclePricingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.spre.CalculateCustomerVehiclePricingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateCustomerVehiclePricingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    vehicle: (f = msg.getVehicle()) && vehicle_pb.Vehicle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.spre.CalculateCustomerVehiclePricingResponse}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.spre.CalculateCustomerVehiclePricingResponse;
  return proto.spre.CalculateCustomerVehiclePricingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.spre.CalculateCustomerVehiclePricingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.spre.CalculateCustomerVehiclePricingResponse}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = new vehicle_pb.Vehicle;
      reader.readMessage(value,vehicle_pb.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.spre.CalculateCustomerVehiclePricingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.spre.CalculateCustomerVehiclePricingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.spre.CalculateCustomerVehiclePricingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vehicle_pb.Vehicle.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.spre.CalculateCustomerVehiclePricingResponse} returns this
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional vehicle.Vehicle vehicle = 2;
 * @return {?proto.vehicle.Vehicle}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.getVehicle = function() {
  return /** @type{?proto.vehicle.Vehicle} */ (
    jspb.Message.getWrapperField(this, vehicle_pb.Vehicle, 2));
};


/**
 * @param {?proto.vehicle.Vehicle|undefined} value
 * @return {!proto.spre.CalculateCustomerVehiclePricingResponse} returns this
*/
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.spre.CalculateCustomerVehiclePricingResponse} returns this
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.spre.CalculateCustomerVehiclePricingResponse.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.spre);
